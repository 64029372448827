@import "production.min.1.css";
.select2-container .select2-selection--single {
  border: 0;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 28px;
}

.select2-container .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container.select2-container--disabled .select2-selection--single {
  cursor: default;
}

.select2-container.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container .select2-search--dropdown .select2-search__field,
.select2-container .select2-dropdown {
  border-radius: 0;
  border: 0;
}

.select2-container.select2-container--open.select2-container--above .select2-selection--single,
.select2-container.select2-container--open.select2-container--below .select2-selection--single,
.select2-container.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 0;
}

.select2-container .select2-search--inline {
  width: 100%;
}

.select2-container .select2-search--inline:after {
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f122";
  font-size: 16px;
  position: absolute;
  right: 14px;
  top: 14px;
}

.select2-container .select2-search--inline .select2-search__field {
  border: none;
  outline: 0;
  box-shadow: none;
  padding-right: 40px;
  min-width: 100%;
}

.select2-container .select2-results>.select2-results__options {
  overflow-y: auto;
}

.select2-container.has_value .select2-results__option[aria-disabled=true] {
  display: block;
}

.select2-container .select2-results__option[role=group] {
  padding: 0;
}

.select2-container .select2-results__option[aria-disabled=true] {
  display: none;
  font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
}

.select2-container .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select_2-generic.select_2-generic+.select2 .select2-selection--single {
  height: 40px;
  padding: 8px 5% 8px 20px;
}

.select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__rendered {
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
}

.select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__rendered,
.select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__placeholder {
  font-size: 16px;
  line-height: 23px;
}

.select2_filters.select2_filters+.select2-container .select2-selection .select2-selection__placeholder {
  font-size: 16px;
}

.process_filter .select2_filters.select2_filters+.select2-container .select2-selection .select2-selection__placeholder {
  font-size: 13px;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.select2-container--darkgrey .select2-selection--single,
.select2-container--mixed .select2-selection--single {
  background-color: #3c4152;
}

.select2-container--darkgrey .select2-selection--single:focus,
.select2-container--mixed .select2-selection--single:focus {
  outline: none;
  background-color: #171923;
}

.select2-container--darkgrey .select2-selection--single:focus:active,
.select2-container--mixed .select2-selection--single:focus:active {
  outline: none;
}

.select2-container--darkgrey .select2-dropdown,
.select2-container--mixed .select2-dropdown {
  background-color: #3c4152;
}

.no-touch .select2-container--mixed .select2-selection--single.select2-selection--single .select2-container--mixed .select2-selection--single.select2-selection--single:focus {
  background-color: #42485b;
}

.select2-container--mixed .select2-selection--single.select2-selection--single .select2-selection__placeholder {
  color: #b6b8c0;
}

.select2-container--mixed .select2-selection--single.select2-selection--single .select2-selection__arrow {
  color: #b6b8c0;
}

.select2-container--mixed .select2-dropdown {
  background-color: #d7d8da;
}

.select2-container--mixed .select2-dropdown .select2-results__option {
  color: #323849;
}

.select2-container--mixed .select2-dropdown .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
  background: #c4c6c9;
}

.no-touch .select2-container--dark .select2-container--dark:focus {
  outline: none;
  background-color: #171923;
}

.no-touch .select2-container--dark .select2-container--dark:focus:active {
  outline: none;
}

.select2-container--dark .select2-selection--single {
  background: #1e222d;
  background-color: rgba(30,34,45,0.95);
}

.select2-container--dark .select2-dropdown {
  background: #1e222d;
  background-color: rgba(30,34,45,0.95);
}

.select2-container--dark .select2-dropdown.dropdown_body__footer {
  background: #282d3a;
  background-color: rgba(40,45,58,0.95);
}

.select2-container--open {
  z-index: 12;
}

.select2-container--darkgrey .select2-search--dropdown input,
.select2-container--dark .select2-search--dropdown input,
.select2-container--mixed .select2-search--dropdown input {
  background-color: #181b24;
}

.select2-container--darkgrey .select2-search--dropdown:after,
.select2-container--dark .select2-search--dropdown:after,
.select2-container--mixed .select2-search--dropdown:after {
  color: #707480;
}

.select2-container--darkgrey.select2-container--focus,
.select2-container--dark.select2-container--focus,
.select2-container--mixed.select2-container--focus {
  background-color: #171923;
}

.select2-container--darkgrey.select2-container--focus:focus,
.select2-container--dark.select2-container--focus:focus,
.select2-container--mixed.select2-container--focus:focus {
  outline: none;
  background-color: #171923;
}

.select2-container--darkgrey.select2-container--focus:focus:active,
.select2-container--dark.select2-container--focus:focus:active,
.select2-container--mixed.select2-container--focus:focus:active {
  outline: none;
}

.select2-container--darkgrey.select2-container--focus .select2-selection__rendered,
.select2-container--dark.select2-container--focus .select2-selection__rendered,
.select2-container--mixed.select2-container--focus .select2-selection__rendered {
  color: #fff;
}

.select2-container--darkgrey .select2-selection--single .select2-selection__rendered,
.select2-container--dark .select2-selection--single .select2-selection__rendered,
.select2-container--mixed .select2-selection--single .select2-selection__rendered {
  color: #a5a8b1;
}

.select2-container--darkgrey .select2-selection--single .select2-selection__placeholder,
.select2-container--dark .select2-selection--single .select2-selection__placeholder,
.select2-container--mixed .select2-selection--single .select2-selection__placeholder {
  color: #a5a8b1;
}

.select2-container--darkgrey .select2-selection--single .select2-selection__arrow,
.select2-container--dark .select2-selection--single .select2-selection__arrow,
.select2-container--mixed .select2-selection--single .select2-selection__arrow {
  color: #fff;
}

.select2-container--darkgrey.select2-container--disabled .select2-selection--single,
.select2-container--dark.select2-container--disabled .select2-selection--single,
.select2-container--mixed.select2-container--disabled .select2-selection--single {
  opacity: .8;
  cursor: not-allowed;
  background: #1e222d;
  background-color: rgba(30,34,45,0.95);
}

.select2-container--darkgrey .select2-search--inline .select2-search__field,
.select2-container--dark .select2-search--inline .select2-search__field,
.select2-container--mixed .select2-search--inline .select2-search__field {
  color: #a5a8b1;
  background: transparent;
}

.select2-container--darkgrey .select2-results__option,
.select2-container--dark .select2-results__option,
.select2-container--mixed .select2-results__option {
  color: #fff;
}

.select2-container--darkgrey .select2-results__option[aria-selected=true],
.select2-container--dark .select2-results__option[aria-selected=true],
.select2-container--mixed .select2-results__option[aria-selected=true] {
  background-color: #313543;
}

.select2-container--darkgrey .select2-results__option--highlighted[aria-selected],
.select2-container--dark .select2-results__option--highlighted[aria-selected],
.select2-container--mixed .select2-results__option--highlighted[aria-selected] {
  background-color: #dc2a5f;
  color: #fff;
}

.select2-container--darkgrey .select2-search input:focus,
.select2-container--dark .select2-search input:focus,
.select2-container--mixed .select2-search input:focus {
  outline: none;
}

.select2-container--darkgrey .select2-search input::-webkit-input-placeholder,
.select2-container--dark .select2-search input::-webkit-input-placeholder,
.select2-container--mixed .select2-search input::-webkit-input-placeholder {
  font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #a5a8b1;
}

.select2-container--darkgrey .select2-search input::-moz-placeholder,
.select2-container--dark .select2-search input::-moz-placeholder,
.select2-container--mixed .select2-search input::-moz-placeholder {
  font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #a5a8b1;
}

.select2-container--darkgrey .select2-search input:-moz-placeholder,
.select2-container--dark .select2-search input:-moz-placeholder,
.select2-container--mixed .select2-search input:-moz-placeholder {
  font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #a5a8b1;
}

.select2-container--darkgrey .select2-search input:-ms-input-placeholder,
.select2-container--dark .select2-search input:-ms-input-placeholder,
.select2-container--mixed .select2-search input:-ms-input-placeholder {
  font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #a5a8b1;
}

.select2-container--darkgrey .select2-search input::-ms-clear,
.select2-container--dark .select2-search input::-ms-clear,
.select2-container--mixed .select2-search input::-ms-clear {
  font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #a5a8b1;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.select2-container--transparent .select2-selection--single {
  background: #ede1dc;
  background-color: rgba(255,255,255,0.6);
}

.select2-container--transparent .select2-selection--single:focus {
  background: #fff;
  background: rgba(255,255,255,0.7);
  outline: none;
}

.select2-container--transparent .select2-selection--single:focus:active {
  outline: none;
}

.select2-container--transparent .select2-dropdown {
  background: #ede1dc;
  background-color: rgba(255,255,255,0.9);
}

.select2-container--transparent.select2-container--focus .select2-selection__rendered {
  color: #323849;
}

.select2-container--transparent.select2-container--focus .select2-selection__arrow b:before {
  color: #323849;
}

.select2-container--transparent .select2-selection--single .select2-selection__rendered {
  color: #323849;
}

.select2-container--transparent .select2-selection--single .select2-selection__placeholder {
  color: #323849;
}

.select2-container--transparent .select2-selection--single .select2-selection__arrow {
  color: #323849;
}

.select2-container--transparent.select2-container--disabled .select2-selection--single {
  background: #ede1dc;
  background-color: rgba(255,255,255,0.6);
}

.select2-container--transparent .select2-search--inline .select2-search__field {
  color: #323849;
  background: #ede1dc;
  background-color: rgba(255,255,255,0.6);
  border-radius: 0;
}

.select2-container--transparent .select2-search--inline .select2-search__field:focus {
  background: #fff;
  background: rgba(255,255,255,0.7);
  outline: none;
}

.select2-container--transparent .select2-search--inline .select2-search__field:focus:active {
  outline: none;
}

.select2-container--transparent .select2-search--inline:after {
  color: rgba(50,56,73,0.5);
}

.select2-container--transparent .select2-results__option {
  color: #323849;
}

.select2-container--transparent .select2-results__option[aria-selected=true] {
  background-color: #d6d7da;
}

.select2-container--transparent .select2-results__option--highlighted[aria-selected] {
  background-color: #dc2a5f;
  color: #fff;
}

.select2-container--transparent .select2-search input::-webkit-input-placeholder {
  color: #323849;
}

.select2-container--transparent .select2-search input::-moz-placeholder {
  color: #323849;
}

.select2-container--transparent .select2-search input:-moz-placeholder {
  color: #323849;
}

.select2-container--transparent .select2-search input:-ms-input-placeholder {
  color: #323849;
}

.select2-container--transparent .select2-search input::-ms-clear {
  color: #323849;
}

.select2-container--light .select2-selection--single {
  background: #ede1dc;
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--light .select2-dropdown {
  background: #ede1dc;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.select2-container--light.select2-container--focus .select2-selection--single {
  background: #fff;
  outline: none;
  border: 1px solid #fff;
}

.select2-container--light.select2-container--focus .select2-selection--single:active {
  outline: none;
}

.select2-container--light.select2-container--focus .select2-selection__rendered {
  color: #323849;
}

.select2-container--light.select2-container--focus .select2-selection__arrow b:before {
  color: #323849;
}

.select2-container--light .select2-selection--single .select2-selection__rendered {
  color: #323849;
}

.select2-container--light .select2-selection--single .select2-selection__placeholder {
  color: #323849;
}

.select2-container--light .select2-selection--single .select2-selection__arrow {
  color: #323849;
}

.select2-container--light.select2-container--disabled .select2-selection--single {
  background: #ede1dc;
  background-color: #fff;
}

.select2-container--light .select2-search--inline .select2-search__field {
  color: #323849;
  background: #ede1dc;
  background-color: #fff;
  border-radius: 0;
}

.select2-container--light .select2-search--inline .select2-search__field:focus {
  background: #fff;
  outline: none;
}

.select2-container--light .select2-search--inline:after {
  color: rgba(50,56,73,0.5);
}

.select2-container--light .select2-results__option {
  color: #323849;
}

.select2-container--light .select2-results__option[aria-selected=true] {
  background-color: #d6d7da;
}

.select2-container--light .select2-results__option--highlighted[aria-selected] {
  background-color: #dc2a5f;
  color: #fff;
}

.select2-container--light .select2-search input::-webkit-input-placeholder {
  color: #323849;
}

.select2-container--light .select2-search input::-moz-placeholder {
  color: #323849;
}

.select2-container--light .select2-search input:-moz-placeholder {
  color: #323849;
}

.select2-container--light .select2-search input:-ms-input-placeholder {
  color: #323849;
}

.select2-container--light .select2-search input::-ms-clear {
  color: #323849;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.select2-container.select2-container--mini.select2-container--open .select2-selection--single {
  background: transparent;
}

.select2-container.select2-container--mini.select2-container--open .select2-selection--single .select2-selection__arrow b {
  margin: 0;
}

.select2-container.select2-container--mini .select2-selection--single {
  padding: 4px 20px 4px 10px;
}

.select2-container.select2-container--mini .select2-selection--single .select2-selection__rendered {
  padding: 0;
  font-size: 13px;
  line-height: 13px;
}

.select2-container.select2-container--mini .select2-selection--single .select2-selection__arrow {
  right: 4px;
  width: 8px;
  top: 50%;
  margin-top: -6px;
  height: 12px;
}

.select2-container.select2-container--mini .select2-selection--single .select2-selection__arrow b {
  font-size: 9px;
  right: 5px;
  top: 0;
  height: 12px;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.select2-container.select2-container--mini .select2-selection--single .select2-selection__arrow b:before {
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  width: 8px;
  font-size: 9px;
}

.select2-container.select2-container--mini .select2-dropdown {
  overflow-x: visible;
}

.select2-container.select2-container--mini .select2-results__options {
  max-height: 250px;
  font-size: 13px;
  line-height: 18px;
  font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #3c4152;
}

.select2-container.select2-container--mini .select2-results__options .select2-results__option {
  font-size: 1em;
  padding: 6px 0 6px 10px;
  background-color: #d0d1d4;
}

.select2-container.select2-container--mini .select2-results__options .select2-results__option[aria-selected="true"] {
  background-color: #c5c6c9;
}

.select2-container.select2-container--mini .select2-results__options .select2-results__option--highlighted[aria-selected] {
  background-color: #dc2a5f;
  color: #fff;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.select2-container--natlang.select2 {
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  line-height: 13px;
  margin-top: -3px;
  max-width: 100%;
}

.select2-container--natlang.select2:not(.setwidth) {
  width: auto !important;
}

.select2-container--natlang.select2.setwidth:not(.setwidth-value) {
  min-width: 200px;
}

.select2-container--natlang .select2-selection {
  background-color: transparent;
  color: #00b1e3;
}

.select2-container--natlang .select2-selection.select2-selection {
  padding: 0;
  width: 100%;
}

.select2-container--open {
  z-index: 12;
}

.select2-container--natlang .select2-dropdown {
  min-width: 180px !important;
  background: #d7d8da;
  border-bottom: 1px solid transparent;
}

.select2-container--natlang .select2-search--dropdown input {
  background-color: #d7d8da;
}

.select2-container--natlang .select2-search--dropdown:after {
  color: #707480;
}

.no-touch .select2-container--natlang .select2-selection--single:focus {
  outline: none;
  background: none;
}

.select2-container--natlang.select2-container--focus {
  color: #00c5fc;
}

.select2-container--natlang.select2-container--focus:focus {
  outline: none;
  background: none;
}

.select2-container--natlang.select2-container--focus:focus:active {
  outline: none;
}

.select2-container--natlang.select2-container--focus .select2-selection__rendered {
  color: #323849;
}

.select2-container--natlang .select2-selection--multiple:not(.select2-selection--single) {
  min-height: 0;
}

.select2-container--natlang .select2-selection {
  display: inline-block;
  height: auto;
}

.select2-container--natlang .select2-selection .select2-selection__rendered {
  font-family: "gustan-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  color: #00b1e3;
}

.select2-container--natlang .select2-selection .select2-selection__placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgba(255,255,255,0.5);
  font-size: 16px;
  line-height: 23px;
}

.select2-container--natlang .select2-selection .select2-selection__arrow {
  right: 7px;
  color: #323849;
}

.select2-container--natlang .select2-selection .select2-selection__arrow b {
  top: 0;
  right: -6px;
  width: 12px;
}

.select2-container--natlang .select2-selection .select2-selection__arrow b:before {
  right: 0;
  width: 12px;
}

.select2-container--natlang.select2-container--disabled .select2-selection--single {
  background-color: #d7d8da;
}

.select2-container--natlang .select2-search--inline.select2-search {
  padding-right: 20px;
}

.select2-container--natlang .select2-search--inline.select2-search .select2-search__field {
  font-family: "gustan-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  background: transparent;
  color: #00b1e3;
  display: inline-block;
  font-size: 16px;
  height: 23px;
  line-height: 20px;
  margin: 0;
  margin-top: 3px;
  min-height: 0;
  min-width: 0 !important;
  padding: 0;
  text-indent: 0;
  width: 100% !important;
}

.select2-container--natlang .select2-search--inline.select2-search .select2-search__field:focus {
  outline: none;
}

.select2-container--natlang .select2-search--inline.select2-search .select2-search__field::-webkit-input-placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgba(255,255,255,0.5);
  vertical-align: middle;
  line-height: normal;
}

.select2-container--natlang .select2-search--inline.select2-search .select2-search__field::-moz-placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgba(255,255,255,0.5);
  vertical-align: middle;
  line-height: normal;
}

.select2-container--natlang .select2-search--inline.select2-search .select2-search__field:-moz-placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgba(255,255,255,0.5);
  vertical-align: middle;
  line-height: normal;
}

.select2-container--natlang .select2-search--inline.select2-search .select2-search__field:-ms-input-placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgba(255,255,255,0.5);
  vertical-align: middle;
  line-height: normal;
}

.select2-container--natlang .select2-search--inline.select2-search .select2-search__field::-ms-clear {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgba(255,255,255,0.5);
  line-height: normal;
  vertical-align: middle;
}

.select2-container--natlang .select2-search--inline.select2-search:after {
  right: 0;
  top: 7px;
  font-size: 13px;
}

.select2-container--natlang .select2-results__options li.select2-results__option {
  color: #323849;
  padding: 5px 5% 5px 5%;
  font-size: 14px;
}

.select2-container--natlang .select2-results__options li.select2-results__option[aria-selected=true] {
  background-color: #cacbce;
}

.select2-container--natlang .select2-results__options li.select2-results__option.select2-results__option--highlighted[aria-selected] {
  background-color: #dc2a5f;
  color: #fff;
}

.select2-container--natlang .select2-search input:focus {
  outline: none;
}

.select2-container--natlang .select2-search input::-webkit-input-placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #40434a;
  vertical-align: middle;
}

.select2-container--natlang .select2-search input::-moz-placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #40434a;
  vertical-align: middle;
}

.select2-container--natlang .select2-search input:-moz-placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #40434a;
  vertical-align: middle;
}

.select2-container--natlang .select2-search input:-ms-input-placeholder {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #40434a;
  vertical-align: middle;
}

.select2-container--natlang .select2-search input::-ms-clear {
  font-family: "gustan-thin-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #40434a;
  vertical-align: middle;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-close-mask {
  border: 0;
  display: block;
  height: auto;
  left: 0;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: auto;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

input.select2-search__field {
  -webkit-appearance: none;
  appearance: none;
  width: 100% !important;
}

.select2-margin .select2 {
  margin-bottom: .5rem;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.select2_filters {
  width: 100%;
}

.select2_filters~.field__error {
  margin-right: 2px;
  min-height: 40px;
  padding: 14px 14px 14px 36px;
}

.select2_filters~.field__error:before {
  left: 14px;
  top: 50%;
  margin-top: -5px;
}

.select2_filters+.select2-container {
  display: block;
  width: auto !important;
  margin: 0 0 2px 0;
}

.select2_filters+.select2-container .select2-selection__arrow {
  top: 45%;
}

.select2_filters+.select2-container .select2-selection__arrow b {
  top: -2px;
}

.select2_filters+.select2-container .select2-selection__arrow b:before {
  color: #fff;
}

.select2_filters+.select2-container .select2-selection {
  height: 4em;
  background-color: #373d4f;
}

.select2_filters+.select2-container .select2-selection .select2-selection__rendered {
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  font-size: 14px;
  line-height: 23px;
}

.select2_filters+.select2-container .select2-selection .select2-selection__placeholder {
  font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  font-size: 14px;
}

.select_2-generic .select2-results__options {
  font-size: 14px;
  line-height: 23px;
}

.select_2-generic.js-select2-theme_light .select2-search--dropdown input {
  background: #f7f7f7;
  font-size: 14px;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.select2_message__error:not(.hidden)~.select2_message__disabledonerror {
  display: none;
}

.select2_message__notice {
  line-height: 1.35em;
  margin-bottom: 0;
  padding: 15px;
  margin: 0 auto;
  max-width: 80%;
}

.accordinate .reapply_section .select2_message__disabledonerror .btn__primary,
.accordinate .reapply_section .select2_message__error {
  -webkit-transition: margin-bottom .2s ease-out;
  -moz-transition: margin-bottom .2s ease-out;
  transition: margin-bottom .2s ease-out;
  margin-bottom: 30px !important;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.selectgrid {
  display: none;
  position: fixed;
  left: 200%;
}

.selectgrid_wrapper.inactive {
  display: none;
}

.selectgrid_swap {
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  background: transparent;
  border: none;
  color: white;
  display: none;
  font-size: 20px;
  line-height: 1em;
  padding: 24.5px 0;
  text-align: center;
  width: 100%;
}

.selectgrid_swap:not(.selectgrid_swap__mobile) {
  display: none;
}

.selectgrid_swap.selectgrid_swap__visible.selectgrid_swap__mobile {
  display: inline-block;
}

.selectgrid_swap:after {
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f104";
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  transition: all .2s ease-out;
  font-size: 12px;
  padding-left: 1em;
}

.selectgrid_swap:focus {
  outline: 1px dotted #aaa;
}

.selectgrid_swap:focus:active {
  outline: none;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.footnote,
a.footnote,
.footnotes ol>li:before {
  font-family: "fira-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  content: counter(ol-counter);
  counter-increment: ol-counter;
  text-align: center;
  width: 1.8em;
  height: 1.8em;
  background: #d6d7da;
  border-radius: 50px;
  display: inline-block;
  font-size: 1em;
  line-height: 1.9em;
  margin: 0 5px 0 3px;
  position: relative;
  vertical-align: sub;
  color: #333;
}

.no-touch .footnote:hover,
.no-touch a.footnote:hover,
.no-touch .footnotes ol>li:before:hover,
.no-touch .footnote:focus,
.no-touch a.footnote:focus,
.no-touch .footnotes ol>li:before:focus {
  transition-duration: .3s;
  background: #333;
  color: #fff;
  border-bottom: 0;
}

.no-touch a.footnote:hover,
.no-touch a.footnote:focus {
  transition-duration: .3s;
  background: #333;
  color: #fff;
  border-bottom: 0;
}

.sidenote,
.footnotes {
  margin-bottom: 20px;
}

.sidenote,
.footnotes,
.sidenote p,
.footnotes p,
.sidenote li,
.footnotes li {
  font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: #444;
}

.sidenote li,
.footnotes li {
  position: relative;
}

.sidenote .ref-mark,
.footnotes .ref-mark {
  font-family: "fira-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  content: counter(ol-counter);
  counter-increment: ol-counter;
  text-align: center;
  width: 1.8em;
  height: 1.8em;
  background: #d6d7da;
  border-radius: 50px;
  display: inline-block;
  font-size: 1em;
  line-height: 1.9em;
  margin: 0 5px 0 3px;
  position: relative;
  vertical-align: sub;
  color: #333;
  top: -5px;
  display: block;
  margin: 0;
  cursor: pointer;
}

.no-touch .sidenote .ref-mark:hover,
.no-touch .footnotes .ref-mark:hover,
.no-touch .sidenote .ref-mark:focus,
.no-touch .footnotes .ref-mark:focus {
  transition-duration: .3s;
  background: #333;
  color: #fff;
  border-bottom: 0;
}

.sidenote .reversefootnote,
.footnotes .reversefootnote {
  display: none;
}

.footnotes ol {
  margin: 25px;
}

.footnotes ol>li:before {
  width: 1.7em;
  height: 1.7em;
  font-size: .8em;
  line-height: 1.8em;
  left: 0;
  vertical-align: top;
  margin-left: -25px;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.splitcols {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.splitcols:before,
.splitcols:after {
  color: transparent;
  content: ' ';
  display: block;
  height: 0;
  overflow: hidden;
}

.splitcols:after {
  clear: both;
}

.splitcols_col:only-child {
  width: 100%;
}

.splitcols_title {
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0 0 .89285714em;
}

.splitcols_litemtitle {
  font-size: 1.6em;
  line-height: 1.125em;
  margin-bottom: 0;
}

.wrapper.splitcols_wrapper {
  padding: 3em 0;
}

.listing.splitcols_listing .listing_link~div:last-child {
  padding-right: 5em;
}

.splitcols_col__blue {
  background-color: #0e7ac3;
}

.splitcols_col__blue .listing.splitcols_listing .listing_item {
  border-color: rgba(214,215,219,0.5);
}

.splitcols_col__blue .listing.splitcols_listing p,
.splitcols_col__blue .listing.splitcols_listing .metadata {
  color: white;
}

.splitcols_col__blue .listing.splitcols_listing .listing_link {
  color: #45acf2;
}

.splitcols_col__blue .splitcols_litemtitle {
  color: white;
}

.splitcols_col__blue .splitcols_title {
  color: white;
}

.splitcols_col__blue .splitcols_title a {
  color: #fff;
}

.no-touch .splitcols_col__blue .splitcols_title a:hover,
.no-touch .splitcols_col__blue .splitcols_title a:active {
  color: #323849;
}

.splitcols_col__lightgray {
  background-color: #e6e6e6;
}

.splitcols_col__lightgray .listing.splitcols_listing .listing_item {
  border-color: #d6d7db;
}

.splitcols_col__lightgray .splitcols_litemtitle {
  color: #0e7ac3;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.stay_filter__inner__dual {
  padding: 0 2em;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.stickybar {
  background-color: #0e7ac3;
  width: 100%;
  z-index: 11;
  position: relative;
  max-height: 85px;
  overflow: hidden;
  transition-duration: 0s;
  transform: translateZ(0);
  opacity: 1;
  visibility: visible;
}

.touch .stickybar.animating {
  opacity: 0;
  visibility: hidden;
}

.stickybar.active {
  max-height: 500px;
}

.stickybar.affix.stickybar_inpage+div {
  margin-top: 0;
}

.stickybar .stickybar_details {
  font-size: 1em;
  padding: 0;
  margin: 0;
}

@media screen and (max-width:320px) {
  .stickybar .stickybar_details .stickybar_byline {
    font-size: 12px;
  }
}

.stickybar .stickybar_byline {
  font-family: "gustan-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 1.25em;
}

.stickybar .stickybar_byline span {
  display: none;
}

.stickybar .visa_title {
  font-family: "gustan-light","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 1em;
  color: #fff;
  margin: 0;
  padding: 0 15px 0 0;
  display: block;
  min-height: 30px;
}

.stickybar .stickybar_points {
  font-family: "gustan-light","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5em;
  line-height: 1em;
  color: #fff;
  padding: 0;
  margin: 0;
  max-width: 80%;
}

.stickybar .stickybar_points strong {
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.4em;
  line-height: 1.42857143em;
  font-size: 1em;
  line-height: 1em;
}

.stickybar .stickybar_content {
  color: #fff;
  font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  padding: 0 0 1.66666667em;
  margin: 0;
}

.stickybar .stickybar_altcontent {
  color: #fff;
  font-family: "gustan-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  line-height: 1.66666667em;
}

.stickybar .stickybar_altcontent strong {
  padding-bottom: 1.07142857em;
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  display: block;
}

.stickybar .stickybar_altcontent a {
  color: #fff;
  border-bottom: 1px solid #fff;
  font-size: 1em;
}

.stickybar .stickybar_top {
  padding: 1em 0;
  position: relative;
}

.stickybar .stickybar_bot {
  padding: 2em 0;
  overflow: hidden;
}

.stickybar.active .stickybar_bot {
  border-top: 1px solid rgba(255,255,255,0.2);
}

.stickybar .stickybar_btn {
  text-indent: -99999px;
  overflow: hidden;
  background-color: transparent;
  color: #fff;
  position: relative;
  display: block;
  height: 25px;
  width: 25px;
  background-image: url('../../icons/arrow_circle.svg');
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 22px;
  right: 0;
  cursor: pointer;
  transition-duration: .3s;
}

.no-touch .stickybar .stickybar_btn:hover,
.no-touch .stickybar .stickybar_btn:focus {
  background-image: url('../../icons/arrow_circle_hover.svg');
  outline: none;
}

.stickybar .stickybar_btn.hasfocus {
  outline: 1px dotted #333;
}

.stickybar .stickybar_btn.active {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.stickybar .points_container {
  margin-left: 10px;
}

.container__restrict .stickybar {
  display: none;
}

.stickybar_intab.affix {
  top: 45px;
}

.affix {
  position: fixed;
  top: 0;
}

.affix.no-affix {
  position: relative;
}

.affix-bottom {
  position: fixed;
  top: 0 !important;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.stripe:before,
.stripe:after {
  color: transparent;
  content: ' ';
  display: block;
  height: 0;
  overflow: hidden;
}

.stripe:after {
  clear: both;
}

.stripe_heading {
  text-align: center;
}

.stripe__darkerblue,
.stripe__footerblue,
.stripe__darkblue {
  background-color: #323849;
}

.stripe__darkerblue,
.stripe__footerblue,
.stripe__darkblue,
.stripe__darkerblue p,
.stripe__footerblue p,
.stripe__darkblue p,
.stripe__darkerblue .stripe_heading,
.stripe__footerblue .stripe_heading,
.stripe__darkblue .stripe_heading,
.stripe__darkerblue h2,
.stripe__footerblue h2,
.stripe__darkblue h2,
.stripe__darkerblue h3,
.stripe__footerblue h3,
.stripe__darkblue h3,
.stripe__darkerblue h4,
.stripe__footerblue h4,
.stripe__darkblue h4,
.stripe__darkerblue h5,
.stripe__footerblue h5,
.stripe__darkblue h5,
.stripe__darkerblue h6,
.stripe__footerblue h6,
.stripe__darkblue h6,
.stripe__darkerblue .h2,
.stripe__footerblue .h2,
.stripe__darkblue .h2,
.stripe__darkerblue .h3,
.stripe__footerblue .h3,
.stripe__darkblue .h3,
.stripe__darkerblue .h4,
.stripe__footerblue .h4,
.stripe__darkblue .h4,
.stripe__darkerblue .h5,
.stripe__footerblue .h5,
.stripe__darkblue .h5,
.stripe__darkerblue .h6,
.stripe__footerblue .h6,
.stripe__darkblue .h6 {
  color: #fff;
}

.stripe__darkerblue a:not(.tooltip_content),
.stripe__footerblue a:not(.tooltip_content),
.stripe__darkblue a:not(.tooltip_content),
.stripe__darkerblue a:not(.tooltip_content) a:visited,
.stripe__footerblue a:not(.tooltip_content) a:visited,
.stripe__darkblue a:not(.tooltip_content) a:visited,
.stripe__darkerblue a:not(.tooltip_content) a:active,
.stripe__footerblue a:not(.tooltip_content) a:active,
.stripe__darkblue a:not(.tooltip_content) a:active {
  color: #00b1e3;
}

.no-touch .stripe__darkerblue a:not(.tooltip_content):hover,
.no-touch .stripe__footerblue a:not(.tooltip_content):hover,
.no-touch .stripe__darkblue a:not(.tooltip_content):hover,
.no-touch .stripe__darkerblue a:not(.tooltip_content) a:visited:hover,
.no-touch .stripe__footerblue a:not(.tooltip_content) a:visited:hover,
.no-touch .stripe__darkblue a:not(.tooltip_content) a:visited:hover,
.no-touch .stripe__darkerblue a:not(.tooltip_content) a:active:hover,
.no-touch .stripe__footerblue a:not(.tooltip_content) a:active:hover,
.no-touch .stripe__darkblue a:not(.tooltip_content) a:active:hover,
.no-touch .stripe__darkerblue a:not(.tooltip_content):focus,
.no-touch .stripe__footerblue a:not(.tooltip_content):focus,
.no-touch .stripe__darkblue a:not(.tooltip_content):focus,
.no-touch .stripe__darkerblue a:not(.tooltip_content) a:visited:focus,
.no-touch .stripe__footerblue a:not(.tooltip_content) a:visited:focus,
.no-touch .stripe__darkblue a:not(.tooltip_content) a:visited:focus,
.no-touch .stripe__darkerblue a:not(.tooltip_content) a:active:focus,
.no-touch .stripe__footerblue a:not(.tooltip_content) a:active:focus,
.no-touch .stripe__darkblue a:not(.tooltip_content) a:active:focus {
  color: #fff;
}

.stripe__darkerblue a:not(.tooltip_content):before,
.stripe__footerblue a:not(.tooltip_content):before,
.stripe__darkblue a:not(.tooltip_content):before,
.stripe__darkerblue a:not(.tooltip_content) a:visited:before,
.stripe__footerblue a:not(.tooltip_content) a:visited:before,
.stripe__darkblue a:not(.tooltip_content) a:visited:before,
.stripe__darkerblue a:not(.tooltip_content) a:active:before,
.stripe__footerblue a:not(.tooltip_content) a:active:before,
.stripe__darkblue a:not(.tooltip_content) a:active:before {
  color: #aaa;
  color: rgba(255,255,255,0.8);
}

.stripe__darkerblue a:not(.tooltip_content) .file_info,
.stripe__footerblue a:not(.tooltip_content) .file_info,
.stripe__darkblue a:not(.tooltip_content) .file_info {
  color: #aaa;
  color: rgba(255,255,255,0.8);
}

.stripe__darkerblue a.tooltip_content.tooltip_content,
.stripe__footerblue a.tooltip_content.tooltip_content,
.stripe__darkblue a.tooltip_content.tooltip_content {
  border-bottom: 1px dashed rgba(255,255,255,0.3);
  color: #fff;
}

.no-touch .stripe__darkerblue a.tooltip_content.tooltip_content:hover,
.no-touch .stripe__footerblue a.tooltip_content.tooltip_content:hover,
.no-touch .stripe__darkblue a.tooltip_content.tooltip_content:hover,
.no-touch .stripe__darkerblue a.tooltip_content.tooltip_content:focus,
.no-touch .stripe__footerblue a.tooltip_content.tooltip_content:focus,
.no-touch .stripe__darkblue a.tooltip_content.tooltip_content:focus {
  color: #17ccff;
}

.stripe__darkerblue a.tooltip_content.tooltip_content:active,
.stripe__footerblue a.tooltip_content.tooltip_content:active,
.stripe__darkblue a.tooltip_content.tooltip_content:active {
  border-bottom-color: #fff;
  color: #323849;
}

.stripe__white {
  background-color: #fff;
  padding-bottom: 0;
}

.stripe__darkerblue {
  background-color: #2e3343;
}

.stripe__footerblue {
  background-color: #282d3a;
}

.stripe__darkblue2 {
  background-color: #3c4252;
}

.stripe__darkblue2,
.stripe__darkblue2 p,
.stripe__darkblue2 .stripe_heading {
  color: white;
}

.stripe__blue {
  background-color: #0e7ac3;
}

.stripe__blue,
.stripe__blue p,
.stripe__blue .stripe_heading {
  color: white;
}

.stripe__grey {
  background-color: #5a5f6d;
}

.stripe__grey,
.stripe__grey p,
.stripe__grey .stripe_heading {
  color: white;
}

.stripe__light_grey {
  background-color: #f1f2f3;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.table_outer {
  position: relative;
}

.table_wrapper {
  position: relative;
  overflow-y: hidden;
  margin-top: 4em;
  padding-bottom: 1em;
}

.table_wrapper~.table_indicator {
  display: none;
  border: 0;
}

.table_wrapper.active.left~button.table_indicator.left {
  display: block;
}

.table_wrapper.active.right~button.table_indicator.right {
  display: block;
}

.table_wrapper.active~button.table_indicator {
  display: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 25px;
  background: rgba(230,230,230,0.4);
  color: #9a9a9a;
  z-index: 1;
}

.no-touch .table_wrapper.active~button.table_indicator:hover,
.no-touch .table_wrapper.active~button.table_indicator:focus {
  background: rgba(218,218,218,0.4);
  color: #8d8d8d;
  box-shadow: none;
}

.table_wrapper.active~button.table_indicator:before {
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 2;
  position: absolute;
  top: 46.5%;
  font-size: 1.5em;
  line-height: 100%;
}

.table_wrapper.active~button.table_indicator.right {
  right: 0;
}

.table_wrapper.active~button.table_indicator.right:before {
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f108";
  right: 0;
}

.table_wrapper.active~button.table_indicator.left {
  left: 0;
}

.table_wrapper.active~button.table_indicator.left:before {
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  left: 33%;
}

.table_wrapper.active table tr td:last-child,
.table_wrapper.active table tr th:last-child {
  padding-right: 20px;
}

.table_wrapper.active table thead tr th {
  white-space: nowrap;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  color: #323849;
}

table p,
table ul,
table ol,
table a,
table ol li,
table ul li {
  font-size: 1em;
}

.content table p,
.content table ul,
.content table ol,
.content table a,
.content table ol li,
.content table ul li {
  font-size: 1em;
}

.content table ul:first-child,
.content table ol:first-child {
  padding-top: 0;
}

tbody {
  border-bottom: 1px solid #d6d7da;
}

tbody tr td,
tbody tr th {
  height: 100%;
}

tbody tr td:first-child,
tbody tr th:first-child {
  text-align: left;
}

thead th {
  border-bottom: 1px solid #323849;
}

thead th:first-child {
  text-align: left;
}

th {
  font-family: "fira-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5em;
  line-height: 1.33333333em;
  vertical-align: middle;
  text-align: center;
  padding: .66666667em 1.2em .66666667em;
}

th:first-child {
  padding-left: .7em;
}

td {
  border-right: 1px solid #d6d7da;
  vertical-align: middle;
  text-align: center;
  font-family: "fira-regular","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.4em;
  line-height: 1.42857143em;
  padding: 1.43em 1.2em;
}

td:first-child {
  padding-left: .7em;
}

tr:nth-child(2n+2) {
  background-color: rgba(230,230,230,0.5);
}

td:last-child {
  padding-right: 0;
  border-right: none;
}

.table-collapsed {
  margin-bottom: 0;
  max-width: 100%;
}

.table-collapsed th {
  width: 35%;
  color: #323849;
}

.table-collapsed th .metadata {
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #323849;
}

.table-collapsed th,
.table-collapsed td {
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 10px;
  vertical-align: top;
}

.table-collapsed td {
  font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: .83333333em;
  padding-left: 20px;
  font-size: 16px;
  color: #323849;
}

.text__light .table-collapsed td {
  color: #fff;
}

.table-collapsed td p {
  margin-bottom: 0;
}

.table-collapsed tr:nth-child(2n+2) {
  background-color: transparent;
}

.table-collapsed tbody {
  border-bottom: 0;
}

.table-collapsed tr ul {
  padding-top: 0;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.tabs {
  display: none;
}

.tabs_contentwrapper:before,
.tabs_contentwrapper:after {
  color: transparent;
  content: ' ';
  display: block;
  height: 0;
  overflow: hidden;
}

.tabs_contentwrapper:after {
  clear: both;
}

.tabs_panel {
  display: none;
  margin-top: -50px;
  padding-top: 50px;
  -webkit-transition: max-height .2s ease-out;
  -moz-transition: max-height .2s ease-out;
  transition: max-height .2s ease-out;
}

.tabs_panel.current {
  display: block;
}

.tabs_panel .content {
  padding-bottom: 0;
}

.tabs_accordiontrigger,
a.tabs_accordiontrigger {
  font-size: 1.5em;
  padding-bottom: 1em;
  padding-top: 1em;
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #0e7ac3;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  color: white;
  display: block;
  margin: 0;
  padding-left: 5%;
  padding-right: 5%;
  position: relative;
  text-transform: uppercase;
  z-index: 0;
}

.no-touch .tabs_accordiontrigger:hover,
.no-touch a.tabs_accordiontrigger:hover,
.no-touch .tabs_accordiontrigger:active,
.no-touch a.tabs_accordiontrigger:active,
.no-touch .tabs_accordiontrigger:focus,
.no-touch a.tabs_accordiontrigger:focus {
  color: white;
}

.tabs_accordiontrigger:after,
a.tabs_accordiontrigger:after {
  font-size: 12px;
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f104";
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  transition: all .2s ease-out;
  margin-top: -0.5em;
  position: absolute;
  right: 5%;
  top: 50%;
  width: 12px;
}

.tabs_accordiontrigger.current:after,
a.tabs_accordiontrigger.current:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.tabs_accordiontrigger.affix,
a.tabs_accordiontrigger.affix {
  position: relative;
  z-index: 0;
}

.tabs_accordiontrigger.current.affix,
a.tabs_accordiontrigger.current.affix {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.tabs_accordiontrigger.current.affix+.tabs_panel .stickybar_inpage_outer+.wrapper .layout_header,
a.tabs_accordiontrigger.current.affix+.tabs_panel .stickybar_inpage_outer+.wrapper .layout_header {
  margin-top: 120px;
}

.tabs_tab>a,
.tabs_tab>button {
  font-size: 1.5em;
  padding: 1em .66666667em .93333333em .66666667em;
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  background: transparent;
  border: none;
  color: white;
  display: block;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
}

.no-touch .tabs_tab>a:hover,
.no-touch .tabs_tab>button:hover {
  background-color: rgba(0,0,0,0.2);
}

.tabs_tab.current>a,
.tabs_tab.current>button {
  background-color: white;
  color: #323849;
}

.no-touch .tabs_tab.current>a:hover,
.no-touch .tabs_tab.current>button:hover {
  background-color: white;
  color: #0e7ac3;
}

.tabs_tab.current.active__blue>a,
.tabs_tab.current.active__blue>button {
  background-color: #0e7ac3;
  color: white;
}

.no-touch .tabs_tab.current.active__blue:hover>a,
.no-touch .tabs_tab.current.active__blue:focus>a,
.no-touch .tabs_tab.current.active__blue:hover>button,
.no-touch .tabs_tab.current.active__blue:focus>button {
  background-color: #0c6bab;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.pre_form_actions.inactive {
  display: none;
}

.termination_accordion {
  border-top: 1px solid #d5d6d9;
}

.termination_accordion.inactive {
  display: none;
}

.termination_accordion .termination_accordion_header,
.termination_accordion .termination_accordion_content {
  width: 90%;
  margin: 0 auto;
}

.termination_accordion .termination_accordion_header {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

.termination_accordion .termination_accordion_header h2 {
  margin-right: 40px;
}

.termination_accordion .accordion_trigger {
  position: absolute;
  top: 14px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
}

.termination_accordion .accordion_trigger:after {
  font-size: 14px;
  top: 14px;
  right: 0;
  width: 13px;
  height: 13px;
}

.termination_accordion .termination_accordion_content {
  max-height: 0;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  transition: all .3s ease-out;
  overflow: hidden;
}

.termination_accordion .termination_accordion_content.active {
  max-height: 10000px;
}

.termination h1 {
  margin-top: 10px;
}

.termination .form_wrap {
  padding-bottom: 15px;
}

.termination form.form.form__inpage.form__theme-light {
  background: none;
}

.termination form.form .form_fieldset {
  background: #f3f3f3;
  margin-bottom: 15px;
}

.termination form.form .form_fieldset h3 {
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d6d9;
}

.termination form.form .form_field {
  border-bottom: 1px dotted #d5d6d9;
  margin-bottom: .75em;
  padding-bottom: 1.75em;
  box-sizing: border-box;
}

.termination form.form .form_field .err-msg {
  color: #e34f26;
  font-size: 1.2em;
  line-height: 18px;
  font-family: "fira-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
  display: block;
  top: 7px;
  padding-right: 5px;
}

.termination form.form .form_field .form_field {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
  padding-top: 0;
}

.termination form.form .form_field.parsley-success .field__error {
  display: none;
}

.termination form.form .form_field:last-of-type {
  border: 0;
  padding-bottom: 0;
}

.termination form.form .form_field .label-col {
  padding: .5em 0;
  position: relative;
}

.termination form.form .form_field .label-col label {
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
}

.termination form.form .form_field>label,
.termination form.form .form_field label {
  font-size: 1.2em;
  line-height: 18px;
}

.termination form.form .form_field textarea {
  min-height: 90px;
  width: 100%;
}

.termination form.form .form_field .field-col {
  position: relative;
}

.termination form.form .form_field .input {
  padding-left: 10px;
  padding-bottom: 10px;
  border: 1px solid #d6d7da;
  font-size: 13px;
  width: 100%;
}

.termination form.form .form_field .firstdate .input {
  border-right-width: 0;
}

.termination form.form .form_field.select_field .select2-container {
  border: 1px solid #d6d7da;
}

.termination form.form .form_field.select_field .select2-container.select2-container--focus {
  border-color: #848791;
}

.termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__rendered,
.termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__placeholder {
  font-size: 13px;
}

.termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single {
  padding-left: 10px;
}

.termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__arrow {
  right: 12px;
}

.termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__arrow b {
  right: 6px;
}

.termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__arrow b:before {
  right: -5px;
}

.termination form.form .form_field.select_field.multiple_selects .select-col .select2-container {
  border-right-width: 0;
}

.termination form.form .form_field.select_field.multiple_selects .select-col:last-child .select2-container {
  border-right-width: 1px;
}

.termination form.form .form_field.date_field .input {
  padding-right: 34px;
}

.termination form.form .form_field.parsley-error .field__error {
  position: absolute;
  top: 31px;
  right: -45px;
  background: #e34f26;
  width: 48px;
  height: 43px;
  box-sizing: border-box;
  padding: 0;
  display: none;
}

.termination form.form .form_field.parsley-error .field__error span {
  display: none;
}

.termination form.form .form_field.parsley-error .field__error:after {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 0;
  border-right: 9px solid #e34f26;
  left: 0;
  top: 12px;
  margin-left: -9px;
}

.termination form.form .form_field.parsley-error .field__error:before {
  left: 4px;
}

@media screen and (max-width:480px) {
  .termination form.form .form_field.parsley-error .field__error:before {
    left: 2px;
  }
}

.termination form.form .form_field.parsley-error.select_field.not('.multiple_selects') .select2-container {
  border-color: #e34f26;
}

.termination form.form .form_field.parsley-error.select_field .has-error .select2-container {
  border-color: #e34f26;
}

.termination form.form .form_field.parsley-error .input {
  border-color: #e34f26;
}

.termination form.form .form_field.multiple_selects .form_field.parsley-error .field__error,
.termination form.form .form_field.multiple_dates .form_field.parsley-error .field__error {
  top: 0;
}

.termination form.form .actions {
  text-align: center;
}

.termination form.form .actions input.btn {
  min-height: 45px;
  width: auto;
}

.termination .term_form .banner__alert {
  margin-bottom: 15px;
}

.termination .banner {
  padding-top: 1.6em;
  padding-bottom: 1.6em;
  max-height: none !important;
  display: block;
}

.termination .banner.inactive,
.termination .banner.hidden {
  display: none;
}

.termination .banner .banner_icon {
  padding-top: 0;
  float: left;
  display: block;
  width: 24px;
}

.termination .banner .banner_title {
  float: left;
  padding-left: 6px;
}

.termination .banner .banner_text {
  padding: 6px 0 0 30px;
}

.termination .calendarWrap .datePicker {
  width: 20px;
  height: 21px;
  background-image: url(../../images/svg_icons/calendar.svg);
}

.termination .blue-link {
  color: #00b1e3;
  font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  display: block;
  margin: 15px 55px;
  font-size: 14px;
}

.termination .blue-link span {
  border-bottom: 1px dotted #00b1e3;
}

.termination .req-text {
  text-align: right;
  margin: 0;
  padding: 0;
  color: #364054;
  font-size: 14px;
  padding-bottom: 5px;
}

.termination .req-text span {
  color: #e34f26;
}

@media screen and (max-width:360px) {
  .termination .g-recaptcha {
    transform: scale(.86);
    -webkit-transform: scale(.86);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.ticket {
  background: #f2f2f2;
  margin-bottom: 1.5em;
}

.ticket_img {
  display: block;
  width: 100%;
}

.ticket_text {
  padding: 3em 5em;
}

.ticket_title {
  margin-top: 0;
  font-size: 1.6em;
  margin-bottom: .9375em;
}

dl.ticket_info dt,
dl.ticket_info dd {
  font-size: 1.4em;
}

dl.ticket_info .link__external.ticket_link {
  font-size: 1em;
  margin-top: .28571429em;
}

.link__external.ticket_link {
  font-size: 1.4em;
  margin-left: 1.57142857em;
}

.link__external.ticket_link:before {
  left: -22px;
  top: 50%;
  font-size: .85714286em;
  margin-top: -0.5em;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.tipflag {
  position: relative;
  margin-left: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.tipflag:last-child {
  margin-bottom: 0;
}

.tipflag:last-child ul,
.tipflag:last-child ol {
  margin-bottom: 0;
}

.tipflag:before {
  content: '  ';
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f10f";
  color: #d4431b;
  position: absolute;
  left: -30px;
  top: 4px;
  font-size: 20px;
}

.tipflag p,
.tipflag ul li,
.tipflag ol li {
  line-height: 1.66666667em;
  font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #323849;
}

.tipflag ul,
.tipflag ol {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tipflag ul li,
.tipflag ol li {
  margin-bottom: 0;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.tools .btn {
  padding: 5px;
  height: 40px;
  width: 50px;
  margin: 0;
}

.tools_secondary {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e6e6e6;
  width: 100%;
  text-align: center;
  display: inline-block;
  display: flex;
  justify-content: center;
}

.tools_secondary>:first-child {
  border-left: 1px solid #fff;
}

.tools_secondary .btn__secondary {
  background: #e6e6e6;
}

.tools_secondary .title {
  display: none;
}

.no-touch .tools_secondary>.btn[class^='tools_']:hover>.btn,
.no-touch .tools_secondary>.btn[class^='tools_']:focus>.btn,
.no-touch .tools_secondary>.btn[class^='tools_'].active>.btn,
.no-touch .tools_secondary>.btn:hover,
.no-touch .tools_secondary>.btn:focus,
.no-touch .tools_secondary>.btn.active {
  background: #dc2a5f;
  color: #fff;
}

.tools_secondary .btn {
  position: relative;
  border-right: 1px solid #fff;
}

.tools_secondary .btn .title {
  white-space: nowrap;
  position: fixed;
  bottom: 40px;
  width: 0;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
}

.tools_secondary .btn [class^='glyph'] {
  font-size: 19.2px;
  margin-left: 3px;
}

.tools_secondary .btn .glyph {
  margin-left: 0;
}

.tools_secondary .btn .glyph_print span:first-child {
  margin-left: 2px;
}

.tools_secondary .btn.tools_share .btn span:first-child {
  vertical-align: text-bottom;
  font-size: 16px;
  margin-left: 5px;
}

.tools_secondary>span.btn {
  padding: 0;
}

.tools_secondary>span.btn:hover .title,
.tools_secondary>span.btn:focus .title,
.tools_secondary>span.btn.active .title {
  padding: 0;
  font-size: 0;
  height: 40px;
  width: 100%;
  left: 0;
  display: block;
}

.tools_secondary>span.btn:hover .tools_title,
.tools_secondary>span.btn:focus .tools_title,
.tools_secondary>span.btn.active .tools_title {
  display: none;
}

.tools_secondary>span.btn .title {
  background: #fff;
  padding: 0;
}

.tools_secondary>span.btn.tools_share .title {
  background: #f7f8f8;
}

.tools_secondary>span.btn.tools_share .title a.tools_option {
  margin: 5px;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  line-height: 30px;
}

.tools_secondary>span.btn.tools_share .title a.tools_option:first-child {
  margin-left: 10px;
}

.tools_secondary>span.btn.tools_share .title .tools_title {
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.tools_secondary>span.btn .glyph:first-child {
  font-size: 14px;
  white-space: nowrap;
  vertical-align: text-bottom;
}

.tools .tools_option {
  font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #fff;
  display: inline-block;
  font-size: 14px;
  color: #323849;
  padding: 0 .78571429em;
  position: relative;
}

.tools .tools_option.active {
  background-color: #f5c3d2;
}

.tools .tools_share .tools_option {
  text-indent: -3px;
  color: #ccc;
  color: rgba(50,56,73,0.7);
}

.no-touch .tools .tools_share .tools_option:hover,
.no-touch .tools .tools_share .tools_option:focus {
  color: #323849;
  text-decoration: none;
}

.tools .tools_share .tools_option.glyph_facebook {
  text-indent: 0;
}

.tools .tools_share .tools_option.glyph_gplus {
  text-indent: -2px;
}

.tools .tools_share .tools_option.glyph_linkedin {
  text-indent: -1px;
}

.tools .tools_share .tools_option.glyph_email {
  padding: 0;
  font-size: 10px;
}

.tools .tools_share .tools_option.glyph_twitter {
  padding: 0;
}

.toolswrap {
  margin-top: 50px;
}

.toolswrap .btn.btn__secondary {
  font-size: 12px;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.tooltip_isopen body {
  overflow: hidden;
}

.tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  visibility: visible;
  font-size: 11px;
  line-height: 1.4;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -webkit-transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out;
  background-color: rgba(30,34,45,0.98);
}

.tooltip.in {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.tooltip__small {
  position: fixed;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.tooltip_title {
  color: #fff;
  margin-bottom: 25px;
  margin-top: 20px;
}

.tooltip_title:first-letter {
  text-transform: capitalize;
}

.tooltip p,
.tooltip ul,
.tooltip ol {
  color: #fff;
}

.tooltip .link__external:before {
  color: #fff;
}

.tooltip a.tooltip_content.tooltip_content {
  border-bottom-color: #fff;
  color: #fff;
}

.tooltip_wrap {
  position: relative;
  padding: 10%;
  overflow: auto;
  height: 100%;
}

.tooltip-inner {
  font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  line-height: 1.66666667em;
  line-height: 1.57142857em;
  margin-bottom: 0;
  color: #fff;
  text-align: left;
  text-decoration: none;
  font-size: 12px;
  position: relative;
}

.tooltip-arrow {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: -5px;
  left: 50%;
  margin-left: -15px;
  border-width: 15px 15px 0;
  border-top-color: #323849;
}

.tooltip.bottom .tooltip-arrow {
  top: -15px;
  left: 50%;
  margin-left: 15px;
  border-width: 0 15px 15px;
  border-bottom-color: #323849;
}

.tooltip .content {
  padding-bottom: 0;
}

.tooltip a {
  color: #fff;
  border-bottom: 1px dotted #fff;
}

.no-touch .tooltip a:hover,
.no-touch .tooltip a:active {
  color: #00b1e3;
  border-bottom-color: #00b1e3;
}

.tooltip p {
  font-size: 13px;
  margin-bottom: 5px;
}

.tooltip ul {
  font-size: 10px;
  margin-left: 2.5em;
  padding-top: .5em;
  padding-bottom: .5em;
}

.tooltip ul li {
  color: #fff;
  font-size: 12px;
  margin-bottom: 3px;
}

.tooltip ul li:before {
  content: '\2014';
  color: #00b1e3;
  font-family: "fira-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  left: 0 !important;
}

.tooltip .link__download .link__title,
.tooltip .link__external .link__title,
.tooltip .link__internal .link__title {
  border-bottom: 1px dotted #fff;
  -webkit-transition: border-bottom-color .1s ease-out;
  -moz-transition: border-bottom-color .1s ease-out;
  transition: border-bottom-color .1s ease-out;
}

.tooltip .link__download:hover,
.tooltip .link__external:hover,
.tooltip .link__internal:hover,
.tooltip .link__download:focus,
.tooltip .link__external:focus,
.tooltip .link__internal:focus {
  text-decoration: none;
}

.tooltip .link__download:hover .link__title,
.tooltip .link__external:hover .link__title,
.tooltip .link__internal:hover .link__title,
.tooltip .link__download:focus .link__title,
.tooltip .link__external:focus .link__title,
.tooltip .link__internal:focus .link__title {
  border-bottom-color: #0e7ac3;
}

.tooltip .link__download:before,
.tooltip .link__external:before,
.tooltip .link__internal:before {
  position: absolute;
  color: #72757d;
}

.tooltip-sronly {
  display: none;
}

a.tooltip_content.tooltip_content {
  cursor: pointer;
  border-bottom: 1px dashed #323849;
  color: #323849;
}

.no-touch a.tooltip_content.tooltip_content:hover,
.no-touch a.tooltip_content.tooltip_content:focus {
  text-decoration: none;
  color: #00b1e3;
}

.no-touch a.tooltip_content.tooltip_content:hover .tooltip-sronly,
.no-touch a.tooltip_content.tooltip_content:focus .tooltip-sronly {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

a.tooltip_content.tooltip_content:active {
  border-bottom-color: #323849;
  color: #323849;
}

.tooltip_text {
  display: none;
}

.header_real_tip .link__external,
.header_real_tip .link__internal {
  border-bottom: none;
}

.header_real_tip .link__external:before,
.header_real_tip .link__internal:before {
  color: #00b1e3;
}

.header_real_tip .link__title {
  display: inline-block;
  border-bottom: 1px dotted #fff;
  margin-left: 10px;
}

a:hover .header_real_tip .link__title {
  border-bottom-color: #00b1e3;
}

.header_real_tip .link__external:before {
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f11c";
  font-size: 1em;
  left: -1.875em;
  top: .313em;
}

.header_real_tip .link__internal {
  margin: .625em 0 .625em 1.875em;
  display: inline-block;
}

.header_real_tip .link__internal:before {
  font-family: "glyphs";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f11d";
  font-size: .75em;
  left: -2.5em;
  top: .667em;
}

.header_mobile .realme_mobile_link .header__realme_info {
  color: #fff;
  border-color: #fff;
}

@font-face {
  font-family: "glyphs";
  src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
  src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.show-animate {
  transition: all .3s;
  max-height: 100%;
  height: auto;
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

.hide-animate {
  transition: all .3s;
  max-height: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
}

.translatable_content {
  border-bottom: 1px solid #d5d6d9;
  margin-bottom: 3em;
}

.translatable_content .translation {
  margin-top: 1em;
}

.text-block {
  display: inline-block;
}

.xs-m0 {
  margin: 0;
}

.xs-mt0 {
  margin-top: 0;
}

.xs-mr0 {
  margin-right: 0;
}

.xs-mb0 {
  margin-bottom: 0;
}

.xs-ml0 {
  margin-left: 0;
}

.xs-m1 {
  margin: .5rem;
}

.xs-mt1 {
  margin-top: .5rem;
}

.xs-mr1 {
  margin-right: .5rem;
}

.xs-mb1 {
  margin-bottom: .5rem;
}

.xs-ml1 {
  margin-left: .5rem;
}

.xs-m2 {
  margin: 1rem;
}

.xs-mt2 {
  margin-top: 1rem;
}

.xs-mr2 {
  margin-right: 1rem;
}

.xs-mb2 {
  margin-bottom: 1rem;
}

.xs-ml2 {
  margin-left: 1rem;
}

.xs-m3 {
  margin: 2rem;
}

.xs-mt3 {
  margin-top: 2rem;
}

.xs-mr3 {
  margin-right: 2rem;
}

.xs-mb3 {
  margin-bottom: 2rem;
}

.xs-ml3 {
  margin-left: 2rem;
}

.xs-m4 {
  margin: 2.6rem;
}

.xs-mt4 {
  margin-top: 2.6rem;
}

.xs-mr4 {
  margin-right: 2.6rem;
}

.xs-mb4 {
  margin-bottom: 2.6rem;
}

.xs-ml4 {
  margin-left: 2.6rem;
}

.xs-m5 {
  margin: 3rem;
}

.xs-mt5 {
  margin-top: 3rem;
}

.xs-mr5 {
  margin-right: 3rem;
}

.xs-mb5 {
  margin-bottom: 3rem;
}

.xs-ml5 {
  margin-left: 3rem;
}

.xs-m6 {
  margin: 5.45rem;
}

.xs-mt6 {
  margin-top: 5.45rem;
}

.xs-mr6 {
  margin-right: 5.45rem;
}

.xs-mb6 {
  margin-bottom: 5.45rem;
}

.xs-ml6 {
  margin-left: 5.45rem;
}

.xs-mx1 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.xs-mx2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.xs-mx3 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.xs-mx4 {
  margin-left: 2.6rem;
  margin-right: 2.6rem;
}

.xs-mx5 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.xs-mx6 {
  margin-left: 5.45rem;
  margin-right: 5.45rem;
}

.xs-my1 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.xs-my2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.xs-my3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.xs-my4 {
  margin-top: 2.6rem;
  margin-bottom: 2.6rem;
}

.xs-my5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.xs-my6 {
  margin-top: 5.45rem;
  margin-bottom: 5.45rem;
}

.xs-mxn1 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.xs-mxn2 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.xs-mxn3 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.xs-mxn4 {
  margin-left: -2.6rem;
  margin-right: -2.6rem;
}

.xs-mxn5 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.xs-mxn6 {
  margin-left: -5.45rem;
  margin-right: -5.45rem;
}

.xs-myn1 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.xs-myn2 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.xs-myn3 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.xs-myn4 {
  margin-top: -2.6rem;
  margin-bottom: -2.6rem;
}

.xs-myn5 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.xs-myn6 {
  margin-top: -5.45rem;
  margin-bottom: -5.45rem;
}

.xs-mtn1 {
  margin-top: -0.5rem !important;
}

.xs-mtn2 {
  margin-top: -1rem;
}

.xs-mtn3 {
  margin-top: -2rem;
}

.xs-mtn4 {
  margin-top: -2.6rem;
}

.xs-mtn5 {
  margin-top: -3rem;
}

.xs-mtn6 {
  margin-top: -5.45rem;
}

.xs-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.padding-base {
  padding-left: 5%;
  padding-right: 5%;
}

.padding-base-lg {
  padding-left: 10%;
  padding-right: 10%;
}

.xs-p0 {
  padding: 0;
}

.xs-pt0 {
  padding-top: 0;
}

.xs-pr0 {
  padding-right: 0;
}

.xs-pb0 {
  padding-bottom: 0;
}

.xs-pl0 {
  padding-left: 0;
}

.xs-p1 {
  padding: .5rem;
}

.xs-py1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.xs-px1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.xs-p2 {
  padding: 1rem;
}

.xs-py2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.xs-px2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.xs-p3 {
  padding: 2rem;
}

.xs-py3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.xs-px3 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.xs-p4 {
  padding: 2.6rem;
}

.xs-py4 {
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
}

.xs-px4 {
  padding-left: 2.6rem;
  padding-right: 2.6rem;
}

.xs-p5 {
  padding: 3rem;
}

.xs-py5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.xs-px5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.xs-p6 {
  padding: 5.45rem;
}

.xs-py6 {
  padding-top: 5.45rem;
  padding-bottom: 5.45rem;
}

.xs-px6 {
  padding-left: 5.45rem;
  padding-right: 5.45rem;
}

.xs-p7 {
  padding: 6.25rem;
}

.xs-py7 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.xs-px7 {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}

.xs-p8 {
  padding: 11.111111rem;
}

.xs-py8 {
  padding-top: 11.111111rem;
  padding-bottom: 11.111111rem;
}

.xs-px8 {
  padding-left: 11.111111rem;
  padding-right: 11.111111rem;
}

.xs-pt1 {
  padding-top: .5rem;
}

.xs-pb1 {
  padding-bottom: .5rem;
}

.xs-pt2 {
  padding-top: 1rem;
}

.xs-pb2 {
  padding-bottom: 1rem;
}

.xs-pt3 {
  padding-top: 2rem;
}

.xs-pb3 {
  padding-bottom: 2rem;
}

.xs-pt4 {
  padding-top: 2.6rem;
}

.xs-pb4 {
  padding-bottom: 2.6rem;
}

.xs-pt5 {
  padding-top: 3rem;
}

.xs-pb5 {
  padding-bottom: 3rem;
}

.xs-pt6 {
  padding-top: 5.45rem;
}

.xs-pb6 {
  padding-bottom: 5.45rem;
}

.xs-pt7 {
  padding-top: 6.25rem;
}

.xs-pb7 {
  padding-bottom: 6.25rem;
}

.xs-pt8 {
  padding-top: 11.111111rem;
}

.xs-pb8 {
  padding-bottom: 11.111111rem;
}

.xs-pr1 {
  padding-right: .5rem;
}

.xs-pl1 {
  padding-left: .5rem;
}

.xs-pr2 {
  padding-right: 1rem;
}

.xs-pl2 {
  padding-left: 1rem;
}

.xs-pr3 {
  padding-right: 2rem;
}

.xs-pl3 {
  padding-left: 2rem;
}

.xs-pr4 {
  padding-right: 2.6rem;
}

.xs-pl4 {
  padding-left: 2.6rem;
}

.xs-pr5 {
  padding-right: 3rem;
}

.xs-pl5 {
  padding-left: 3rem;
}

.xs-pr6 {
  padding-right: 5.45rem;
}

.xs-pl6 {
  padding-left: 5.45rem;
}

.xs-pr7 {
  padding-right: 6.25rem;
}

.xs-pl7 {
  padding-left: 6.25rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {

}

@media only screen and (min-width: 30em) {
  /* aligns to bootstrap: 480; */

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .content dl,
  .process dl {
    clear: both;
  }

  .content dl:before,
  .process dl:before,
  .content dl:after,
  .process dl:after {
    color: transparent;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .content dl:after,
  .process dl:after {
    clear: both;
  }

  .content dt,
  .process dt {
    font-family: "fira-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    display: inline-block;
    width: 35%;
    float: left;
  }

  .content dd,
  .process dd {
    display: inline-block;
    width: 65%;
    float: left;
  }

  .content dd:before,
  .process dd:before,
  .content dd:after,
  .process dd:after {
    color: transparent;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .content dd:after,
  .process dd:after {
    clear: both;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .layout_header {
    padding-left: 0;
    padding-right: 0;
  }

  .visible_base,
  .visible_medium,
  .visible_large,
  .visible_base.btn,
  .visible_medium.btn,
  .visible_large.btn,
  tr.visible_base,
  tr.visible_medium,
  tr.visible_large,
  th.visible_base,
  th.visible_medium,
  th.visible_large,
  td.visible_base,
  td.visible_medium,
  td.visible_large {
    display: none !important;
  }

  .visible_small {
    display: block !important;
  }

  table.visible_small {
    display: table;
  }

  tr.visible_small {
    display: table-row !important;
  }

  th.visible_small,
  td.visible_small {
    display: table-cell !important;
  }

  .visible_small.btn {
    display: inline-block !important;
  }

  .hidden_base,
  .hidden_medium,
  .hidden_large {
    display: block !important;
  }

  table.hidden_base,
  table.hidden_medium,
  table.hidden_large {
    display: table;
  }

  tr.hidden_base,
  tr.hidden_medium,
  tr.hidden_large {
    display: table-row !important;
  }

  th.hidden_base,
  th.hidden_medium,
  th.hidden_large,
  td.hidden_base,
  td.hidden_medium,
  td.hidden_large {
    display: table-cell !important;
  }

  .hidden_base.btn,
  .hidden_medium.btn,
  .hidden_large.btn {
    display: inline-block !important;
  }

  .hidden_small,
  .hidden_small.btn,
  tr.hidden_small,
  th.hidden_small,
  td.hidden_small {
    display: none !important;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .banner__centered {
    text-align: center;
  }

  .banner__centeredwrap {
    display: inline-block;
  }

  .banner__inline .banner_text {
    max-width: 100%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .bgpattern.bgpattern_employers:before {
    top: 0;
  }

  .bgpattern.bgpattern_employers:after {
    height: 100%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .blocknav {
    min-height: 400px;
  }

  .blocknav_align,
  .blocknav_label {
    padding-left: 22px;
    padding-right: 22px;
  }

  .blocknav_title {
    font-size: 3.2em;
    margin-bottom: 30px;
  }

  .blocknav_blockwrap {
    margin: 0 auto;
  }

  .blocknav_blockwrap:before,
  .blocknav_blockwrap:after {
    color: transparent;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .blocknav_blockwrap:after {
    clear: both;
  }

  .blocknav_block {
    margin: 0 auto;
    padding: 40px 0 30px;
  }

  .blocknav_item__breaksm {
    clear: both;
  }

  .blocknav_searchgroup {
    white-space: nowrap;
  }

  .blocknav_search {
    width: 71%;
    width: calc(100% - 115px);
  }

  .blocknav_centered-sm {
    display: inline-block;
    max-width: 416px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .btn__lrg {
    font-size: 1.4em;
  }

  .btn__inline_right {
    float: right;
    margin: 0;
  }

  .btn__centered {
    margin-top: 15px;
  }

  [data-action-select].margin-onshow:not(.disabled):not(:disabled).btn__centered,
  .btn_hidedisabled.margin-onshow:not(.disabled):not(:disabled).btn__centered {
    margin-top: 15px;
  }

  [data-action-select].margin-onshow:not(.disabled):not(:disabled).btn__blockmd,
  .btn_hidedisabled.margin-onshow:not(.disabled):not(:disabled).btn__blockmd {
    margin-top: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .contentblock_wrap {
    margin: 0;
  }

  .contentblock_wrap:before,
  .contentblock_wrap:after {
    color: transparent;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .contentblock_wrap:after {
    clear: both;
  }

  .contentblock_heading .intro {
    max-width: 65%;
  }

  .content__maxwidth {
    width: 83.33333333%;
    margin-left: auto;
    margin-right: auto;
  }

  .content__maxwidth:before,
  .content__maxwidth:after {
    color: transparent;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .content__maxwidth:after {
    clear: both;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dock_tools.tools .tools_close.btn {
    left: -40px;
    width: 40px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .form_fieldset {
    padding: 18px 20px;
  }

  .panel_contact .form_fieldset {
    padding: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .image__small {
    margin: 0;
    float: left;
    margin-right: 0;
    margin-bottom: 3em;
  }

  .image__small .image_wrapper {
    width: 100%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .linkgrid_row {
    margin: 0 auto;
  }

  .linkgrid_title {
    padding-right: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .listing_item__banner .contact_name {
    width: 45%;
  }

  .listing_item__banner .link,
  .listing_item__banner .number {
    display: inline-block;
    max-width: 50%;
    margin-left: 10px;
  }

  .listing_item__banner .number {
    margin-left: 10px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .motivegrid {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
    max-width: 453px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .process .popout {
    top: 0;
  }

  .process .popout_set {
    padding-left: 16.66666667%;
  }

  .process .popout_set>.contentblock_centered {
    margin-left: -2em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .processnav {
    width: 80%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .publishinfo {
    margin-bottom: 3em;
  }

  .publishinfo_title {
    margin: .88em 0 1.12em;
  }

  dl.publishinfo_list dt {
    clear: left;
  }

  .results__search,
  .results__resources {
    padding: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .splitcols_col.col-sm-6:not(:only-child) {
    display: table-cell;
    float: none;
    vertical-align: top;
  }

  .splitcols_col.col-sm-6:not(:only-child) .wrapper.splitcols_wrapper {
    max-width: 65em;
    padding: 5em 5em 5em 0;
    float: right;
  }

  .splitcols_col.col-sm-6:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper {
    padding: 5em 0 5em 5em;
    float: left;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .stickybar .stickybar_content {
    font-size: 1.2em;
  }

  .stickybar .stickybar_altcontent {
    font-family: "gustan-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.66666667em;
  }

  .stickybar .points_container {
    margin-left: 0;
  }

  .stickybar .visa_title {
    font-family: "gustan-light","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }

  .stickybar .stickybar_points {
    font-family: "gustan-light","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.42857143em;
  }

  .stickybar .stickybar_byline span+span {
    display: inline-block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .table-collapsed th {
    width: 20%;
  }

  .text-block {
    display: inline-block;
  }

  .sm-m0 {
    margin: 0;
  }

  .sm-mt0 {
    margin-top: 0;
  }

  .sm-mr0 {
    margin-right: 0;
  }

  .sm-mb0 {
    margin-bottom: 0;
  }

  .sm-ml0 {
    margin-left: 0;
  }

  .sm-m1 {
    margin: .5rem;
  }

  .sm-mt1 {
    margin-top: .5rem;
  }

  .sm-mr1 {
    margin-right: .5rem;
  }

  .sm-mb1 {
    margin-bottom: .5rem;
  }

  .sm-ml1 {
    margin-left: .5rem;
  }

  .sm-m2 {
    margin: 1rem;
  }

  .sm-mt2 {
    margin-top: 1rem;
  }

  .sm-mr2 {
    margin-right: 1rem;
  }

  .sm-mb2 {
    margin-bottom: 1rem;
  }

  .sm-ml2 {
    margin-left: 1rem;
  }

  .sm-m3 {
    margin: 2rem;
  }

  .sm-mt3 {
    margin-top: 2rem;
  }

  .sm-mr3 {
    margin-right: 2rem;
  }

  .sm-mb3 {
    margin-bottom: 2rem;
  }

  .sm-ml3 {
    margin-left: 2rem;
  }

  .sm-m4 {
    margin: 2.6rem;
  }

  .sm-mt4 {
    margin-top: 2.6rem;
  }

  .sm-mr4 {
    margin-right: 2.6rem;
  }

  .sm-mb4 {
    margin-bottom: 2.6rem;
  }

  .sm-ml4 {
    margin-left: 2.6rem;
  }

  .sm-m5 {
    margin: 3rem;
  }

  .sm-mt5 {
    margin-top: 3rem;
  }

  .sm-mr5 {
    margin-right: 3rem;
  }

  .sm-mb5 {
    margin-bottom: 3rem;
  }

  .sm-ml5 {
    margin-left: 3rem;
  }

  .sm-m6 {
    margin: 5.45rem;
  }

  .sm-mt6 {
    margin-top: 5.45rem;
  }

  .sm-mr6 {
    margin-right: 5.45rem;
  }

  .sm-mb6 {
    margin-bottom: 5.45rem;
  }

  .sm-ml6 {
    margin-left: 5.45rem;
  }

  .sm-mx1 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .sm-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm-mx4 {
    margin-left: 2.6rem;
    margin-right: 2.6rem;
  }

  .sm-mx5 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm-mx6 {
    margin-left: 5.45rem;
    margin-right: 5.45rem;
  }

  .sm-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .sm-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm-mxn4 {
    margin-left: -2.6rem;
    margin-right: -2.6rem;
  }

  .sm-mxn5 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm-mxn6 {
    margin-left: -5.45rem;
    margin-right: -5.45rem;
  }

  .sm-myn1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .sm-myn2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm-myn3 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm-myn4 {
    margin-top: -2.6rem;
    margin-bottom: -2.6rem;
  }

  .sm-myn5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm-myn6 {
    margin-top: -5.45rem;
    margin-bottom: -5.45rem;
  }

  .sm-mtn1 {
    margin-top: -0.5rem;
  }

  .sm-mtn2 {
    margin-top: -1rem;
  }

  .sm-mtn3 {
    margin-top: -2rem;
  }

  .sm-mtn4 {
    margin-top: -2.6rem;
  }

  .sm-mtn5 {
    margin-top: -3rem;
  }

  .sm-mtn6 {
    margin-top: -5.45rem;
  }

  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm-p0 {
    padding: 0;
  }

  .sm-pt0 {
    padding-top: 0;
  }

  .sm-pr0 {
    padding-right: 0;
  }

  .sm-pb0 {
    padding-bottom: 0;
  }

  .sm-pl0 {
    padding-left: 0;
  }

  .sm-p1 {
    padding: .5rem;
  }

  .sm-py1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm-px1 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm-p2 {
    padding: 1rem;
  }

  .sm-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm-p3 {
    padding: 2rem;
  }

  .sm-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm-p4 {
    padding: 2.6rem;
  }

  .sm-py4 {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }

  .sm-px4 {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }

  .sm-p5 {
    padding: 3rem;
  }

  .sm-py5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm-px5 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm-p6 {
    padding: 5.45rem;
  }

  .sm-py6 {
    padding-top: 5.45rem;
    padding-bottom: 5.45rem;
  }

  .sm-px6 {
    padding-left: 5.45rem;
    padding-right: 5.45rem;
  }

  .sm-p7 {
    padding: 6.25rem;
  }

  .sm-py7 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .sm-px7 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .sm-p8 {
    padding: 11.111111rem;
  }

  .sm-py8 {
    padding-top: 11.111111rem;
    padding-bottom: 11.111111rem;
  }

  .sm-px8 {
    padding-left: 11.111111rem;
    padding-right: 11.111111rem;
  }

  .sm-pt1 {
    padding-top: .5rem;
  }

  .sm-pb1 {
    padding-bottom: .5rem;
  }

  .sm-pt2 {
    padding-top: 1rem;
  }

  .sm-pb2 {
    padding-bottom: 1rem;
  }

  .sm-pt3 {
    padding-top: 2rem;
  }

  .sm-pb3 {
    padding-bottom: 2rem;
  }

  .sm-pt4 {
    padding-top: 2.6rem;
  }

  .sm-pb4 {
    padding-bottom: 2.6rem;
  }

  .sm-pt5 {
    padding-top: 3rem;
  }

  .sm-pb5 {
    padding-bottom: 3rem;
  }

  .sm-pt6 {
    padding-top: 5.45rem;
  }

  .sm-pb6 {
    padding-bottom: 5.45rem;
  }

  .sm-pt7 {
    padding-top: 6.25rem;
  }

  .sm-pb7 {
    padding-bottom: 6.25rem;
  }

  .sm-pt8 {
    padding-top: 11.111111rem;
  }

  .sm-pb8 {
    padding-bottom: 11.111111rem;
  }

  .sm-pr1 {
    padding-right: .5rem;
  }

  .sm-pl1 {
    padding-left: .5rem;
  }

  .sm-pr2 {
    padding-right: 1rem;
  }

  .sm-pl2 {
    padding-left: 1rem;
  }

  .sm-pr3 {
    padding-right: 2rem;
  }

  .sm-pl3 {
    padding-left: 2rem;
  }

  .sm-pr4 {
    padding-right: 2.6rem;
  }

  .sm-pl4 {
    padding-left: 2.6rem;
  }

  .sm-pr5 {
    padding-right: 3rem;
  }

  .sm-pl5 {
    padding-left: 3rem;
  }

  .sm-pr6 {
    padding-right: 5.45rem;
  }

  .sm-pl6 {
    padding-left: 5.45rem;
  }

  .sm-pr7 {
    padding-right: 6.25rem;
  }

  .sm-pl7 {
    padding-left: 6.25rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2)       and (min-width: 30em),
only screen and (   min--moz-device-pixel-ratio: 2)       and (min-width: 30em),
only screen and (     -o-min-device-pixel-ratio: 2/1)     and (min-width: 30em),
only screen and (        min-device-pixel-ratio: 2)       and (min-width: 30em),
only screen and (                min-resolution: 192dpi)  and (min-width: 30em),
only screen and (                min-resolution: 2dppx)   and (min-width: 30em) {

}

@media only screen and (min-width: 46.875em) {
  /* aligns to bootstrap: 750; */

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  h1,
  .h1 {
    font-family: "gustan-thin","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 4.5em;
    line-height: 1.22222222em;
    margin-bottom: .22222222em;
    color: #323849;
    padding-top: .55555556em;
  }

  h1 .secondary_title,
  .h1 .secondary_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 25px;
    margin-top: .4em;
  }

  h2,
  .h2 {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5em;
    line-height: 1.2em;
    margin-top: .8em;
    margin-bottom: .8em;
    color: #323849;
  }

  h3,
  .h3 {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    line-height: 1.25em;
    margin-top: 0;
    margin-bottom: .5em;
    color: #323849;
  }

  h4,
  .h4 {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.66666667em;
    color: #0e7ac3;
    margin-top: .55555556em;
    margin-bottom: .55555556em;
  }

  h4.small,
  .h4.small {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.3em;
  }

  h4.large,
  .h4.large {
    font-family: "gustan-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    line-height: 1.25em;
    margin-bottom: .5em;
  }

  h5,
  .h5,
  h6,
  .h6 {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.42857143em;
    color: #323849;
    margin-top: .71428571em;
    margin-bottom: .71428571em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  p.intro {
    font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.42857143em;
    padding-left: 30px;
    margin-top: 1.42857143em;
    margin-bottom: 1.42857143em;
  }

  p.intro:before {
    content: '  ';
    position: absolute;
    top: 3px;
    bottom: 7px;
    left: 0;
    background-color: #00b1e3;
    width: 8px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .wrapper {
    max-width: 1560px;
    width: 90%;
    margin: 0 auto;
  }

  .layout_header {
    padding-bottom: 4rem;
    padding-top: 1rem;
  }

  .layout_header.text-center {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .dock .layout_header {
    padding: 0;
  }

  .layout_header__title {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }

  .layout_header__intab {
    padding-bottom: 0;
    padding-top: 1rem;
    margin-bottom: 0;
  }

  .col_bordered_x {
    border-left: 1px solid #e5e5e8;
    border-right: 1px solid #e5e5e8;
  }

  .col_bordered_left {
    margin-left: -1px;
    border-left: 1px solid #e5e5e8;
  }

  .col_bordered_right {
    margin-right: -1px;
    border-right: 1px solid #e5e5e8;
  }

  .row_equal {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .row_equal>div {
    flex: 1 auto;
    position: relative;
    float: left;
    align-self: stretch;
  }

  .banner .row_equal>div.col-pgmd-2 {
    flex: 0 8%;
    padding: 0;
  }

  .banner .row_equal>div.col-pgmd-8 {
    flex: 2 0;
    padding: 0 15px 0 20px;
  }

  .banner .row_equal>div.col-pgmd-9 {
    flex: 3 0;
    padding: 0 15px 0 20px;
  }

  .contentblock_centered .banner .row_equal>div.col-pgmd-8 {
    flex: 1;
    padding: 0 15px 0 20px;
  }

  .contentblock_centered .banner .row_equal>div.col-pgmd-9 {
    flex: 3;
  }

  .row_equal>div.col-pgmd-6 {
    flex: 3.5;
  }

  .row_equal>div.col-pgmd-9 {
    flex: 5;
  }

  .ie9 .row_equal>div.col-pgmd-9 {
    width: 350px;
  }

  .row_equal>div.col-pgmd-8 {
    flex: 4;
  }

  .row_equal__child_fill>div {
    display: flex;
  }

  .row_equal__child_fill>div>div {
    flex: 1;
  }

  .container__restrict {
    position: fixed;
  }

  .container__restrict .container_content,
  .container__restrict .footer {
    display: block;
  }

  .container_shader {
    background: rgba(255,255,255,0);
  }

  .container__restrict .container_shader {
    background: rgba(255,255,255,0.7);
  }

  .visible_base,
  .visible_small,
  .visible_large,
  .visible_base.btn,
  .visible_small.btn,
  .visible_large.btn,
  tr.visible_base,
  tr.visible_small,
  tr.visible_large,
  th.visible_base,
  th.visible_small,
  th.visible_large,
  td.visible_base,
  td.visible_small,
  td.visible_large {
    display: none !important;
  }

  .visible_medium {
    display: block !important;
  }

  table.visible_medium {
    display: table;
  }

  tr.visible_medium {
    display: table-row !important;
  }

  th.visible_medium,
  td.visible_medium {
    display: table-cell !important;
  }

  .visible_medium.btn {
    display: inline-block !important;
  }

  .hidden_base,
  .hidden_small,
  .hidden_large {
    display: block !important;
  }

  table.hidden_base,
  table.hidden_small,
  table.hidden_large {
    display: table;
  }

  tr.hidden_base,
  tr.hidden_small,
  tr.hidden_large {
    display: table-row !important;
  }

  th.hidden_base,
  th.hidden_small,
  th.hidden_large,
  td.hidden_base,
  td.hidden_small,
  td.hidden_large {
    display: table-cell !important;
  }

  .hidden_base.btn,
  .hidden_small.btn,
  .hidden_large.btn {
    display: inline-block !important;
  }

  .hidden_medium,
  .hidden_medium.btn,
  tr.hidden_medium,
  th.hidden_medium,
  td.hidden_medium {
    display: none !important;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .accordinate__lg_title {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .accordinate__lg_title .accordion_trigger_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 31.5px 0 35px;
    font-size: 2.1em;
    line-height: 1.26666667em;
    margin: 0;
  }

  .accordinate__lg_title .accordion_trigger:after {
    top: 42%;
    width: 21px;
    height: 17px;
  }

  .accordion_trigger {
    margin-right: 0;
    overflow: visible;
  }

  .accordion_trigger:after {
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f104";
    color: #0e7ac3;
    text-indent: 0;
    position: absolute;
    right: 22px;
    text-align: right;
    font-size: 2em;
    font-weight: 700;
    top: 50%;
    width: 25px;
    height: 25px;
    overflow: visible;
    vertical-align: top;
    -webkit-transition: -webkit-transform .2s ease-out;
    transition: transform .2s ease-out;
    transform-origin: 62% 34%;
  }

  .accordion_trigger.accordinate__trigger_all:after {
    right: 10px;
  }

  .accordion_trigger.accordion_trigger__open .cta_dismiss {
    display: inline-block;
  }

  .accordion_trigger.accordion_trigger__open .cta_show {
    display: none;
  }

  .accordion_trigger.accordinate__cta.accordinate__trigger_all {
    margin-bottom: 24px;
    border: 1px solid #0e7ac3;
  }

  .accordinate__clickable .accordion_trigger.accordinate__cta:after {
    margin-right: 0;
  }

  .accordion_trigger.accordinate__cta.accordinate__trigger_all {
    margin-right: 0;
    padding-right: 30px;
  }

  .accordion_trigger.accordinate__cta.accordinate__trigger_all:after {
    right: 12px;
  }

  .accordinate__clickable .accordion_trigger.accordinate__cta {
    padding-right: 30px;
  }

  .accordinate__clickable .accordion_trigger.accordinate__cta:after {
    right: 12px;
  }

  .criteria_accordion {
    padding-left: 7%;
    padding-right: 7%;
  }

  .criteria_accordion__visafactsheet {
    padding: 0 5% 50px;
  }

  .dock .criteria_accordion {
    padding: 0;
    margin-top: 50px;
  }

  .dock .criteria_accordion .accordinate__title {
    padding-right: 7px;
  }

  .criteria_accordion>h2 {
    padding-left: 0;
  }

  .criteria_accordion .accordinate__clickable .accordion_trigger.accordinate__cta {
    padding-right: 30px;
  }

  .criteria_accordion .accordinate__clickable .accordion_trigger.accordinate__cta:after {
    right: 12px;
  }

  .criteria_accordion .accordion_trigger.accordinate__cta.accordinate__trigger_all {
    margin-right: 0;
  }

  .criteria_accordion .accordion_trigger.accordinate__cta.accordinate__trigger_all#criteria_toggle_hide:after {
    right: 10px;
  }

  .criteria_accordion .accordion_trigger.accordinate__cta.accordinate__trigger_all.active:after {
    right: 8px;
    top: 22px;
  }

  .criteria_accordion .accordion_trigger.accordinate__cta.accordinate__trigger_all.active,
  .criteria_accordion .accordion_trigger.accordinate__cta.accordinate__trigger_all:hover {
    color: #0e7ac3;
  }

  .criteria_accordion .accordion_trigger.accordinate__cta.accordinate__trigger_all.active:after,
  .criteria_accordion .accordion_trigger.accordinate__cta.accordinate__trigger_all:hover:after {
    color: #0e7ac3;
  }

  .criteria_accordion.has_left_nav,
  .question_container.has_left_nav {
    padding-top: 0;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__cta {
    right: 0;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__blurb {
    font-size: 1.6em;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__title_blurb_cta,
  .accordinate__title_blurb_cta_wrapper .accordion_content_inner {
    padding-left: 0;
    padding-right: 0;
  }

  .accordion_trigger.accordinate__cta>.cta_dismiss {
    display: none;
  }

  .accordion_trigger.accordinate__cta>.cta_show {
    display: inline-block;
  }

  .accordion_trigger.accordinate__cta.active>.cta_dismiss {
    display: inline-block;
  }

  .accordion_trigger.accordinate__cta.active>.cta_show {
    display: none;
  }

  .accordion_heading {
    display: none;
  }

  .accordion_body {
    max-height: none;
    overflow: visible;
  }

  .accordion_group__flush {
    margin: 0;
  }

  .accordion_group__flush .accordion_body {
    padding: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .alternatepathway {
    padding: 1.2em 0 1.6em;
  }

  .alternatepathway_heading {
    font-size: 2.5em;
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  .alternatepathway_text {
    font-size: 1.6em;
    line-height: 1.5625em;
  }

  .alternatepathway_text__short {
    display: none;
  }

  .alternatepathway_text__short+.alternatepathway_text__full {
    display: block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .banner.open {
    padding-top: 2.35em;
    padding-bottom: 2.35em;
  }

  .banner_icon {
    font-size: 26px;
    line-height: 34px;
  }

  .banner_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3em;
    line-height: 1.26666667em;
    margin-bottom: .33333333em;
    padding: 0;
  }

  .banner_type {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    margin-bottom: .3125em;
    display: block;
  }

  .banner_timing {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.66666667em;
    padding: 0;
  }

  .banner_timing strong {
    font-family: "fira-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.66666667em;
  }

  .banner_readmore {
    display: none;
  }

  .banner_text {
    padding-left: 0;
  }

  .banner_texttitle {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.25em;
    margin-bottom: .41666667em;
    width: 90%;
  }

  .banner_text p,
  .banner_textcopy {
    display: block;
  }

  .banner_text p strong,
  .banner_textcopy strong {
    font-family: "fira-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.25em;
    font-size: 1em;
  }

  .banner_dismiss {
    font-size: 2em;
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    right: -6%;
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f10a";
  }

  .banner_promo-link {
    padding: .94444444em 0;
    font-family: "gustan-light","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.38888889em;
  }

  .banner_promo-link .glyph_right_arrow:before {
    font-size: .44444444em;
    margin-left: 1.25em;
  }

  .banner__inline .banner_title {
    line-height: 30px;
    padding-left: 23px;
  }

  .banner__inline .banner_text p,
  .banner__inline .banner_textcopy {
    line-height: 30px;
    font-size: 1.3em;
  }

  .banner__wide .banner_title {
    position: relative;
  }

  .banner__wide .banner_text {
    padding-left: 30px;
  }

  .banner__wide .banner_dismiss {
    right: 0;
  }

  .banner__wide .banner_icon {
    width: 80px;
    position: absolute;
    left: -72px;
  }

  .banner__wide.open {
    padding-left: 0;
    padding-right: 0;
  }

  .banner__tool .banner_text ul,
  .banner__tool ul.banner_textcopy {
    padding-top: 0;
    margin-top: -4px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .bgpattern:before {
    background-position: -148px 123%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .blocknav .hidden-md {
    display: none;
  }

  .blocknav_item__breaksm {
    clear: none;
  }

  .blocknav_block {
    padding: 100px 0 180px;
  }

  .blocknav_subtitle {
    font-family: "gustan-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.42857143em;
    margin-top: 40px;
  }

  .blocknav_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 4em;
    line-height: 1em;
    margin-bottom: 50px;
  }

  .blocknav_items {
    display: block;
    float: none;
  }

  .blocknav_item {
    box-sizing: border-box;
    margin: 0;
    padding-left: 1px;
    transition-duration: .3s;
    width: 16.66666667%;
  }

  .blocknav_item:first-child {
    padding-left: 0;
  }

  .blocknav_item:last-child {
    padding-right: -1px;
  }

  .blocknav_link {
    height: 100px;
  }

  .blocknav_align {
    display: table;
    height: 100px;
    white-space: normal;
    width: 100%;
  }

  .blocknav_align .blocknav_linktext {
    display: table-cell;
    vertical-align: middle;
  }

  .blocknav_align,
  .blocknav_label {
    padding-left: 13px;
    padding-right: 13px;
  }

  .blocknav_label {
    margin-left: 0;
    width: 16.66666667%;
  }

  .blocknav_searchgroup {
    margin: 0 auto;
    white-space: nowrap;
    display: block;
    margin-bottom: 30px;
  }

  .blocknav_search {
    margin-top: 1px;
    width: 83.33333333%;
  }

  .blocknav_centered-sm {
    display: block;
    max-width: 700px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .btn__blockxs {
    width: auto;
  }

  [data-action-select].margin-onshow:not(.disabled),
  .btn_hidedisabled.margin-onshow:not(.disabled) {
    margin-top: 21px;
    margin-bottom: 21px;
  }

  [data-action-select].margin-onshow-medium,
  .btn_hidedisabled.margin-onshow-medium {
    margin-top: 0;
    margin-bottom: 0;
  }

  [data-action-select].margin-onshow-medium:not(.disabled),
  .btn_hidedisabled.margin-onshow-medium:not(.disabled) {
    margin-top: 21px;
  }

  .btn_popout__contact_panel {
    position: static;
  }

  .btn_popout .btn_popouttriangle__contact_panel {
    bottom: auto;
    margin-top: 3px;
  }

  .btn__auto {
    width: auto;
    min-width: 100px;
    display: inline-block;
  }

  .btn__auto.btn__primary {
    min-width: 140px;
  }

  .btn_back_wrap .btn_back_button {
    padding-top: 0;
    max-width: 150px;
  }

  .btn__withinput {
    border-right: solid 2px transparent;
  }

  .ie9 .btn__withinput {
    border-right-width: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .calculate_subtotal {
    font-size: 12px;
    right: -50px;
    width: 47px;
    padding: 3px 4px;
    height: auto;
    top: 34px;
    margin-top: -14px;
  }

  .calculate_subtotaltext {
    display: inline-block;
  }

  .calculate_subtotal:before {
    left: -5px;
    top: 38%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  section.comparison_wrapper.inactive {
    display: none;
  }

  .comparison {
    height: 1px;
    display: table;
    table-layout: fixed;
    position: static;
    left: auto;
    width: 100%;
    border-collapse: separate;
  }

  .comparison tfoot th,
  .comparison tfoot td {
    background: none;
  }

  .comparison .cell_wrapper {
    position: relative;
    height: 100%;
    display: block;
    background: inherit;
    padding: 20px;
  }

  .comparison tr:nth-child(2n+2) td,
  .comparison tr:nth-child(2n+2) th,
  .comparison tr.even th,
  .comparison tr.even td {
    background-color: #f3f3f3;
  }

  .comparison td,
  .comparison th {
    display: none;
    height: auto;
  }

  .comparison td.active,
  .comparison th.active,
  .comparison td.no-hide,
  .comparison th.no-hide {
    display: table-cell;
  }

  .comparison tfoot th,
  .comparison tbody th {
    display: table-cell;
  }

  .comparison td,
  .comparison th {
    border-left: solid 1px #d6d7db;
    border-right: 0;
  }

  .comparison td:first-child,
  .comparison th:first-child {
    border-left: none;
  }

  .comparison td:first-child,
  .comparison th:first-child,
  .comparison td:last-child,
  .comparison th:last-child,
  .comparison td.last,
  .comparison th.last {
    position: relative;
  }

  .comparison td:first-child:before,
  .comparison th:first-child:before,
  .comparison td:last-child:after,
  .comparison th:last-child:after,
  .comparison td.last:after,
  .comparison th.last:after,
  .comparison td:first-child .cell_wrapper:before,
  .comparison th:first-child .cell_wrapper:before,
  .comparison td:last-child .cell_wrapper:after,
  .comparison th:last-child .cell_wrapper:after,
  .comparison td.last .cell_wrapper:after,
  .comparison th.last .cell_wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100vw;
    display: block;
    background: inherit;
    border: 0;
    padding: inherit;
  }

  .comparison td:first-child:before,
  .comparison th:first-child:before,
  .comparison td:first-child .cell_wrapper:before,
  .comparison th:first-child .cell_wrapper:before {
    right: 100%;
  }

  .comparison td:last-child:after,
  .comparison th:last-child:after,
  .comparison td.last:after,
  .comparison th.last:after,
  .comparison td:last-child .cell_wrapper:after,
  .comparison th:last-child .cell_wrapper:after,
  .comparison td.last .cell_wrapper:after,
  .comparison th.last .cell_wrapper:after {
    left: 100%;
  }

  .comparison thead td,
  .comparison tbody td,
  .comparison tfoot td,
  .comparison thead th,
  .comparison tbody th,
  .comparison tfoot th {
    padding: 0;
    box-sizing: border-box;
  }

  .comparison thead th {
    line-height: 1.25em;
    margin: 0 0 .75em;
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: #0e7ac3;
    border: 0;
    border-left: solid 1px #00b1e3;
    color: #fff;
    font-size: 20px;
  }

  .comparison thead th .cell_wrapper {
    padding: 30px 20px 15px 20px;
  }

  .comparison thead th .subtitle {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    display: block;
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0;
  }

  .comparison tbody {
    border: 0;
  }

  .comparison tbody th {
    font-family: "fira-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 1.38461538em;
    margin: 0 0 1.15384615em;
    color: #323849;
  }

  .comparison_cell {
    vertical-align: top;
    text-align: left;
  }

  .comparison .comparison_cell__centeralign .btn {
    text-align: center;
    padding: 15px 0;
    width: 100%;
    margin: 0;
  }

  .comparison tbody .comparison_row:first-child td,
  .comparison tbody .comparison_row:first-child th:not(.comparison_cell__thead) {
    padding-top: 40px;
  }

  .comparison .comparison_cell__thead {
    padding-bottom: 20px;
  }

  .comparisonaccordion {
    display: none;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .contentblock_centered {
    display: block;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .contentblock_centered.md-contentblock_centered_medium {
    max-width: 800px;
  }

  .contentblock_centered_large {
    max-width: 800px;
  }

  .contentblock_centered_xlarge {
    max-width: 750px;
  }

  .contentblock_centered__medium {
    max-width: 700px;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
  }

  .contentblock_heading {
    margin-top: 33px;
  }

  .contentblock_heading h1 {
    margin-bottom: 32px;
    padding-top: 20px;
  }

  .contentblock_heading .intro {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .content__maxwidth {
    width: auto;
  }

  .content__maxwidth:before,
  .content__maxwidth:after {
    color: transparent;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .content__maxwidth:after {
    clear: both;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .currency_type_static {
    float: none;
  }

  .currencyconverter__right {
    float: none;
    border: 1px solid #eff0f1;
    margin-left: 0;
  }

  .currencyconverter__right .currencyconverter_select .select2-container.select2-container--mini .select2-selection--single {
    padding: 16px 50px 13px 15px;
  }

  .currencyconverter__right .currencyconverter_select .select2-container.select2-container--mini .select2-selection--single .select2-selection__arrow {
    right: 15px;
  }

  .currencyconverter__right .select2-selection__rendered,
  .currencyconverter__right .select2-selection__arrow b:before {
    color: #323849;
  }

  .currency {
    text-align: left;
  }

  .currency_notes__empty {
    margin-top: -20px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dock.closed .dock_dialog {
    right: -85%;
  }

  .dock_dialog {
    width: 80%;
  }

  .dock_body {
    padding: 30px 50px;
    max-height: 100%;
  }

  .dock_tools.tools {
    position: absolute;
    top: 300px;
    left: -40px;
    right: auto;
  }

  .dock_tools.tools .tools_close.btn {
    position: relative;
    left: 0;
    width: 40px;
    height: 40px;
    padding-top: 5px;
  }

  .dock .text-center .intro {
    font-size: 14px;
    line-height: 20px;
  }

  .dock .dock_loading {
    padding-top: 204px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dropdown_label__footer {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    padding-top: 15px;
    float: left;
    position: absolute;
    left: 0;
    padding-left: 5%;
  }

  .dropdown__footer {
    max-width: 100%;
    overflow: hidden;
  }

  .dropdown__footer .left+.select2 .select2-selection__rendered {
    text-indent: 220px;
    text-indent: calc(170px  + 6%);
  }

  .dropdown__footer .right+.select2 .select2-selection__rendered {
    text-indent: 220px;
    text-indent: calc(170px  + 6%);
  }

  .dropdown__footer .select2-selection__rendered {
    text-indent: 170px;
  }

  .dropdown__footer .select2-container .select2-selection--single {
    padding-top: 14px;
    padding-bottom: 40px;
  }

  .dropdown__footer .select2-container .select2-selection--single .select2-selection__rendered {
    font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
  }

  .dropdown__footer .select2-container {
    padding-left: 0;
    padding-top: 0;
  }

  .dropdown__footer .select2-container .select2-selection--multiple input {
    padding-top: 14px;
    padding-bottom: 18px;
  }

  .dropdown__footer .select2-container .select2-search--inline:after {
    top: 26px;
  }

  .dropdown__footer .select2-selection__arrow {
    top: 40%;
  }

  .dropdown__footer .select2-selection__arrow b {
    top: 0;
  }

  .dropdown__footer .select2-search--inline .select2-search__field {
    font-size: 16px;
    max-width: 70%;
  }

  .dropdown_body__footer .select2-results__options {
    font-family: "gustan-light","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.38888889em;
    line-height: 2.7em;
  }

  .dropdown_body__footer.left .select2-results__options .select2-results__option {
    text-indent: 220px;
    text-indent: calc(170px  + 6%);
    padding-left: 5%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .factsheet_title {
    padding-left: 17%;
  }

  .factsheet_intro {
    margin-left: 18%;
  }

  .visa_back {
    position: absolute;
    top: 24px;
    left: -10%;
    display: block;
  }

  .filters .results_summary__fs a {
    display: inline;
    max-width: auto;
    margin: 0;
  }

  .vf_wrap .stickybar.stickybar_policy p {
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 0;
    margin-bottom: 5px;
  }

  .vf_wrap .stickybar.stickybar_policy p.stickybar_byline {
    font-size: 24px;
    padding-left: 45px;
    padding-top: 10px;
  }

  .vf_wrap .stickybar.stickybar_policy p.stickybar_byline:before {
    top: 20px;
    left: 23px;
    font-size: 30px;
  }

  .vf_wrap .stickybar.stickybar_intab.affix {
    position: relative;
  }

  .vf_wrap .stickybar.stickybar_intab.fixed {
    position: fixed;
    top: 0;
    margin-top: 48px;
  }

  .vf_wrap .accordinate__clickable .accordion_trigger.accordinate__cta {
    padding-right: 4%;
  }

  .vf_wrap .fees_step_wrap.visa_steps .steps_title {
    margin: 1.5em 0 0;
    padding-left: .8em;
  }

  .vf_wrap .fees_step_wrap .rcd_wrap .question_evidence {
    display: block !important;
  }

  .vf_wrap .fees_step_wrap .rcd_wrap .pm_row .question_evidence {
    display: none !important;
  }

  .vf_wrap .fees_step_wrap .rcd_wrap .pm_row .question_evidence.active {
    display: block !important;
  }

  .vf_wrap .fees_step_wrap .rcd_wrap p.content_listing_title {
    cursor: text;
  }

  .filter_wrap {
    padding-bottom: 20px;
  }

  .duration .duration_value {
    text-align: left;
  }

  .tools.vf_tools {
    top: 340px;
  }

  .vf_panel.hasSticky {
    margin-top: 50px;
  }

  .tab_overview .vf_panel.hasSticky {
    margin-top: 198px;
  }

  .tab_criteria .vf_panel.hasSticky {
    margin-top: 168px;
  }

  .standalone .results {
    margin-bottom: 70px;
  }

  .standalone .results .rcd_wrap .question_evidence {
    display: block !important;
  }

  .standalone .results .rcd_wrap .pm_row .question_evidence {
    display: none !important;
  }

  .standalone .results .rcd_wrap .pm_row .question_evidence.active {
    display: block !important;
  }

  .standalone .results .rcd_wrap .row.pm_row .question_evidencetrigger {
    text-transform: uppercase;
  }

  .vf_wrap .visa_steps__ufilter .contentblock_centered .steps_title {
    margin-bottom: 0;
  }

  .contentblock_centered.hasSticky {
    margin-top: 165px;
  }

  .rcd_wrap p.content_listing_title {
    cursor: text;
  }

  .process_filter .process_spacer {
    display: block;
  }

  .fees_panels {
    margin-bottom: 50px;
  }

  .fees_panels>.row {
    display: flex;
  }

  .fees_panels .col {
    padding: 0 2px;
    display: flex;
    flex-direction: column;
  }

  .fees_panels .col:first-child {
    padding-left: 0;
  }

  .fees_panels .col:last-child {
    padding-right: 0;
  }

  .fees_panels .col .inner {
    padding: 30px 34px 14px;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .fees_panels .col .inner h3 {
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #323949;
  }

  .fees_panels.eoi_stage2 .col .inner h3 {
    border-color: #464b5c;
  }

  .fees_panels .currencyconverter_select {
    position: relative;
    right: 0;
    top: 0;
  }

  .fees_panels .currencyconverter_select,
  .fees_panels .currency_type_static {
    margin-top: 19px;
  }

  .fees_panels .has_currency .currencyconverter_select {
    margin-top: 14px;
  }

  .fees_panels .currencyconverter_value {
    margin-top: 0;
  }

  .fees_panels .currencyconverter_value.smaller,
  .fees_panels .currency_value_static.smaller {
    margin-top: 10px;
  }

  .btn_adjust.btn {
    margin: 25px 0 0;
  }

  .fees_body {
    flex: 1 1 auto;
    width: 100%;
  }

  .fees_body h4 {
    margin-top: 8px;
  }

  .fees_body .fees_row:first-child:not(.has_currency) {
    padding-top: 39px;
  }

  .fees_body .fees_row:nth-child(2) {
    padding-top: 8px;
  }

  .fees_body .fees_row:nth-child(2) .fee_amount {
    margin-top: 6px;
  }

  .fees_body .fees_row:nth-of-type(2) {
    margin-top: 20px;
  }

  .fees_body .fees_row.has_currency h4 {
    margin-top: 48px;
  }

  .fees_body .fee_amount,
  .fees_body .processing_time {
    margin: 5px 0 0;
  }

  .fees_footer {
    min-height: 66px;
    overflow: hidden;
    width: 100%;
  }

  .fees_footer .fees_row .accordinate__clickable .accordion_trigger.accordinate__cta {
    top: 0;
  }

  .fees_footer .fees_row .accordinate__clickable .accordion_trigger.accordinate__cta:after {
    right: 2px;
  }

  .fees_footer .fees_row.expanded .accordinate__clickable .accordion_trigger.accordinate__cta:after {
    top: 9px;
  }

  .fees_footer .fees_row .payment_method_row {
    font-family: gustan-light;
    font-size: 14px;
  }

  .fees_footer.no_content {
    display: block;
  }

  .fees_step_wrap .rcd_wrap p.content_listing_title {
    padding-bottom: 0;
    text-transform: uppercase;
  }

  .fees_step_wrap .row {
    border-top-width: 1px;
  }

  .fees_step_wrap .row .row {
    border-top: 1px solid #d5d6d9;
  }

  .fees_step_wrap .row .row.pm_row .question_evidencetrigger {
    top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .fees_step_wrap .row .row.pm_row .question_evidencetrigger:after {
    right: 87%;
  }

  .fees_step_wrap .row .row.pm_row .question_evidence {
    padding-top: .8em;
  }

  .fees_step_wrap .row .row.pm_row .payment_method {
    padding-left: 20px;
  }

  .fees_step_wrap .row.actions {
    margin-top: 40px;
  }

  .fees_step_wrap .process__vertical {
    padding-bottom: 50px;
  }

  .fees_step_wrap .process__vertical .process_downloads {
    font-size: 1.6em;
  }

  .fees_step_wrap .process__vertical .process_heading .process_number {
    font-size: 3.5em;
    line-height: 35px;
  }

  .points_wrap .question_evidencetrigger,
  .points_wrap .question_container.has_left_nav .question_evidencetrigger {
    padding: 0 1.5em 0 0;
    right: 0;
    top: 21px;
  }

  .points_wrap .question_evidencetrigger span.trigger_show,
  .points_wrap .question_container.has_left_nav .question_evidencetrigger span.trigger_show {
    display: block;
  }

  .points_wrap .question_evidencetrigger.active span.trigger_show,
  .points_wrap .question_container.has_left_nav .question_evidencetrigger.active span.trigger_show {
    display: none;
  }

  .points_wrap .question_evidencetrigger.active span.trigger_hide,
  .points_wrap .question_container.has_left_nav .question_evidencetrigger.active span.trigger_hide {
    display: block;
  }

  .points_wrap .calculate_subtotal {
    top: 34px;
    right: -50px;
  }

  .af_wrap {
    text-align: left;
  }

  .mobile-only {
    display: none !important;
  }

  .mobile_accord {
    display: block !important;
    height: auto !important;
  }

  .mobile_accordion .mobile-line {
    display: none;
  }

  .process__vertical .mobile_accordion .process_title {
    padding: 24px 0 0;
    max-width: 100%;
  }

  .process__vertical .mobile_accordion .process_heading {
    padding: 18px 0 0;
  }

  .mobile_accordion .question_evidencetrigger {
    top: 0;
  }

  .mobile_accordion .question_evidence {
    display: block !important;
    height: auto !important;
  }

  .mobile_accordion .question_evidence .question_evidence {
    display: none !important;
  }

  .mobile_accordion .question_evidence .question_evidence.active,
  .mobile_accordion .question_evidence .question_evidence.rcd_content {
    display: block !important;
  }

  .mobile_accordion .mobile_accord .question_evidencetrigger {
    top: 0;
  }

  .mobile_accordion .mobile_accord .question_evidencetrigger:after {
    color: #0e7ac3;
  }

  .mobile_accordion .mobile_accord .question_evidencetrigger.active:after,
  .mobile_accordion .mobile_accord .question_evidencetrigger:hover:after {
    color: #00b1e3;
  }

  .fees_step_wrap .mobile_accordion .mobile_accord .question_evidence {
    margin-top: 0;
  }

  .mobile_accordion .mobile_accord .question_evidence .link__download:first-of-type {
    margin-top: 10px;
  }

  .mobile_accordion .mobile_accord .rcd_wrap .row {
    padding: 15px 0 12px;
  }

  .mobile_accordion .mobile_accord .rcd_wrap .row .row.pm_row {
    padding-top: 15px;
  }

  .mobile_accordion .mobile_accord .rcd_wrap .row .row.pm_row:first-child {
    padding-top: 0;
  }

  .mobile_accordion .mobile_accord .rcd_wrap .row .row.pm_row:last-child {
    padding-bottom: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .field__error {
    padding: 13px 3% 13px 15%;
    line-height: 13px;
  }

  .field__error:before {
    left: 4%;
    font-size: 15.4px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .footer .footer_tertiary_list {
    padding-top: 0;
  }

  .footer .footer_tertiary_list li {
    float: left;
    padding: 0 .57692308em;
  }

  .footer .footer_tertiary_list li:first-child {
    margin: 0 0 0 -0.57692308em;
  }

  .footer .f_tertiary_text a {
    padding: 1.15384615em 0;
  }

  .footer .f_tertiary_text__c {
    text-align: right;
  }

  .footer .footer_list a {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.28571429em;
    padding: .21428571em 0 .35714286em;
  }

  .footer .footer_list__header {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.42857143em;
    text-transform: uppercase;
  }

  .footer .footer_list__header a {
    padding-bottom: 1.07142857em;
    padding-top: 1.07142857em;
  }

  .footer .mbie_logo,
  .footer .nzgovt_logo {
    margin: 3em 0 2em;
  }

  .footer .mbie_logo {
    background-size: auto;
    height: 5em;
    background-position: left center;
    float: left;
  }

  .footer .nzgovt_logo {
    background-size: auto;
    background-position: right center;
    height: 5em;
    float: right;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .form_field.form_field__action {
    margin-top: 21px;
  }

  .form_field.form_field__tool_action input {
    width: auto;
  }

  .form_fieldset {
    padding: 3em;
  }

  .form_fieldset>.field_legend:first-child,
  .form_fieldset>.form_field:first-child>label {
    margin-top: -6px;
  }

  .panel_contact .form_fieldset {
    padding: 0;
  }

  .form__inpage {
    margin-bottom: 2.5em;
  }

  .form__inpage+.banner,
  .form__inpage+.form__flush {
    margin-top: -2.5em;
    margin-bottom: 2.5em;
  }

  .form__inpage__nopad {
    margin-bottom: 0;
  }

  .form__tool {
    margin: 30px 0 40px;
  }

  .form__tool .field_legend {
    text-align: left;
  }

  .form__tool .form_fieldset {
    padding: 25px;
  }

  .form__tool__policecerts {
    margin-bottom: 100px;
  }

  .form__tool__standalone {
    margin-bottom: 60px;
  }

  .form__tool+[data-tool-error] {
    margin: -40px 0 0;
  }

  .form__tool+[data-tool-error] .banner {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .form__inpage_search .form_fieldset {
    padding: 4.5em 4.8em 4em 4.8em;
  }

  .form_checklist {
    margin-bottom: 2.5em;
  }

  .form_checklist .input__radio,
  .form_checklist .input__check {
    width: 50%;
    float: left;
  }

  .form_checklist .input__radio:nth-child(odd),
  .form_checklist .input__check:nth-child(odd) {
    clear: both;
  }

  .form_checklist.form_checklist__1col .input__radio,
  .form_checklist.form_checklist__1col .input__check {
    width: 100%;
    float: none;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .glossary_index__top {
    display: block;
  }

  .glossary_index_outer {
    min-height: 59px;
  }

  .glossary_index__side {
    display: none;
  }

  .glossary_item {
    padding: 3em 0;
  }

  .glossary_alpha {
    font-size: 5em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .header .header_logo_link.header_logo_link_small {
    height: 4.2em;
    margin-top: 3.2em;
    margin-bottom: 3.6em;
    width: 13.8em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .container__restrictcontent .header .header_login_holder {
    position: absolute;
  }

  .header .header_login_title,
  .header .header_login_text {
    padding-top: 10px;
  }

  .header .header_login_content {
    padding-top: 15px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .header .header_search__mobile {
    padding-top: 2.7em;
    padding-bottom: 2.8em;
    max-height: 11em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .input__check label {
    font-size: 14px;
  }

  .input__radio label {
    font-size: 14px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .leftnav {
    display: block;
    margin-top: 34px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .linkgrid .linkgrid_item .linkgrid_toggle {
    display: none;
  }

  .linkgrid .linkgrid_abstract {
    line-height: 1.6em;
  }

  .linkgrid_row {
    position: relative;
    margin: 0 auto;
    width: 603px;
    max-width: none;
  }

  .linkgrid_item {
    padding: 0;
    transition-duration: 0s;
    box-sizing: border-box;
    float: left;
    margin-left: 1px;
    width: 33%;
    position: relative;
    overflow: hidden;
    display: block;
  }

  .linkgrid_item:before {
    content: '  ';
    display: block;
    padding-top: 100%;
  }

  .linkgrid_item .linkgrid_wrap {
    margin: 10%;
    box-sizing: border-box;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 77%;
  }

  .linkgrid_item .linkgrid_text {
    position: relative;
    display: block;
    transition-duration: 0s;
    overflow: hidden;
    height: 97%;
    max-width: 100%;
  }

  .linkgrid_item .linkgrid_title {
    transition-duration: 0s;
    margin-top: 0;
    position: relative;
    max-height: 200px;
    overflow: hidden;
  }

  .no-touch .linkgrid_item:hover .linkgrid_abstract,
  .no-touch .linkgrid_item:focus .linkgrid_abstract {
    height: 100%;
  }

  .no-touch .linkgrid_item:hover .linkgrid_title,
  .no-touch .linkgrid_item:focus .linkgrid_title {
    display: none !important;
  }

  .linkgrid_item:after {
    display: none;
  }

  .linkgrid_item__promo .linkgrid_wrap__promo {
    bottom: 0;
    height: 50%;
    padding-right: 2em;
    margin-top: 50%;
    padding: 1em 3em 1em 1em;
    position: absolute;
    width: 100%;
  }

  .linkgrid_item__promo .linkgrid_wrap__promo:after {
    top: auto;
    bottom: 5px;
    right: 9px;
  }

  .linkgrid_item__promo .linkgrid_title__promo {
    font-size: 1.5em;
    line-height: 1.2em;
  }

  .linkgrid_item__offsite .linkgrid_title:after {
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f11c";
  }

  .linkgrid_title {
    font-family: "gustan-thin","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    line-height: 1em;
    margin-bottom: .75em;
  }

  .linkgrid_title:after {
    display: inline-block;
  }

  .linkgrid_abstract {
    display: block;
    color: #323849;
    font-size: 1.2em;
    margin-bottom: 1.25em;
  }

  .linkgrid__dark .linkgrid_item {
    border-color: transparent;
  }

  .no-touch .linkgrid__dark .linkgrid_item:hover,
  .no-touch .linkgrid__dark .linkgrid_item:focus {
    background-color: none;
  }

  .linkgrid__dark .linkgrid_item .linkgrid_title,
  .linkgrid__dark .linkgrid_item .linkgrid_abstract {
    color: #fff;
  }

  .no-touch .linkgrid__dark .linkgrid_col:hover:before,
  .no-touch .linkgrid__dark .linkgrid_col:hover:first-child:before {
    border-color: #00b1e3;
    box-shadow: 0 0 0 1px #00b1e3;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .listing img {
    display: block;
  }

  .listing .listing-item:not(.listing_item__headeronly) .listing_link:after {
    font-size: 1.5em;
    margin-top: .75em;
  }

  .listing .listing_link:after {
    right: 15px;
  }

  .listing .listing_link~div:last-child {
    padding-right: 80px;
  }

  .listing .listing_flagged {
    padding-left: 0;
  }

  .listing .listing_flagged:after {
    left: -25px;
  }

  .listing_item.has_link {
    transition-duration: .1s;
  }

  .no-touch .listing_item.has_link:hover,
  .no-touch .listing_item.has_link:focus {
    transition-duration: .1s;
    background-color: #282d3a;
  }

  .no-touch .listing_item.has_link:hover *,
  .no-touch .listing_item.has_link:focus * {
    color: #fff;
  }

  .no-touch .listing_item.has_link:hover .listing-link,
  .no-touch .listing_item.has_link:focus .listing-link {
    text-decoration: none;
    color: #fff;
  }

  .listing_item__headeronly .metadata,
  .listing_item__headeronly h3,
  .listing_item__headeronly h4 {
    margin-bottom: 0;
  }

  .listing__explore_visas .listing_item {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .listing__contact {
    padding: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .loader:before,
  .loader:after,
  .loader {
    width: 2.5em;
    height: 2.5em;
  }

  .loader {
    margin: 80px auto;
  }

  .loader:before {
    left: -3.5em;
  }

  .loader:after {
    left: 3.5em;
  }

  .loader_text {
    font-size: 14px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .map_outer {
    width: auto;
    margin-left: 0;
    height: 500px;
  }

  .map_detail_item_outer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    width: 0;
  }

  .map_item_detail {
    width: 300px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    padding: 40px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .mediafooter {
    padding-top: 2em;
  }

  .mediafooter_title,
  .mediafooter_article,
  .mediafooter_contact {
    padding-left: 0;
    padding-right: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .motivegrid {
    margin-bottom: 3em;
    width: 100%;
    padding-top: 100%;
    max-width: none;
    position: relative;
  }

  .motivegrid [data-posh="1"] {
    left: 0;
  }

  .motivegrid [data-posv="1"] {
    top: 0;
  }

  .motivegrid [data-posh="2"] {
    left: 33.33%;
  }

  .motivegrid [data-posv="2"] {
    top: 33.33%;
  }

  .motivegrid [data-posh="3"] {
    left: 66.66%;
  }

  .motivegrid [data-posv="3"] {
    top: 66.66%;
  }

  .motivegrid_itemblurb {
    line-height: 1.45454545em;
  }

  .motivegrid_item {
    box-sizing: border-box;
    height: 33.33%;
    border-right: 1px solid white;
    padding: 0;
    position: absolute;
    width: 33.33%;
  }

  .motivegrid_itemtitle {
    background-color: inherit;
    margin: 0;
    margin-bottom: .41666667em;
    font-size: 1.5em;
    line-height: 1.2em;
    padding: 1.42857143em;
    float: none;
    width: auto;
  }

  .motivegrid_itemnumber {
    font-size: 3.06666667em;
    line-height: .76086957em;
    margin-right: .22222222em;
  }

  .motivegrid_itemimage {
    width: 100%;
    max-width: none;
    height: auto;
    max-height: none;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  .motivegrid_item__event {
    display: block;
    padding: 2em 0;
  }

  .motivegrid_item__event .motivegrid_itemtitle {
    padding: 0 1.42857143em;
  }

  .motivegrid_item__event.motivegrid_itemtitle__top .motivegrid_itembg:after {
    background-image: linear-gradient(rgba(0,0,0,0.5), transparent 40%);
  }

  .motivegrid_item__event.motivegrid_itemtitle__top .motivegrid_itemtitle {
    vertical-align: top;
  }

  .motivegrid_item__event.motivegrid_itemtitle__mid .motivegrid_itembg:after {
    background-image: linear-gradient(transparent 20%, rgba(0,0,0,0.5), transparent 80%);
  }

  .motivegrid_item__event.motivegrid_itemtitle__mid .motivegrid_itemtitle {
    vertical-align: middle;
  }

  .motivegrid_item__event.motivegrid_itemtitle__bot .motivegrid_itembg:after {
    background-image: linear-gradient(transparent 60%, rgba(0,0,0,0.5));
  }

  .motivegrid_item__event.motivegrid_itemtitle__bot .motivegrid_itemtitle {
    vertical-align: bottom;
  }

  .motivegrid_item__news {
    text-align: center;
  }

  .motivegrid_item__news:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 1px;
  }

  .motivegrid_item__news .motivegrid_article {
    display: inline-block;
    height: auto;
    text-align: left;
    vertical-align: middle;
    width: 75%;
  }

  .motivegrid_item__news .motivegrid_itemtitle {
    display: block;
  }

  .motivegrid_item__news .motivegrid_itemblurb {
    display: block;
  }

  .motivegrid_item__news .motivegrid_itemdate:before {
    display: none;
  }

  .motivegrid_promotext {
    bottom: 0;
    padding: 2em 4em 2em 3em;
    position: absolute;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .natlang .query {
    padding-right: 26px;
    font-size: 20px;
    line-height: 30px;
  }

  .natlang .query:after {
    margin-right: -30px;
  }

  .natlang .textmeasure,
  .natlang .field.text input {
    font-size: 20px;
    height: 30px;
    line-height: 30px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .nav_mobile {
    display: block;
    position: fixed;
    right: 0;
    left: 100%;
    background: #000;
    bottom: 0;
    top: 11em;
    overflow: visible;
    max-height: none;
    -webkit-transition: left .2s ease-out;
    -moz-transition: left .2s ease-out;
    transition: left .2s ease-out;
    z-index: 4;
  }

  .nav_mobile.open {
    max-height: none;
    left: 20%;
  }

  .container__restrictcontent .nav_mobile {
    position: fixed;
  }

  .nav_mobile .nav_mobile_list.nav_mobile_list__l1:after {
    background: red;
    top: 0;
    position: absolute;
    bottom: 0;
    content: ' ';
  }

  .nav_mobile .nav_mobile_list .nav_mobile_list__slide:after {
    content: ' ';
    position: absolute;
    background: #000;
    top: 100%;
    bottom: -999px;
    left: 0;
    right: 0;
  }

  .nav_mobile .nav_mobile_link {
    font-family: "gustan-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.25em;
    padding-top: 1.09375em;
    padding-bottom: 1.09375em;
  }

  .nav_mobile .nav_mobile_list__l2 .nav_mobile_link {
    font-family: "gustan-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.38888889em;
    padding-top: .97222222em;
    padding-bottom: .97222222em;
  }

  .nav_mobile .nav_mobile_list__l2 .nav_mobile_link span {
    padding-left: 1.11111111em;
    padding-right: 2.77777778em;
  }

  .nav_mobile .nav_mobile_list__l2 .nav_mobile_link__back {
    padding-top: .97222222em;
    padding-bottom: .97222222em;
  }

  .nav_mobile .nav_mobile_list__l2 .nav_mobile_link__back:before {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f108";
    bottom: 0;
    font-size: 10px;
    line-height: 58px;
    left: 5%;
    position: absolute;
    top: 0;
    width: 10px;
    text-indent: 0;
  }

  .nav_mobile_triggers {
    padding-top: 2.7em;
    padding-bottom: 2.8em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .form__news {
    margin-left: 0;
    margin-right: 0;
  }

  .news_tabs_outer {
    display: block;
    position: relative;
    margin-bottom: 5em;
  }

  .news_tabs_outer:before,
  .news_tabs_outer:after {
    content: '';
    background: #323849;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .news_tabs_outer:before {
    left: -100%;
  }

  .news_tabs_outer:after {
    left: auto;
    right: -100%;
  }

  .accordion_group__news+.accordion_group__news {
    display: none;
  }

  .results__news_date {
    display: block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .officefees_stage {
    padding: 60px 0 0;
  }

  .officefees_stage:first-child {
    padding-top: 20px;
  }

  .officefees_stageheader {
    padding: 0 0 15px;
    text-align: left;
  }

  .officefees_stageheader span {
    font-size: 18px;
  }

  .officefees_result {
    padding-left: 0;
    padding-right: 0;
  }

  .officefees_result h3 {
    width: 100%;
    float: none;
    margin-bottom: 0;
    padding-right: 25px;
  }

  .officefees_result h4 {
    font-family: "fira-regular","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .officefees_processing_time {
    text-align: left;
  }

  .content .content_details__officefees .content_listing_title {
    font-family: "fira-regular","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .content_details__officefees .content_listing_wrap:first-child {
    border-top: none;
    padding-top: 3px;
  }

  .content_details__officefees .listing_item_inner {
    padding-left: 0;
  }

  .officefees_details_payment {
    padding-right: 23px;
  }

  .officefees_details_payment:first-child {
    padding-top: 0;
    border-top: none;
  }

  .officefees_details_payment .question_evidence {
    margin-left: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .panel_contact {
    padding: 5em 4.8em 4.8em 5em;
    margin: 0;
  }

  .panel__default {
    padding: 3rem 3rem .4rem;
  }

  .panel__split {
    padding: 0 3rem 0;
  }

  .pswp__caption__center {
    max-width: 650px;
  }

  .pswp__ui .pswp__caption {
    font-size: 10px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .policecert_actions {
    display: inline-block;
  }

  .policecert_subtitle+.policecert_actions {
    margin-left: 10px;
  }

  .policecert_main_heading {
    font-size: 26px;
  }

  .policecert_result .content_list_item .content_list_trigger {
    right: 0;
    top: 6px;
  }

  .policecert_result .content_list_item .content_list_headwrap {
    padding: 14px 0;
  }

  .policecert_result .content_list__content .content_listing_wrap {
    padding: 20px 0;
  }

  .policecert_result .policecert_resultheader {
    padding: 0 0 30px;
  }

  .policecert_result .content_list_heading {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 0;
  }

  .policecert_result .policecert_subheading {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }

  .policecert_result .listing_item_inner {
    padding-top: 10px;
  }

  .policecert_result .listing_item_inner p strong {
    font-size: 14px;
  }

  .policecert_resultform {
    margin: 0 0 0;
  }

  .policecert_resultform_inner {
    padding-top: 20px;
  }

  .policecert_resultform form:before {
    content: none;
    display: none;
  }

  .policecert_resultform form .form_fieldset {
    padding: 18px 20px;
  }

  .policecert_resultform_actions {
    margin-top: 2em;
  }

  .policecert_resultform_actions__mobile {
    display: none;
  }

  .policecert_resultform_actions__desktop {
    display: block;
  }

  .popout__login_content {
    float: left;
  }

  .popout .btn__login {
    width: 82%;
  }

  .popout_set {
    padding-left: 8.33333333%;
    padding-right: 8.33333333%;
  }

  .popout_contact .popout_set {
    padding-left: 8.33333333%;
    padding-right: 8.33333333%;
  }

  .popout_contact h2 {
    margin-top: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .process__vertical .btn__primary {
    width: auto;
    margin-right: 0;
  }

  .process__vertical .cta_box.popout_triggers {
    float: right;
  }

  .process_stage_title {
    padding: 30px 0;
    font-size: 20px;
    line-height: 25px;
  }

  .steps_title {
    font-size: 25px;
    line-height: 30px;
  }

  .process_section_heading {
    font-size: 45px;
    padding-top: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .processnav {
    margin: 0 auto 20px;
    padding-top: 40px;
    width: 100%;
  }

  .processnav_bar {
    padding-bottom: 1px;
    background-image: none;
  }

  .processnav_direction-nav {
    display: block;
  }

  .processnav_items {
    text-align: center;
    position: relative;
  }

  .processnav_item {
    display: block;
    float: left;
    vertical-align: bottom;
    text-align: left;
    width: 200px !important;
    margin-right: 1px;
    margin-top: -135px;
    margin-bottom: -15px;
    text-indent: 0;
    float: none;
    position: relative;
    overflow: visible;
  }

  .processnav_item>a {
    display: block;
    height: 100%;
  }

  .processnav_item .processnav_itemtext {
    display: inline;
  }

  .processnav_item .processnav_itemnumber {
    left: 10px;
    right: auto;
  }

  .processnav_item.processnav_active-slide {
    width: 200px !important;
  }

  .processnav_item.active {
    left: auto;
    right: auto;
    width: 200px !important;
  }

  .processnav_item.active:before,
  .processnav_item.active:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 1000%;
    top: 108%;
    border-top: solid 23px #282d3a;
  }

  .processnav_item.active:before {
    right: 50%;
    margin-right: -11px;
    border-right: solid 23px transparent;
  }

  .processnav_item.active:after {
    left: 50%;
    margin-left: -11px;
    border-left: solid 23px transparent;
  }

  .processnav_item.active .processnav_itemtitle:before,
  .processnav_item.active .processnav_itemtitle:after {
    border-top: solid 12px #0e7ac3;
  }

  .processnav_itemimage {
    box-sizing: border-box;
    display: block;
    background-size: cover;
    background-position: center center;
    background-color: #323849;
    font-family: "gustan-thin","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    height: 135px;
    width: 200px;
    padding: 20px 40px;
    color: white;
  }

  .processnav_itemtitle {
    margin: 0;
    text-indent: 0;
    white-space: normal;
    font-size: 12px;
    line-height: 15px;
    width: 200px;
    height: 53px;
    padding: 18px 15px 0 45px;
    margin-bottom: 12px;
  }

  .processnav_itemtitle:before,
  .processnav_itemtitle:after {
    content: "";
    position: absolute;
    width: 50%;
    top: 100%;
    border-top: solid 12px #0c65a2;
    border-right: solid 0 transparent;
    border-left: solid 0 transparent;
    transition-duration: .3s;
  }

  .processnav_itemtitle:before {
    right: 50%;
  }

  .processnav_itemtitle:after {
    left: 50%;
  }

  .processnav_itemtitle__dark:before,
  .processnav_itemtitle__dark:after {
    border-top-color: #323849;
  }

  .processnav_bar.affix:not(.no-affix),
  .processnav_bar.false-affix {
    background: none;
    border-top: 0;
  }

  .processnav_bar.affix:not(.no-affix) .processnav_viewport:before,
  .processnav_bar.false-affix .processnav_viewport:before,
  .processnav_bar.affix:not(.no-affix) .processnav_viewport:after,
  .processnav_bar.false-affix .processnav_viewport:after {
    content: "";
    position: absolute;
    width: 202%;
    top: 0;
    bottom: 0;
    height: 100%;
    display: block;
    background: #282d3a;
  }

  .processnav_bar.affix:not(.no-affix) .processnav_viewport:before,
  .processnav_bar.false-affix .processnav_viewport:before {
    left: -200%;
  }

  .processnav_bar.affix:not(.no-affix) .processnav_viewport:after,
  .processnav_bar.false-affix .processnav_viewport:after {
    left: 1278px;
  }

  .processnav_bar.affix:not(.no-affix) .processnav,
  .processnav_bar.false-affix .processnav {
    margin: 0;
    padding-top: 0;
    background: transparent;
  }

  .processnav_bar.affix:not(.no-affix) .processnav_itemimage,
  .processnav_bar.false-affix .processnav_itemimage {
    -webkit-transition: height .2s ease-out;
    -moz-transition: height .2s ease-out;
    transition: height .2s ease-out;
    box-sizing: border-box;
    height: 0;
    overflow: hidden;
    padding: 0;
  }

  .processnav_bar.affix:not(.no-affix) .active,
  .processnav_bar.false-affix .active {
    background: transparent;
  }

  .processnav_bar.affix:not(.no-affix) .active:before,
  .processnav_bar.false-affix .active:before,
  .processnav_bar.affix:not(.no-affix) .active:after,
  .processnav_bar.false-affix .active:after {
    margin-top: -4em;
    border: 0;
  }

  .processnav_bar.affix:not(.no-affix) .active .processnav_itemtitle:before,
  .processnav_bar.false-affix .active .processnav_itemtitle:before {
    border-right-width: 12px;
  }

  .processnav_bar.affix:not(.no-affix) .active .processnav_itemtitle:after,
  .processnav_bar.false-affix .active .processnav_itemtitle:after {
    border-left-width: 12px;
  }

  .processnav_wrapper.processnav_wrapper,
  .processnav_wrapper.processnav_wrapper.processnav_wrapper_3 {
    max-width: 100%;
    width: 800px;
  }

  .processnav_wrapper.processnav_wrapper .processnav_viewport:after,
  .processnav_wrapper.processnav_wrapper.processnav_wrapper_3 .processnav_viewport:after {
    left: 800px;
  }

  .processnav_wrapper.processnav_wrapper.processnav_wrapper_4 {
    width: 1000px;
  }

  .processnav_wrapper.processnav_wrapper.processnav_wrapper_4 .processnav_viewport:after {
    left: 1000px;
  }

  .processnav_wrapper.processnav_wrapper.processnav_wrapper_5 {
    width: 1200px;
  }

  .processnav_wrapper.processnav_wrapper.processnav_wrapper_5 .processnav_viewport:after {
    left: 1200px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .question_container {
    max-width: 100em;
    padding: 30px 7%;
  }

  .question_actions {
    padding: 0;
  }

  .question_input .checkbox_label {
    padding-right: 125px;
    display: block;
  }

  .question_input .checkbox_label:before {
    width: 30px;
    height: 30px;
    font-size: 12px;
    top: 20.5px;
  }

  .question_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    line-height: 1.25em;
    width: auto;
    float: none;
    max-width: 150px;
    float: left;
    padding-left: 0;
    border-bottom: none;
    margin-bottom: 0;
    z-index: 0;
  }

  .question_option {
    padding: 0 0 0 170px;
    clear: none;
  }

  .question_evidence {
    margin-left: 61px;
  }

  .question_selectwrapper {
    max-width: 300px;
    padding: 0;
  }

  .question_selectwrapper .question_select+.select2 {
    margin-left: 61px;
  }

  .question_selectwrapper .showactive.active+.select2 {
    margin: 1.25em 0;
  }

  .question_selectwrapper .select2-container .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
    font-size: 14px;
  }

  .question_input .checkbox_label {
    font-size: 1.6em;
    padding-left: 60px;
  }

  .question_select .select2-results__options .select2-results__option {
    font-size: 14px;
  }

  .question_evidenceinner {
    padding: 20px 0;
  }

  .question_evidenceinner:before,
  .question_evidenceinner:after {
    color: transparent;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .question_evidenceinner:after {
    clear: both;
  }

  .question_evidenceinner p {
    font-size: 14px;
  }

  .question_evidenceinner p.question_flagged:last-child {
    margin-bottom: 0;
  }

  .question_evidenceinner ul li {
    font-size: 14px;
  }

  .question_evidenceinner .question_flagged p {
    font-size: 1.4em;
  }

  .question_evidencetrigger {
    min-width: 100px;
    margin-right: 12px;
    text-align: right;
    top: 0;
  }

  .question_evidencetrigger:after {
    right: 0;
  }

  .question_evidencetrigger .trigger_show {
    display: block;
  }

  .question_evidencetrigger.active .trigger_hide {
    display: inline-block;
  }

  .question_evidencetrigger__dark {
    right: auto;
    left: 0;
    text-align: left;
  }

  .question_evidencetrigger__dark .trigger_hide {
    display: none !important;
  }

  .question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all {
    padding-right: 30px;
    margin-top: 80px;
  }

  .has_left_nav .question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all {
    margin-top: 30px;
  }

  .question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all:after {
    right: 12px;
  }

  .question .question_evidenceinner .question_flagged p {
    font-size: 1.4em;
  }

  .question .calculate_subtotal {
    top: 34px;
  }

  .question_heading {
    padding: 0;
  }

  .question_heading p {
    font-size: 1.6em;
  }

  .question_heading h2 {
    font-size: 2.5em;
  }

  .question_container.has_left_nav {
    padding: 0 7%;
  }

  .question_container.has_left_nav .accordion_trigger.accordinate__cta.accordinate__trigger_all {
    padding-right: 46px;
  }

  .question_container.has_left_nav .accordion_trigger.accordinate__cta.accordinate__trigger_all:after {
    right: 7%;
  }

  .question_container.has_left_nav .question_option+.question_option .question_input {
    border-top-color: #d5d7db;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .results__search,
  .results__resources {
    padding: 0;
  }

  .results__tool {
    margin-left: 0;
    margin-right: 0;
  }

  .results__tool .fees_step_wrap {
    margin-bottom: -50px;
  }

  .results_loadbtn {
    font-size: 16px;
  }

  .results_summary {
    padding-left: .7em;
    padding-right: 1.5em;
  }

  .results_summary__flush {
    padding-left: 0;
    padding-right: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .select2-selection__arrow {
    right: 13px;
  }

  .select2-results__options {
    max-height: 300px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .select2-container .select2-selection--single.select2--large {
    padding: 11px 18px;
    font-size: 18px;
  }

  .select2-container .select2-selection--single.select2--large .select2-selection__placeholder {
    font-size: 18px;
  }

  .select2-container .select2-selection--single.select2--large .select2-selection__rendered {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
  }

  .select2-container .select2-selection--single.select2--large .select2-selection__arrow b:before {
    font-size: 12px;
  }

  .select2-container .select-large .select2-results__options .select2-results__option {
    padding: 5px 18px;
    font-size: 18px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .select2-container--natlang .select2-dropdown {
    min-width: 250px !important;
  }

  .select2-container--natlang .select2-selection .select2-selection__rendered {
    font-size: 20px;
    line-height: 30px;
  }

  .select2-container--natlang .select2-selection .select2-selection__placeholder {
    font-size: 20px;
    line-height: 30px;
  }

  .select2-container--natlang .select2-search--inline.select2-search .select2-search__field {
    font-size: 20px;
    line-height: 30px;
  }

  .select2-container--natlang .select2-search--inline.select2-search:after {
    right: 0;
    top: 11px;
    font-size: 16px;
  }

  .select2-container--natlang .select2-results__options li.select2-results__option {
    font-size: 20px;
    padding: 8px 5% 8px 5%;
  }

  .select2_filters~.field__error {
    min-height: auto;
  }

  .select2_filters~.field__error:before {
    margin-top: -8px;
  }

  .select2_filters+.select2-container {
    margin-right: 2px;
  }

  .form_checklist {
    margin-bottom: 2.5em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .selectgrid {
    display: block;
    left: auto;
    position: static;
  }

  .selectgrid.inactive {
    display: none;
  }

  .selectgrid_wrapper.selectgrid_wrapper {
    max-width: 600px;
  }

  .selectgrid_swap {
    font-size: 20px;
    line-height: 1em;
    padding: 24.5px 0;
  }

  .selectgrid_swap__mobile.selectgrid_swap__visible {
    display: none;
  }

  .selectgrid_swap.selectgrid_swap__visible:not(.selectgrid_swap__mobile) {
    display: inline-block;
  }

  .selectgrid_swap:after {
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f104";
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    transition: all .2s ease-out;
    font-size: 12px;
    padding-left: 1em;
  }

  .selectgrid_swap:focus {
    outline: 1px dotted #aaa;
  }

  .selectgrid_swap:focus:active {
    outline: none;
  }

  .selectgrid_content {
    padding: 30px 0 10px;
  }

  .selectgrid_action {
    clear: both;
    padding-top: 30px;
    text-align: center;
  }

  .selectgrid_singleactionlabel {
    display: none;
  }

  .selectgrid_cell {
    float: left;
    height: 200px;
    margin: -1px 0 0 -1px;
    padding: 65px 20px 20px;
    position: relative;
    width: 200px;
  }

  .selectgrid_cell input+.select_wrapper label {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
    border: solid 1px #5b606e;
    color: #fff;
    display: block;
    font-size: 12px;
    height: 100%;
    left: 0;
    padding: 20px 20px 20px 10px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    vertical-align: middle;
    width: 100%;
  }

  .selectgrid_cell input+.select_wrapper label>span {
    padding-left: 40px;
  }

  .selectgrid_cell input+.select_wrapper label>span:before {
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: " ";
    position: absolute;
    height: 22.5px;
    width: 22.5px;
    left: 20px;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    transition: all .2s ease-out;
    border: solid 1px #5b606e;
    text-align: center;
    text-indent: -0.3em;
    color: #323849;
  }

  .selectgrid_cell input:not(:disabled)+.select_wrapper:hover label,
  .selectgrid_cell input.hasfocus+.select_wrapper label {
    z-index: 2;
    background-color: #0e7ac3;
    border-color: #0e7ac3;
    color: #fff;
  }

  .selectgrid_cell input:not(:disabled)+.select_wrapper:hover label span,
  .selectgrid_cell input.hasfocus+.select_wrapper label span {
    color: #fff;
  }

  .selectgrid_cell input:not(:disabled)+.select_wrapper:hover label span:before,
  .selectgrid_cell input.hasfocus+.select_wrapper label span:before {
    border-color: white;
    color: #0e7ac3;
  }

  .selectgrid_cell input:not(:disabled)+.select_wrapper:hover label~.selectgrid_cellinfo .selectgrid_celltitle,
  .selectgrid_cell input.hasfocus+.select_wrapper label~.selectgrid_cellinfo .selectgrid_celltitle {
    height: 0;
    opacity: 0;
    visibility: hidden;
    color: #fff;
    margin: 0;
  }

  .selectgrid_cell input:disabled+.select_wrapper label,
  .selectgrid_cell input:disabled+.select_wrapper label~.selectgrid_cellinfo,
  .selectgrid_cell input:disabled+.select_wrapper label~.selectgrid_celltext {
    opacity: .5;
    cursor: not-allowed;
  }

  .selectgrid_cell input:checked+.select_wrapper label {
    z-index: 1;
    border-color: #00b1e2;
  }

  .selectgrid_cell input:checked+.select_wrapper label>span {
    color: #00b1e2;
  }

  .selectgrid_cell input:checked+.select_wrapper label>span:before {
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f127";
    border-color: #00b1e2;
    background-color: #00b1e2;
    line-height: 22.5px;
  }

  .selectgrid_cell input:checked+.select_wrapper label:hover>span,
  .selectgrid_cell input:checked.hasfocus+.select_wrapper label>span {
    color: #fff;
  }

  .selectgrid_cell input:checked+.select_wrapper label:hover>span:before,
  .selectgrid_cell input:checked.hasfocus+.select_wrapper label>span:before {
    background-color: #fff;
  }

  .selectgrid_cellinfo {
    display: inline-block;
    overflow: hidden;
    height: 118px;
    cursor: pointer;
    pointer-events: none;
  }

  .selectgrid_celltitle {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.25em;
    margin: 0 0 .9375em;
    color: white;
  }

  .selectgrid_cellblurb {
    position: relative;
    z-index: 2;
  }

  .selectgrid_cellblurb p {
    margin: 0;
    color: white;
  }

  .selectgrid .show-multiple {
    display: none;
  }

  .selectgrid .multiple .show-multiple {
    display: inline-block;
  }

  .explore_grid_intro {
    font-size: 1.4em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .sidenote {
    float: right;
    clear: right;
    width: 35.89744%;
    margin-left: -35%;
    position: relative;
    right: -48%;
  }

  .dock .sidenote {
    width: 25%;
    margin-left: -35%;
    position: relative;
    right: -34%;
  }

  .content__centered .sidenote {
    width: 20.89744%;
    right: -28%;
  }

  .sidenote .ref-mark,
  .footnotes .ref-mark {
    top: 0;
    margin-bottom: 5px;
  }

  .sidenote .ref-mark {
    font-size: 75%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .splitcols_col.col-md-6:not(:only-child),
  .splitcols_col.col-pgmd-10:not(:only-child) {
    display: table-cell;
    float: none;
    vertical-align: top;
  }

  .splitcols_col.col-md-6:not(:only-child) .wrapper.splitcols_wrapper,
  .splitcols_col.col-pgmd-10:not(:only-child) .wrapper.splitcols_wrapper {
    max-width: 65em;
    padding: 5em 5em 5em 0;
    float: right;
  }

  .splitcols_col.col-md-6:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper,
  .splitcols_col.col-pgmd-10:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper {
    padding: 5em 0 5em 5em;
    float: left;
  }

  .splitcols_col:only-child {
    width: 100%;
  }

  .wrapper.splitcols_wrapper {
    padding: 5em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .stickybar {
    max-height: 120px;
  }

  .stickybar.stickybar_intab {
    transition-duration: 0s;
  }

  .stickybar.affix.stickybar_intab {
    margin-top: 48px;
  }

  .stickybar .stickybar_byline {
    display: inline-block;
    font-size: 1.4em;
  }

  .stickybar .visa_title {
    font-size: 18px;
    line-height: 1.2em;
  }

  .stickybar .stickybar_points {
    font-size: 2.6em;
  }

  .stickybar .stickybar_points strong {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
  }

  .stickybar .stickybar_content {
    font-size: 1.4em;
    padding: 0;
  }

  .stickybar .stickybar_altcontent {
    font-family: "gustan-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.42857143em;
  }

  .stickybar .stickybar_altcontent strong {
    padding-bottom: 1.5em;
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .stickybar .stickybar_btn {
    height: 50px;
    width: 50px;
    top: 35px;
  }

  .stickybar .stickybar_top {
    padding: 3em 0;
  }

  .stickybar .stickybar_bot {
    padding: 5em 0;
  }

  .container__restrict .stickybar {
    display: block;
  }

  .stickybar_intab.affix {
    top: 0;
  }

  .stickybar_holder .stickybar {
    transition-duration: 0s;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .table-collapsed {
    margin-bottom: 0;
  }

  .table-collapsed td {
    padding-left: 0;
  }

  .table-collapsed th,
  .table-collapsed td {
    margin: 0;
  }

  .table-collapsed th {
    padding-bottom: 0;
    width: 100%;
  }

  .table-collapsed tr:last-child th,
  .table-collapsed tr:last-child td {
    padding-bottom: 0;
  }

  .table-collapsed,
  .table-collapsed thead,
  .table-collapsed tfoot,
  .table-collapsed tbody,
  .table-collapsed tr,
  .table-collapsed th,
  .table-collapsed td {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .tabs {
    display: table;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .tabs_tab {
    display: table-cell;
    font-size: 9px;
    text-align: center;
    vertical-align: bottom;
  }

  .tabs_panel__default {
    display: block !important;
  }

  .tabs_accordiontrigger,
  a.tabs_accordiontrigger {
    display: none;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .termination_accordion .termination_accordion_header,
  .termination_accordion .termination_accordion_content {
    width: 100%;
    margin: 0;
  }

  .termination_accordion .accordion_trigger {
    top: 25px;
  }

  .termination_accordion .termination_accordion_content .blurb p {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 12px 0;
  }

  .termination h1 {
    margin-top: 0;
  }

  .termination form.form .form_fieldset {
    padding: 3em;
    margin-bottom: 30px;
  }

  .termination form.form .form_fieldset h3 {
    padding-bottom: 15px;
  }

  .termination form.form .form_field {
    padding-top: .75em;
  }

  .termination form.form .form_field .err-msg {
    font-size: 1.4em;
  }

  .termination form.form .form_field>label,
  .termination form.form .form_field label {
    font-size: 1.4em;
    line-height: 18px;
  }

  .termination form.form .form_field textarea {
    min-height: 110px;
  }

  .termination form.form .form_field .label-col {
    position: relative;
    height: 45px;
    padding-bottom: 0;
  }

  .termination form.form .form_field .label-col label {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    top: 43%;
    padding-right: 10px;
  }

  .termination form.form .form_field .label-col .input {
    padding-bottom: 10px;
    font-size: 16px;
  }

  .termination form.form .form_field .firstdate .input {
    border-right-width: 1px;
  }

  .termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__arrow b:before {
    right: 0;
  }

  .termination form.form .form_field.select_field.multiple_selects .select-col .select2-container {
    width: 95% !important;
    border-right-width: 1px;
  }

  .termination form.form .form_field.select_field.multiple_selects .select-col:last-child .select2-container {
    width: 100% !important;
  }

  .termination form.form .form_field.parsley-error .field__error {
    top: 6px;
  }

  .termination form.form .form_field.parsley-error .field__error:before {
    left: 8px;
  }

  .termination form.form .form_field.multiple_selects .calendarWrap.firstcal,
  .termination form.form .form_field.multiple_dates .calendarWrap.firstcal {
    width: 97%;
  }

  .termination .pre_form_questions form.form .form_fieldset {
    margin-bottom: 10px;
  }

  .termination .pre_form_actions {
    padding-top: 10px;
  }

  .termination .term_form .banner__alert {
    margin-bottom: 30px;
  }

  .termination .banner {
    padding-bottom: 2.5em;
  }

  .termination .banner .banner_icon {
    width: 30px;
  }

  .termination .banner .banner_text {
    padding: 4px 30px 0 0;
  }

  .termination .blue-link {
    font-size: 16px;
    margin: 40px 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .ticket {
    float: right;
    width: 300px;
    margin-right: -7.9365%;
    margin-left: 20px;
  }

  .ticket_text {
    padding: 2em 3em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .tipflag {
    margin-top: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .tools {
    position: absolute;
    top: 340px;
    right: 0;
  }

  .tools .btn {
    display: block;
    margin-bottom: 1px;
    width: 40px;
  }

  .tools_secondary {
    position: relative;
    background: none;
    display: block;
  }

  .tools_secondary>:first-child {
    border: 0;
  }

  .tools_secondary>.btn:hover.tools_print:after,
  .tools_secondary>.btn:focus.tools_print:after,
  .tools_secondary>.btn.active.tools_print:after,
  .tools_secondary>.btn:hover.tools_download:after,
  .tools_secondary>.btn:focus.tools_download:after,
  .tools_secondary>.btn.active.tools_download:after,
  .tools_secondary>.btn:hover .btn:after,
  .tools_secondary>.btn:focus .btn:after,
  .tools_secondary>.btn.active .btn:after {
    content: "";
    position: absolute;
    border: solid 4px transparent;
    border-right: solid 4px #dc2a5f;
    border-bottom: solid 4px transparent;
    bottom: 50%;
    left: -8px;
    margin-bottom: -4px;
    z-index: 10;
  }

  .tools_secondary>.btn[class^='tools_']:hover .title,
  .tools_secondary>.btn[class^='tools_']:focus .title,
  .tools_secondary>.btn[class^='tools_'].active .title {
    left: auto;
    width: auto;
  }

  .tools_secondary>.btn[class^='tools_']:hover .tools_title,
  .tools_secondary>.btn[class^='tools_']:focus .tools_title,
  .tools_secondary>.btn[class^='tools_'].active .tools_title {
    display: inline-block;
  }

  .tools_secondary .btn {
    border: 0;
  }

  .tools_secondary .btn:hover .title,
  .tools_secondary .btn:focus .title,
  .tools_secondary .btn.active .title {
    left: auto;
    width: auto;
    bottom: auto;
    padding: 0 20px;
  }

  .tools_secondary .btn .title {
    background: #f5c3d2;
    color: #333;
    z-index: 9;
    right: 100%;
    top: 0;
    display: inline-block;
    text-transform: capitalize;
    position: absolute;
  }

  .tools_secondary .btn .tools_title {
    background: #f5c3d2;
    color: #333;
    vertical-align: top;
  }

  .md-m0 {
    margin: 0;
  }

  .md-mt0 {
    margin-top: 0;
  }

  .md-mr0 {
    margin-right: 0;
  }

  .md-mb0 {
    margin-bottom: 0;
  }

  .md-ml0 {
    margin-left: 0;
  }

  .md-m1 {
    margin: .5rem;
  }

  .md-mt1 {
    margin-top: .5rem;
  }

  .md-mr1 {
    margin-right: .5rem;
  }

  .md-mb1 {
    margin-bottom: .5rem;
  }

  .md-ml1 {
    margin-left: .5rem;
  }

  .md-m2 {
    margin: 1rem;
  }

  .md-mt2 {
    margin-top: 1rem;
  }

  .md-mr2 {
    margin-right: 1rem;
  }

  .md-mb2 {
    margin-bottom: 1rem;
  }

  .md-ml2 {
    margin-left: 1rem;
  }

  .md-m3 {
    margin: 2rem;
  }

  .md-mt3 {
    margin-top: 2rem;
  }

  .md-mr3 {
    margin-right: 2rem;
  }

  .md-mb3 {
    margin-bottom: 2rem;
  }

  .md-ml3 {
    margin-left: 2rem;
  }

  .md-m4 {
    margin: 2.6rem;
  }

  .md-mt4 {
    margin-top: 2.6rem !important;
  }

  .md-mr4 {
    margin-right: 2.6rem;
  }

  .md-mb4 {
    margin-bottom: 2.6rem;
  }

  .md-ml4 {
    margin-left: 2.6rem;
  }

  .md-m5 {
    margin: 3rem;
  }

  .md-mt5 {
    margin-top: 3rem;
  }

  .md-mr5 {
    margin-right: 3rem;
  }

  .md-mb5 {
    margin-bottom: 3rem;
  }

  .md-ml5 {
    margin-left: 3rem;
  }

  .md-m6 {
    margin: 5.45rem;
  }

  .md-mt6 {
    margin-top: 5.45rem;
  }

  .md-mr6 {
    margin-right: 5.45rem;
  }

  .md-mb6 {
    margin-bottom: 5.45rem;
  }

  .md-ml6 {
    margin-left: 5.45rem;
  }

  .md-mx1 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md-mx4 {
    margin-left: 2.6rem;
    margin-right: 2.6rem;
  }

  .md-mx5 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md-mx6 {
    margin-left: 5.45rem;
    margin-right: 5.45rem;
  }

  .md-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .md-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md-mxn4 {
    margin-left: -2.6rem;
    margin-right: -2.6rem;
  }

  .md-mxn5 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md-mxn6 {
    margin-left: -5.45rem;
    margin-right: -5.45rem;
  }

  .md-myn1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .md-myn2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md-myn3 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md-myn4 {
    margin-top: -2.6rem;
    margin-bottom: -2.6rem;
  }

  .md-myn5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md-myn6 {
    margin-top: -5.45rem;
    margin-bottom: -5.45rem;
  }

  .md-mtn1 {
    margin-top: -0.5rem;
  }

  .md-mtn2 {
    margin-top: -1rem;
  }

  .md-mtn3 {
    margin-top: -2rem;
  }

  .md-mtn4 {
    margin-top: -2.6rem;
  }

  .md-mtn5 {
    margin-top: -3rem;
  }

  .md-mtn6 {
    margin-top: -5.45rem;
  }

  .md-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .padding-base,
  .padding-base-lg {
    padding-left: 0;
    padding-right: 0;
  }

  .md-p0 {
    padding: 0;
  }

  .md-pt0 {
    padding-top: 0;
  }

  .md-pr0 {
    padding-right: 0;
  }

  .md-pb0 {
    padding-bottom: 0;
  }

  .md-pl0 {
    padding-left: 0;
  }

  .md-p1 {
    padding: .5rem;
  }

  .md-py1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md-px1 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md-p2 {
    padding: 1rem;
  }

  .md-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md-p3 {
    padding: 2rem;
  }

  .md-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md-p4 {
    padding: 2.6rem;
  }

  .md-py4 {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }

  .md-px4 {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }

  .md-p5 {
    padding: 3rem;
  }

  .md-py5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md-px5 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md-p6 {
    padding: 5.45rem;
  }

  .md-py6 {
    padding-top: 5.45rem;
    padding-bottom: 5.45rem;
  }

  .md-px6 {
    padding-left: 5.45rem;
    padding-right: 5.45rem;
  }

  .md-p7 {
    padding: 6.25rem;
  }

  .md-py7 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .md-px7 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .md-p7 {
    padding: 6.25rem;
  }

  .md-py7 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .md-px7 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .md-p8 {
    padding: 11.111111rem;
  }

  .md-py8 {
    padding-top: 11.111111rem;
    padding-bottom: 11.111111rem;
  }

  .md-px8 {
    padding-left: 11.111111rem;
    padding-right: 11.111111rem;
  }

  .md-pt1 {
    padding-top: .5rem;
  }

  .md-pb1 {
    padding-bottom: .5rem;
  }

  .md-pt2 {
    padding-top: 1rem;
  }

  .md-pb2 {
    padding-bottom: 1rem;
  }

  .md-pt3 {
    padding-top: 2rem;
  }

  .md-pb3 {
    padding-bottom: 2rem;
  }

  .md-pt4 {
    padding-top: 2.6rem;
  }

  .md-pb4 {
    padding-bottom: 2.6rem;
  }

  .md-pt5 {
    padding-top: 3rem;
  }

  .md-pb5 {
    padding-bottom: 3rem;
  }

  .md-pt6 {
    padding-top: 5.45rem;
  }

  .md-pb6 {
    padding-bottom: 5.45rem;
  }

  .md-pt7 {
    padding-top: 6.25rem;
  }

  .md-pb7 {
    padding-bottom: 6.25rem;
  }

  .md-pt8 {
    padding-top: 11.111111rem;
  }

  .md-pb8 {
    padding-bottom: 11.111111rem;
  }

  .md-pr1 {
    padding-right: .5rem;
  }

  .md-pl1 {
    padding-left: .5rem;
  }

  .md-pr2 {
    padding-right: 1rem;
  }

  .md-pl2 {
    padding-left: 1rem;
  }

  .md-pr3 {
    padding-right: 2rem;
  }

  .md-pl3 {
    padding-left: 2rem;
  }

  .md-pr4 {
    padding-right: 2.6rem;
  }

  .md-pl4 {
    padding-left: 2.6rem;
  }

  .md-pr5 {
    padding-right: 3rem;
  }

  .md-pl5 {
    padding-left: 3rem;
  }

  .md-pr6 {
    padding-right: 5.45rem;
  }

  .md-pl6 {
    padding-left: 5.45rem;
  }

  .md-pr7 {
    padding-right: 6.25rem;
  }

  .md-pl7 {
    padding-left: 6.25rem;
  }

  .md-pr8 {
    padding-right: 11.111111rem;
  }

  .md-pl8 {
    padding-left: 11.111111rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2)       and (min-width: 46.875em),
only screen and (   min--moz-device-pixel-ratio: 2)       and (min-width: 46.875em),
only screen and (     -o-min-device-pixel-ratio: 2/1)     and (min-width: 46.875em),
only screen and (        min-device-pixel-ratio: 2)       and (min-width: 46.875em),
only screen and (                min-resolution: 192dpi)  and (min-width: 46.875em),
only screen and (                min-resolution: 2dppx)   and (min-width: 46.875em) {

}

@media only screen and (min-width: 59em) {
  /* aligns to bootstrap: 944; */

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .visible_base,
  .visible_small,
  .visible_medium,
  .visible_base.btn,
  .visible_small.btn,
  .visible_medium.btn,
  tr.visible_base,
  tr.visible_small,
  tr.visible_medium,
  th.visible_base,
  th.visible_small,
  th.visible_medium,
  td.visible_base,
  td.visible_small,
  td.visible_medium {
    display: none !important;
  }

  .visible_large {
    display: block !important;
  }

  table.visible_large {
    display: table;
  }

  tr.visible_large {
    display: table-row !important;
  }

  th.visible_large,
  td.visible_large {
    display: table-cell !important;
  }

  .visible_large.btn {
    display: inline-block !important;
  }

  .hidden_base,
  .hidden_small,
  .hidden_medium {
    display: block !important;
  }

  table.hidden_base,
  table.hidden_small,
  table.hidden_medium {
    display: table;
  }

  tr.hidden_base,
  tr.hidden_small,
  tr.hidden_medium {
    display: table-row !important;
  }

  th.hidden_base,
  th.hidden_small,
  th.hidden_medium,
  td.hidden_base,
  td.hidden_small,
  td.hidden_medium {
    display: table-cell !important;
  }

  .hidden_base.btn,
  .hidden_small.btn,
  .hidden_medium.btn {
    display: inline-block !important;
  }

  .hidden_large,
  .hidden_large.btn,
  tr.hidden_large,
  th.hidden_large,
  td.hidden_large {
    display: none !important;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__blurb {
    font-family: "fira-regular","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    margin: 0;
    padding: 29px 0 29px;
    line-height: 1.875em;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__title_blurb_cta,
  .accordinate__title_blurb_cta_wrapper .accordion_content_inner {
    padding-left: 0;
    padding-right: 0;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__cta {
    top: 11px;
  }

  .accordinate__title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    line-height: 1.25em;
    margin: 0;
    padding: 1.125em 0;
  }

  .accordion_trigger.accordinate__cta.accordinate__trigger_all {
    margin-bottom: 2.4em;
  }

  .accordion_trigger.accordinate__cta.accordinate__trigger_all .cta_hide_small {
    display: inline-block;
  }

  .accordion_trigger.accordinate__cta.accordinate__trigger_all:after {
    top: 50%;
  }

  .accordion_trigger.accordinate__cta {
    padding: 1.53846154em 1.53846154em 1.53846154em 0;
  }

  .accordion_trigger.accordinate__cta:after {
    top: 30px;
  }

  .accordinate__clickable .accordion_trigger.accordinate__cta:after {
    top: 27px;
  }

  .accordinate__clickable .accordion_trigger.accordinate__cta.active:after {
    top: 29px;
  }

  .accordinate__title_blurb_cta_wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__title {
    padding: 1.40625em 0 1.40625em;
  }

  .cta_box {
    float: right;
  }

  .accordion_content_inner p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .accordion_content_inner ul {
    padding-right: 15px;
  }

  .accordion_content_inner ul li {
    font-size: 14px;
    padding-bottom: 5px;
    padding-left: 0;
  }

  .criteria_accordion .accordion_trigger.accordinate__cta.accordinate__trigger_all#criteria_toggle_hide:after {
    margin-top: -3px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .alternatepathway_text {
    font-size: 2em;
    line-height: 1.5em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .banner.open {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .banner_icon {
    margin-right: 25%;
    font-size: 40px;
    width: 50px;
    padding-top: 0;
  }

  .banner_text {
    padding-left: 5%;
  }

  .banner_texttitle {
    width: 100%;
  }

  .banner_readmore {
    display: none;
  }

  .banner__simple .banner_text p,
  .banner__simple .banner_textcopy {
    font-size: 1.6em;
  }

  .banner__inline .banner_icon,
  .banner__inline .banner_title,
  .banner__inline .banner_text {
    display: inline-block;
    line-height: 0;
    float: left;
    margin: 0;
  }

  .banner__inline .banner_title {
    line-height: 38px;
    padding-left: 10px;
    padding-right: 20px;
  }

  .banner__inline .banner_text {
    padding-left: 0;
    padding-right: 0;
  }

  .banner__inline .banner_text p,
  .banner__inline .banner_textcopy {
    font-size: 1.5em;
    line-height: 35px;
  }

  .banner__static {
    padding: 2em 2.5em 4em;
  }

  .form__inpage+.banner.banner__static {
    margin-bottom: 0;
  }

  .banner__wide .banner_icon {
    left: -93px;
  }

  .banner__wide.open {
    padding-left: 0;
    padding-right: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .bgpattern.bgpattern_employers:before {
    background-size: 60%;
    top: 0;
  }

  .bgpattern.bgpattern_employers:after {
    background-size: 60%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .blocknav_subtitle {
    font-family: "gustan-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    line-height: 1.71428571em;
  }

  .blocknav_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 5em;
    line-height: 1.06em;
  }

  .blocknav_block {
    padding: 10em 0 25em;
    margin-left: -80px;
    margin-right: -80px;
  }

  .blocknav_link {
    height: 110px;
  }

  .blocknav_align {
    padding: .4em;
    display: table;
    height: 110px;
  }

  .blocknav_align .blocknav_linktext {
    display: table-cell;
    vertical-align: middle;
  }

  .blocknav_centered-sm {
    max-width: 800px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .btn__centered {
    margin-top: 30px;
  }

  [data-action-select].margin-onshow:not(.disabled):not(:disabled).btn__centered,
  .btn_hidedisabled.margin-onshow:not(.disabled):not(:disabled).btn__centered {
    margin-top: 30px;
  }

  .btn_back_wrap {
    float: right;
    padding-top: 10px;
  }

  .btn_back_wrap .btn_back_button {
    max-width: 180px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .content_list_heading {
    font-size: 20px;
    line-height: 25px;
  }

  .content_list_blurb {
    font-size: 16px;
    line-height: 25px;
  }

  .content_list_blurb__linked {
    font-size: 14px;
    line-height: 20px;
  }

  .content_list_item .content_list_headwrap {
    padding-top: 23px;
  }

  .content_list_item .content_list_trigger span {
    display: block;
  }

  .content_details li,
  .content .content_details li,
  .content_list__content li,
  .content .content_list__content li,
  .content_details p,
  .content .content_details p,
  .content_list__content p,
  .content .content_list__content p,
  .content_details a,
  .content .content_details a,
  .content_list__content a,
  .content .content_list__content a {
    font-size: 14px;
    line-height: 20px;
  }

  .content_details .content_listing_wrap,
  .content_list__content .content_listing_wrap {
    padding-top: 0;
  }

  .content_details .content_listing_title:after,
  .content_list__content .content_listing_title:after {
    display: none;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .contentblock_centered {
    max-width: 1000px;
  }

  .contentblock_centered_small {
    max-width: 600px;
  }

  .contentblock_centered_large {
    max-width: 900px;
  }

  .ie9 .contentblock_centered_large {
    max-width: 1200px;
  }

  .contentblock_centered_xlarge {
    max-width: 900px;
  }

  .contentblock_centered__medium {
    max-width: 800px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .currencyconverter {
    margin-top: 0;
    margin-left: -20px;
  }

  .currencyconverter_value {
    font-size: 50px;
    line-height: 28px;
    font-family: "gustan-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #323849;
  }

  .currencyconverter_value sub {
    font-size: 25px;
    line-height: 25px;
    bottom: 0;
  }

  .currencyconverter__small .currencyconverter_value {
    font-size: 30px;
    line-height: 20px;
  }

  .currencyconverter__small .currencyconverter_value sub {
    font-size: 20px;
    line-height: 25px;
    bottom: 0;
  }

  .currencyconverter__right {
    margin-top: 10px;
    margin-left: 0;
  }

  .currencyconverter__right .currencyconverter_select .select2-container.select2-container--mini .select2-selection--single {
    padding-right: 60px;
  }

  .currency {
    text-align: left;
    margin: -4px 0 0 0;
  }

  a.accCalendar {
    top: 14px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dropdown_label__footer {
    padding-top: 15px;
    padding-bottom: 15px;
    float: left;
    position: absolute;
    left: 0;
    padding-left: 5%;
  }

  .right .dropdown_label__footer {
    border-left: 1px solid rgba(70,75,92,0.5);
  }

  .dropdown__footer {
    box-sizing: border-box;
    border-bottom: none;
    width: 105%;
  }

  .dropdown__footer.left {
    left: -5%;
  }

  .dropdown__footer.right {
    left: 0;
    right: -5%;
  }

  .dropdown__footer.right .select2-container {
    padding-right: 5%;
  }

  .dropdown__footer .left+.select2 .select2-selection__rendered {
    text-indent: 150px;
    padding-right: 35px;
  }

  .dropdown__footer .right+.select2 .select2-selection__rendered {
    text-indent: 190px;
  }

  .select2-container--open .dropdown__footer .select2-selection__arrow b {
    margin-top: -10px;
  }

  .dropdown__footer .select2-selection__arrow b:before {
    height: 17px;
    width: 17px;
  }

  .dropdown__footer .select2-search--inline .select2-search__field {
    max-width: 50%;
    padding-right: 4%;
  }

  .dropdown__footer .select2-container .select2-search--inline:after {
    margin-right: 0;
    right: 5%;
  }

  .dropdown_body__footer.left .select2-results__options .select2-results__option {
    text-indent: 150px;
  }

  .process_filter .select2-container .select2-selection--single {
    padding-left: 20px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dropdown__login {
    margin-right: 5em;
  }

  .dropdown__login .select2-container--focus {
    outline: 1px dotted #666;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .fees_step_wrap.visa_steps .process__vertical .process_title {
    font-size: 2em;
  }

  .fees_step_wrap.visa_steps .steps_title,
  .fees_step_wrap.visa_steps .standalone {
    padding: 0;
  }

  .vf_wrap .filter_wrap {
    max-width: 800px;
    margin: 0 auto;
  }

  .vf_wrap .filter_wrap .form {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .vf_wrap .filter_wrap .form__tool+[data-tool-error] .banner {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .vf_wrap .accordinate__clickable .accordion_trigger.accordinate__cta {
    padding-right: 3.6%;
  }

  .vf_wrap .stickybar.stickybar_policy p.stickybar_byline:before {
    top: 27px;
    left: 0;
    font-size: 36px;
  }

  .vf_wrap .stickybar.stickybar_policy p.sb_text {
    padding-top: 10px;
  }

  .vf_wrap .fees_step_wrap .process__vertical .processing .process_content {
    padding-top: 15px;
  }

  .vf_wrap .fees_step_wrap.visa_steps .steps_title {
    padding-left: 0;
  }

  .tools.vf_tools {
    top: 340px;
  }

  .vf_panel.hasSticky {
    margin-top: 50px;
  }

  .tab_overview .vf_panel.hasSticky {
    margin-top: 188px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .fees_step_wrap .row.has_accordion {
    padding-bottom: 12px;
  }

  .fees_step_wrap .row .row {
    padding-bottom: 12px;
  }

  .fees_step_wrap .row .row .rcd_wrap {
    padding-bottom: 15px;
  }

  .fees_step_wrap .row .row.pm_row .question_evidence {
    padding-top: 1.4em;
  }

  .fees_step_wrap .process__vertical .process_step {
    padding-top: 2.5em;
  }

  .fees_step_wrap .process__vertical .process_heading {
    padding-top: 1.9em;
    min-height: 1px;
  }

  .fees_step_wrap .process__vertical .process_heading:before {
    width: 7.8em;
  }

  .fees_step_wrap .process__vertical .process_heading .process_number {
    font-size: 5.5em;
    line-height: 55px;
  }

  .fees_step_wrap .process__vertical .process_title {
    padding: 27px 10px 30px 15px;
  }

  .fees_step_wrap .process__vertical .process_content {
    padding: 27px 0 15px;
  }

  .fees_step_wrap .process__vertical .process_content p {
    padding-bottom: 5px;
  }

  .fees_step_wrap .process__vertical .process_downloads {
    margin-top: 12px;
  }

  .fees_step_wrap .content ul {
    margin-top: -1em;
    padding-bottom: 1.5em;
  }

  .fees_step_wrap .content ul:last-child {
    padding-bottom: 0;
  }

  .fees_step_wrap .question_evidencetrigger {
    padding-top: 1.2em;
  }

  .fees_step_wrap .question_evidencetrigger:after {
    top: 45%;
  }

  .fees_content .process__vertical {
    padding: 30px 0 5px;
  }

  .fees_panels .col .inner h3 {
    font-size: 2em;
    line-height: 25px;
    padding-bottom: 20px;
  }

  .points_wrap .calculate_subtotal {
    right: -60px;
  }

  .payment_method p {
    margin-bottom: 14px;
  }

  .payment_method ul li {
    font-size: 14px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .footer .row {
    position: relative;
  }

  .footer .footer_filters .wrapper,
  .footer .footer_primary .wrapper {
    max-width: 130em;
    width: 90%;
  }

  .footer .footer_primary {
    padding: 6em 0 2.5em;
  }

  .footer .footer_primary_col {
    border-bottom: none;
    float: left;
    width: 20.4%;
  }

  .footer .footer_primary_col__first .footer_list__header a {
    padding-left: 0;
  }

  .footer .footer_primary_col__first .footer_list {
    padding-left: 0;
  }

  .footer .footer_primary_col__first .footer_list:before {
    display: none;
  }

  .footer .footer_primary_col__last {
    width: 18.4%;
  }

  .footer .footer_list {
    padding: 0 2em 0 3em;
    max-height: none;
  }

  .footer .footer_list.open {
    border-bottom: none;
    max-height: none;
  }

  .footer .footer_list li {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .footer .footer_list li:first-child {
    padding-top: 0;
  }

  .footer .footer_list:before {
    content: '';
    top: 0;
    bottom: 27px;
    margin-left: -3em;
    border-left: 1px solid rgba(70,75,92,0.5);
    position: absolute;
  }

  .footer .footer_list a {
    padding: .5em 0 .57142857em;
  }

  .footer .footer_list__header {
    border: none;
    cursor: auto;
  }

  .footer .footer_list__header a {
    min-height: 4.28571429em;
    padding: 0 1.42857143em 2.5em 2.14285714em;
  }

  .footer .footer_list__header:after {
    display: none;
  }

  .footer .footer_list__header .footer_list_link__slide_trigger {
    display: none;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .form_fieldset {
    padding: 3em;
  }

  .panel_contact .form_fieldset {
    padding: 0;
  }

  .form__tool .form_fieldset {
    padding: 30px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .header .header_mobile {
    display: none;
  }

  .header .header_top,
  .header .header_bottom {
    display: block;
  }

  .header .header_bottom {
    margin-top: -4px;
  }

  .header .header_top_wrapper {
    position: relative;
  }

  .header .header_logo_link {
    position: absolute;
    left: -18.084615%;
    top: 45px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .header .header_login {
    padding: 2em 0 1.2em;
  }

  .header .header_login_intro {
    padding-right: 4em;
  }

  .header .header_login__init {
    padding-bottom: 18px;
  }

  .header .header_login_text {
    font-size: 1.3em;
    padding-bottom: 10px;
    padding-right: 2.85714286em;
    padding-top: 15px;
  }

  .header .header_login__realme {
    padding-bottom: 19px;
  }

  .header .header_login__site {
    padding-bottom: 23px;
  }

  .header .header_login__site .header_login_intro {
    height: auto;
    background: transparent;
    padding: 0 4em 0 0;
  }

  .header .header_login__site .header_login_content {
    position: relative;
    padding-right: 55px;
    padding-bottom: 0;
  }

  .header .header_login__site .header_login_content h5 {
    margin: 0;
  }

  .header .header_login__site .header_login_content form {
    padding-top: 1em;
  }

  .header .header_login__site .header_login_content .field {
    float: left;
    padding-bottom: 0;
    padding-right: .1em;
    width: 35%;
  }

  .header .header_login__site .header_login_content .action {
    width: 20%;
  }

  .header .header_login__site .header_login_content .field_forgotten_password {
    padding: 0;
  }

  .header .header_login__site .sitelogin {
    width: 100%;
    text-align: left;
    background: #dc2a5f;
    border: none;
  }

  .header .header_login__site .sitelogin:before {
    display: none;
  }

  .no-touch .header .header_login__site .sitelogin:hover,
  .no-touch .header .header_login__site .sitelogin:active,
  .no-touch .header .header_login__site .sitelogin:focus {
    color: #fff;
  }

  .header .header_login__text {
    padding-bottom: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .header .header_search input:-ms-input-placeholder {
    font-size: 4em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .image__small {
    left: -54%;
    width: 54%;
    margin-right: -45%;
  }

  .dock .image__small {
    left: -50px;
    width: 36%;
    margin-right: 0;
  }

  .image__small img {
    float: right;
  }

  .image__dark .image_caption_wrapper:before {
    background-color: #323849;
  }

  .image__large {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .image__large .image_wrapper {
    margin-left: 25%;
  }

  .image__large .image_wrapper img {
    width: auto;
  }

  .dock .image__large .image_wrapper {
    margin-left: 0;
  }

  .dock .image__large.image__hascaption .image_wrapper {
    margin-left: 25%;
  }

  .image_caption {
    position: absolute;
    left: 0;
    width: 25%;
    top: 0;
    bottom: 0;
  }

  .dock .image_caption {
    width: 35%;
  }

  .image_caption_wrapper {
    overflow: hidden;
    max-height: 100%;
  }

  .image_caption_wrapper:before {
    content: '';
    position: absolute;
    left: -200%;
    top: 0;
    bottom: 0;
    background-color: #f2f2f2;
    width: 200%;
  }

  .image_caption_inner {
    padding: 6em 5em 6em 0;
  }

  .dock .image_caption_inner {
    padding: 3.5em 3.5em 3.5em 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .link_external:after {
    padding-top: 2px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .linkgrid {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .linkgrid .linkgrid_abstract {
    line-height: 1.6em;
    font-size: 1.4em;
  }

  .linkgrid_row {
    width: auto;
  }

  .linkgrid_item .linkgrid_wrap {
    height: 80%;
  }

  .linkgrid_item .linkgrid_title {
    line-height: 1.1em;
  }

  .linkgrid_item__promo .linkgrid_wrap__promo {
    height: auto;
    padding: 1.5em;
  }

  .linkgrid_item__promo .linkgrid_wrap__promo:after {
    top: auto;
    bottom: 10px;
  }

  .linkgrid_item__promo .linkgrid_title__promo {
    padding-bottom: .5em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .map_outer {
    height: 650px;
  }

  .map_item_detail {
    width: 380px;
  }

  .results_title.maptool_title {
    font-size: 36px;
    line-height: 45px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .mediafooter {
    padding: 5em 0 6em;
  }

  .mediafooter .row {
    position: relative;
  }

  .mediafooter_rowdivider {
    position: relative;
  }

  .mediafooter_divider {
    border: none;
  }

  .mediafooter_divider:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: solid 1px #d6d7da;
  }

  .mediafooter_divider:first-child:before {
    content: normal;
  }

  .mediafooter_title {
    font-size: 2.5em;
    margin: .6em 2em 1.2em 1.2em;
  }

  .mediafooter_article {
    padding: 0 3em 1.5em;
  }

  .mediafooter_linktitle {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.38888889em;
    margin: 0 0 .83333333em;
  }

  .mediafooter_linkdate {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.28571429em;
  }

  .mediafooter_feature {
    position: relative;
  }

  .mediafooter_feature:before {
    left: 0;
    top: -5555px;
  }

  .mediafooter_contact {
    padding: 0 2em 0 5em;
  }

  .mediafooter_featuretitle {
    font-size: 2em;
    line-height: 1.1em;
  }

  .mediafooter_featureinfo {
    font-size: 1.6em;
    margin-bottom: .9375em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .motivegrid {
    margin-bottom: 0;
  }

  .motivegrid_itemtitle {
    font-size: 2em;
  }

  .motivegrid_itemblurb {
    font-size: 1.3em;
  }

  .motivegrid_promotext {
    padding-right: 5em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .officefees_result h3 {
    font-size: 20px;
    line-height: 24px;
    padding-right: 30px;
  }

  .officefees_result h4 {
    font-family: "fira-regular","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
  }

  .content_details__officefees .content_listing_wrap:first-child {
    padding-top: 5px;
  }

  .officefees_processing_time {
    text-align: left;
    margin: -4px 0 0 0;
  }

  .content .content_details__officefees .content_listing_title {
    font-family: "fira-regular","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    margin-top: -2px;
  }

  .content .content_details__officefees .content_listing_map {
    padding-left: 0;
  }

  .content .content_details__officefees .content_listing_physical:before {
    margin-top: 1px;
  }

  .content .content_details__officefees .content_listing_web:before {
    margin-top: 1px;
  }

  .content .content_details__officefees .content_listing_email:before {
    margin-top: 2px;
  }

  .content .content_details__officefees .content_listing_phone:before {
    margin-top: 1px;
  }

  .content .content_details__officefees .content_listing_fax:before {
    margin-top: 2px;
  }

  .content .content_details__officefees .content_listing_hours:before {
    margin-top: 1px;
  }

  .content .content_details__officefees .content_listing_facebook:before {
    margin-top: 1px;
  }

  .content .content_details__officefees .content_listing_info:before {
    margin-top: 1px;
  }

  .content .content_details__officefees .content_listing_emedical:before {
    margin-top: 3px;
  }

  .content .content_details__officefees .content_listing_postal:before {
    margin-top: 2px;
  }

  .content .content_details__officefees .content_listing_payment:before {
    margin-top: 4px;
  }

  .content .content_details__officefees .content_listing_courier:before {
    margin-top: 2px;
  }

  .listmap_switch {
    position: absolute;
    right: 0;
    top: 0;
  }

  .list_view,
  .map_view {
    min-width: 75px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .ops_manual_links ul {
    padding-top: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .panel__split {
    padding: 0 3rem 0;
  }

  .panel__split:before,
  .panel__split:after {
    color: transparent;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .panel__split:after {
    clear: both;
  }

  .panel__split .panel_first {
    margin-right: -1px;
    border-right: 1px solid #f2f2f2;
  }

  .panel__split .panel_last {
    padding-left: 3rem;
    border-left: 1px solid #f2f2f2;
  }

  .panel__split .panel_first,
  .panel__split .panel_last {
    border-bottom: 0;
    padding-top: 1.5rem;
    padding-bottom: 2.4rem;
    width: 50%;
    float: left;
  }

  .pswp__caption__center {
    max-width: 900px;
  }

  .pswp__top-bar .pswp__button--fs {
    display: block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .policecert_result .listing_item_inner {
    padding-top: 7px;
  }

  .process__vertical .popout.popout__full .popout_set,
  .process__vertical .popout.popout__midpage .popout_set {
    max-width: 800px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .process {
    position: relative;
    margin-bottom: 4em;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .process .popout {
    top: 0;
  }

  .process .popout_set {
    padding-left: 11.6%;
    padding-right: 8.33333333%;
  }

  .process>a {
    display: block;
    flex: 1 auto;
    align-self: stretch;
    position: relative;
  }

  .process>a .process_content {
    height: 100%;
    margin-bottom: 50px;
  }

  .process>a:hover,
  .process>a:focus {
    outline: 1px solid #00b1e3;
    z-index: 1;
  }

  .process .process_readmore {
    position: absolute;
    bottom: 8px;
  }

  .process_stage_title {
    font-size: 36px;
    line-height: 40px;
  }

  .process_stage_title span {
    font-size: 1.39em;
  }

  .process_stage {
    font-size: 1.4em;
  }

  .process_stage.empty {
    display: block;
  }

  .process_stage span {
    font-size: 1.2em;
  }

  .process_step {
    padding: 2em 2.2em 0;
    border: none;
  }

  .process_step:before {
    content: " ";
    position: absolute;
    top: 0;
    height: 100%;
    width: 0;
    margin-left: -2.2em;
    border-left: solid 1px #323849;
  }

  .process_step.open .process_title:after {
    display: none;
  }

  .process_step.open .process_content {
    padding-bottom: 0;
  }

  .process_heading {
    padding-top: 1.7em;
    margin: 0 0 1.5em;
  }

  .process_heading.js-trigger-collapse {
    cursor: auto;
  }

  .process_heading.js-trigger-collapse[tabindex] {
    cursor: pointer;
  }

  .process_number {
    line-height: .8em;
    display: block;
    font-size: 3.5em;
    margin-bottom: .14285714em;
  }

  .process_title,
  .popout_title {
    font-size: 1.5em;
    line-height: 1.06666667em;
    padding-right: 0;
    overflow: visible;
    padding-bottom: 5px;
  }

  .process_title:after,
  .popout_title:after {
    display: none;
  }

  .process_readmore {
    max-height: none;
  }

  .process_content {
    max-height: none;
    margin-bottom: 1.5em;
  }

  .process_content p {
    font-size: 1.2em;
    line-height: 20px;
  }

  .process.process__collapsible .process_content {
    max-height: 0;
    margin-bottom: 0;
  }

  .process.process__collapsible .process_heading {
    cursor: pointer;
  }

  .process.process__collapsible .process_title,
  .process.process__collapsible .popout_title {
    padding-right: .9em;
  }

  .process.process__collapsible .process_title:after,
  .process.process__collapsible .popout_title:after {
    display: inline-block;
    position: static;
    margin: 9px -3em 0 1em;
    padding-left: 0;
    padding-right: .5em;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
    right: auto;
    top: auto;
    font-size: 11px;
  }

  .process.process__collapsible.open .process_title:after,
  .process.process__collapsible.open .popout_title:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  .process.process__collapsible.open .process_content {
    max-height: 1000px;
    margin-bottom: 1.5em;
  }

  .process__vertical {
    margin-bottom: 0;
    display: block;
    align-items: initial;
    align-content: initial;
    justify-content: initial;
  }

  .process__vertical .process_heading {
    padding-top: 3em;
    height: auto;
    min-height: 100px;
  }

  .process__vertical .process_heading:before {
    width: 10em;
  }

  .process__vertical .process_heading .process_number {
    font-size: 5.8em;
  }

  .process__vertical .process_title,
  .process__vertical .popout_title {
    margin: 0;
    padding: 24px 24px 17px 34px;
  }

  .process__vertical .process_title:after,
  .process__vertical .popout_title:after {
    display: none;
  }

  .process__vertical .popout_title {
    padding-top: 0;
  }

  .process__vertical .process_step {
    padding: 0;
  }

  .process__vertical .process_step__stage:nth-child(1) {
    margin-top: 0;
  }

  .process__vertical .process_stage {
    font-size: 2em;
    margin-top: 0;
  }

  .process__vertical .process_stage:after {
    bottom: -14px;
  }

  .process__vertical .process_stage.empty {
    display: block;
  }

  .process__vertical .process_stage.empty:after {
    bottom: -2px;
  }

  .process__vertical .process_content {
    margin: 0;
    padding-bottom: 1em;
  }

  .process__vertical .process_content.process_content__accordian {
    padding-bottom: 5px;
  }

  .process__vertical .btn__primary {
    float: right;
    width: auto;
    margin-right: 0;
  }

  .process_innerwrap:before {
    left: 100px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .question_container .toggle-group {
    margin-top: 20px;
  }

  .question_detail p:last-child {
    margin-bottom: 0;
  }

  .question_actions {
    padding: 0;
  }

  .question_detail {
    width: 50%;
    float: left;
  }

  .question_flags {
    margin-left: 8.33333333%;
    width: 41.66666667%;
    float: left;
  }

  .question_flags p,
  .question_flags ul li,
  .question_flags ol li {
    font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.42857143em;
    margin-top: 0;
  }

  .question_flags ul,
  .question_flags ol {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .question_flags ul li,
  .question_flags ol li {
    margin-bottom: 0;
  }

  .question_flags ul li:before,
  .question_flags ol li:before {
    content: none;
  }

  .question_input .calculate_subtotal {
    right: -60px;
    width: 57px;
    padding: 3px 10px;
  }

  .question_title {
    max-width: 180px;
  }

  .question_option {
    padding-left: 215px;
  }

  .question_evidenceinner .question_detail .detail_list li {
    font-size: 14px;
    line-height: 20px;
  }

  .question_container.has_left_nav {
    padding: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .resourcefooter {
    max-width: 65em;
    margin: 0 auto;
  }

  .resourcefooter_list li {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
  }

  .resourcefooter_searchfield:after {
    font-size: 22px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .results_loadbtn {
    font-size: 20px;
  }

  .results_summary {
    padding-left: 2.6rem;
  }

  .results_summary__flush {
    padding-left: 0;
    padding-right: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .select2-selection__arrow {
    right: 15px;
  }

  .select2-selection__arrow b {
    top: 3px;
  }

  .question_select+.select2 .select2-selection__arrow b {
    top: 0;
  }

  .select2-search--dropdown {
    padding: 0;
  }

  .select2-search--dropdown .select2-search__field {
    padding: 0 18px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .footnote,
  a.footnote,
  .footnotes ol>li:before {
    margin: 0;
  }

  .dock .footnotes ol {
    margin-left: 30px;
  }

  .dock .footnotes ol>li:before {
    margin-left: -30px;
    margin-right: 7px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .splitcols_col.col-lg-6:not(:only-child),
  .splitcols_col.col-pglg-13:not(:only-child) {
    display: table-cell;
    float: none;
    vertical-align: top;
  }

  .splitcols_col.col-lg-6:not(:only-child) .wrapper.splitcols_wrapper,
  .splitcols_col.col-pglg-13:not(:only-child) .wrapper.splitcols_wrapper {
    max-width: 65em;
    padding: 6.5em 5em 6.5em 0;
    float: right;
  }

  .splitcols_col.col-lg-6:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper,
  .splitcols_col.col-pglg-13:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper {
    padding: 6.5em 0 6.5em 5em;
    float: left;
  }

  .splitcols_col:only-child .wrapper.splitcols_wrapper {
    width: 75%;
    padding: 6.5em;
  }

  .splitcols_col:only-child .splitcols_title {
    margin: 0 0 .73em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .stickybar .visa_title {
    font-size: 24px;
    line-height: 1.2em;
  }

  .stickybar .stickybar_byline {
    display: block;
  }

  .stickybar .stickybar_byline span {
    display: inline-block;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .tabs_tab {
    font-size: 10px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .termination_accordion .termination_accordion_header,
  .termination_accordion .termination_accordion_content {
    width: 100%;
    margin: 0;
  }

  .termination_accordion .termination_accordion_header h2 {
    font-size: 30px;
    margin-top: 24px;
  }

  .termination_accordion .termination_accordion_header .blurb p {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 12px 0;
  }

  .termination_accordion .accordion_trigger {
    top: 25px;
  }

  .termination form.form .form_fieldset h3 {
    padding-bottom: 20px;
  }

  .termination form.form .form_field .input {
    padding-left: 20px;
    height: 49px;
    font-size: 16px;
  }

  .termination form.form .form_field>label,
  .termination form.form .form_field label {
    font-size: 1.6em;
    line-height: 20px;
  }

  .termination form.form .form_field .label-col label {
    top: 50%;
  }

  .termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single {
    padding-left: 20px;
    height: 48px;
    padding-top: 10px;
  }

  .termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__rendered,
  .termination form.form .form_field.select_field .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__placeholder {
    font-size: 16px;
  }

  .termination form.form .form_field.parsley-error .field__error {
    height: 50px;
    right: -46px;
  }

  .termination form.form .form_field.parsley-error .field__error:before {
    left: 7px;
  }

  .termination form.form .form_field.parsley-error .field__error:after {
    top: 18px;
  }

  .termination .select2-selection__arrow b {
    top: 0;
  }

  .termination .banner {
    padding-top: 2.4em;
    padding-bottom: 2.4em;
  }

  .termination .banner .banner_icon {
    margin: 0;
    width: 50px;
  }

  .termination .banner .banner_text {
    padding-top: 0;
  }

  .termination .blue-link {
    margin: 60px 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .ticket {
    width: 400px;
    margin-right: -57.2651%;
    margin-left: 40px;
  }

  dl.ticket_info dt {
    width: 25%;
  }

  dl.ticket_info dd {
    width: 75%;
  }

  .ticket_text {
    padding: 3em 5em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .tipflag p,
  .tipflag ul li,
  .tipflag ol li {
    font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.42857143em;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: .71428571em;
  }

  .tipflag ul,
  .tipflag ol {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tipflag ul li,
  .tipflag ol li {
    margin-bottom: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .toolswrap .btn.btn__secondary {
    font-size: 14px;
  }

  .lg-m0 {
    margin: 0;
  }

  .lg-mt0 {
    margin-top: 0;
  }

  .lg-mr0 {
    margin-right: 0;
  }

  .lg-mb0 {
    margin-bottom: 0;
  }

  .lg-ml0 {
    margin-left: 0;
  }

  .lg-m1 {
    margin: .5rem;
  }

  .lg-mt1 {
    margin-top: .5rem;
  }

  .lg-mr1 {
    margin-right: .5rem;
  }

  .lg-mb1 {
    margin-bottom: .5rem;
  }

  .lg-ml1 {
    margin-left: .5rem;
  }

  .lg-m2 {
    margin: 1rem;
  }

  .lg-mt2 {
    margin-top: 1rem;
  }

  .lg-mr2 {
    margin-right: 1rem;
  }

  .lg-mb2 {
    margin-bottom: 1rem;
  }

  .lg-ml2 {
    margin-left: 1rem;
  }

  .lg-m3 {
    margin: 2rem;
  }

  .lg-mt3 {
    margin-top: 2rem;
  }

  .lg-mr3 {
    margin-right: 2rem;
  }

  .lg-mb3 {
    margin-bottom: 2rem;
  }

  .lg-ml3 {
    margin-left: 2rem;
  }

  .lg-m4 {
    margin: 2.6rem;
  }

  .lg-mt4 {
    margin-top: 2.6rem;
  }

  .lg-mr4 {
    margin-right: 2.6rem;
  }

  .lg-mb4 {
    margin-bottom: 2.6rem;
  }

  .lg-ml4 {
    margin-left: 2.6rem;
  }

  .lg-m5 {
    margin: 3rem;
  }

  .lg-mt5 {
    margin-top: 3rem;
  }

  .lg-mr5 {
    margin-right: 3rem;
  }

  .lg-mb5 {
    margin-bottom: 3rem;
  }

  .lg-ml5 {
    margin-left: 3rem;
  }

  .lg-m6 {
    margin: 5.45rem;
  }

  .lg-mt6 {
    margin-top: 5.45rem;
  }

  .lg-mr6 {
    margin-right: 5.45rem;
  }

  .lg-mb6 {
    margin-bottom: 5.45rem;
  }

  .lg-ml6 {
    margin-left: 5.45rem;
  }

  .lg-mx1 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg-mx4 {
    margin-left: 2.6rem;
    margin-right: 2.6rem;
  }

  .lg-mx5 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg-mx6 {
    margin-left: 5.45rem;
    margin-right: 5.45rem;
  }

  .lg-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg-mxn4 {
    margin-left: -2.6rem;
    margin-right: -2.6rem;
  }

  .lg-mxn5 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg-mxn6 {
    margin-left: -5.45rem;
    margin-right: -5.45rem;
  }

  .lg-myn1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .lg-myn2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg-myn3 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg-myn4 {
    margin-top: -2.6rem;
    margin-bottom: -2.6rem;
  }

  .lg-myn5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg-myn6 {
    margin-top: -5.45rem;
    margin-bottom: -5.45rem;
  }

  .lg-mtn1 {
    margin-top: -0.5rem;
  }

  .lg-mtn2 {
    margin-top: -1rem;
  }

  .lg-mtn3 {
    margin-top: -2rem;
  }

  .lg-mtn4 {
    margin-top: -2.6rem;
  }

  .lg-mtn5 {
    margin-top: -3rem;
  }

  .lg-mtn6 {
    margin-top: -5.45rem;
  }

  .lg-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg-p0 {
    padding: 0;
  }

  .lg-pt0 {
    padding-top: 0;
  }

  .lg-pr0 {
    padding-right: 0;
  }

  .lg-pb0 {
    padding-bottom: 0;
  }

  .lg-pl0 {
    padding-left: 0;
  }

  .lg-p1 {
    padding: .5rem;
  }

  .lg-py1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg-px1 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg-p2 {
    padding: 1rem;
  }

  .lg-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg-p3 {
    padding: 2rem;
  }

  .lg-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg-p4 {
    padding: 2.6rem;
  }

  .lg-py4 {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }

  .lg-px4 {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }

  .lg-p5 {
    padding: 3rem;
  }

  .lg-py5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg-px5 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg-p6 {
    padding: 5.45rem;
  }

  .lg-py6 {
    padding-top: 5.45rem;
    padding-bottom: 5.45rem;
  }

  .lg-px6 {
    padding-left: 5.45rem;
    padding-right: 5.45rem;
  }

  .lg-p7 {
    padding: 6.25rem;
  }

  .lg-py7 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .lg-px7 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .lg-p8 {
    padding: 11.111111rem;
  }

  .lg-py8 {
    padding-top: 11.111111rem;
    padding-bottom: 11.111111rem;
  }

  .lg-px8 {
    padding-left: 11.111111rem;
    padding-right: 11.111111rem;
  }

  .lg-pt1 {
    padding-top: .5rem;
  }

  .lg-pb1 {
    padding-bottom: .5rem;
  }

  .lg-pt2 {
    padding-top: 1rem;
  }

  .lg-pb2 {
    padding-bottom: 1rem;
  }

  .lg-pt3 {
    padding-top: 2rem;
  }

  .lg-pb3 {
    padding-bottom: 2rem;
  }

  .lg-pt4 {
    padding-top: 2.6rem;
  }

  .lg-pb4 {
    padding-bottom: 2.6rem;
  }

  .lg-pt5 {
    padding-top: 3rem;
  }

  .lg-pb5 {
    padding-bottom: 3rem;
  }

  .lg-pt6 {
    padding-top: 5.45rem;
  }

  .lg-pb6 {
    padding-bottom: 5.45rem;
  }

  .lg-pt7 {
    padding-top: 6.25rem;
  }

  .lg-pb7 {
    padding-bottom: 6.25rem;
  }

  .lg-pt8 {
    padding-top: 11.111111rem;
  }

  .lg-pb8 {
    padding-bottom: 11.111111rem;
  }

  .lg-pr1 {
    padding-right: .5rem;
  }

  .lg-pl1 {
    padding-left: .5rem;
  }

  .lg-pr2 {
    padding-right: 1rem;
  }

  .lg-pl2 {
    padding-left: 1rem;
  }

  .lg-pr3 {
    padding-right: 2rem;
  }

  .lg-pl3 {
    padding-left: 2rem;
  }

  .lg-pr4 {
    padding-right: 2.6rem;
  }

  .lg-pl4 {
    padding-left: 2.6rem;
  }

  .lg-pr5 {
    padding-right: 3rem;
  }

  .lg-pl5 {
    padding-left: 3rem;
  }

  .lg-pr6 {
    padding-right: 5.45rem;
  }

  .lg-pl6 {
    padding-left: 5.45rem;
  }

  .lg-pr7 {
    padding-right: 6.25rem;
  }

  .lg-pl7 {
    padding-left: 6.25rem;
  }

  .lg-pr8 {
    padding-right: 11.111111rem;
  }

  .lg-pl8 {
    padding-left: 11.111111rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2)       and (min-width: 59em),
only screen and (   min--moz-device-pixel-ratio: 2)       and (min-width: 59em),
only screen and (     -o-min-device-pixel-ratio: 2/1)     and (min-width: 59em),
only screen and (        min-device-pixel-ratio: 2)       and (min-width: 59em),
only screen and (                min-resolution: 192dpi)  and (min-width: 59em),
only screen and (                min-resolution: 2dppx)   and (min-width: 59em) {

}

@media only screen and (min-width: 66em) {
  /* aligns to bootstrap: 1056; */

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  blockquote,
  q {
    padding: 1em 1.66666667em 1.66666667em 1.66666667em;
    position: relative;
    font-size: 3em;
  }

  blockquote p {
    font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3em;
    line-height: 1.33333333em;
    font-size: 1em;
    margin-bottom: .33333333em;
  }

  blockquote p:before,
  q p:before,
  blockquote p:after,
  q p:after {
    font-family: "fira-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #00b1e3;
    font-size: 93px;
    line-height: .32258065em;
  }

  blockquote p:before,
  q p:before {
    content: "“";
    position: absolute;
    top: 65px;
    left: -5px;
  }

  blockquote p:after,
  q p:after {
    content: "”";
    vertical-align: bottom;
    margin-bottom: -20px;
    display: inline-block;
    padding-left: 15px;
  }

  blockquote cite {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.28571429em;
    font-size: .46666667em;
    color: #323849;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .notes,
  p.notes {
    font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    padding-top: 2em;
    font-size: 14px;
  }

  .notes p,
  p.notes p {
    font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
  }

  .hgroup .hgroup_date {
    font-size: 1.4em;
    line-height: 1.78571429em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  h1,
  .h1 {
    font-family: "gustan-thin","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 6em;
    line-height: 1em;
    margin-top: 0;
    margin-bottom: .16666667em;
    color: #323849;
    padding-top: 0;
  }

  h1 .secondary_title,
  .h1 .secondary_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 35px;
    margin-top: .33333333em;
  }

  h1.article,
  .h1.article {
    font-size: 5em;
    line-height: 1.1em;
  }

  h2,
  .h2 {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3em;
    line-height: 1.26666667em;
    margin-top: 0;
    margin-bottom: .66666667em;
    color: #323849;
  }

  h3,
  .h3 {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.2em;
    line-height: 1.13636364em;
    margin-top: .90909091em;
    margin-bottom: .45454545em;
    color: #323849;
  }

  h3.larger,
  .h3.larger {
    font-size: 2.6em;
  }

  h4,
  .h4 {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.66666667em;
    color: #0e7ac3;
    margin-top: .55555556em;
    margin-bottom: .55555556em;
  }

  h4.small,
  .h4.small {
    font-size: 16px;
    margin-bottom: 0;
  }

  h4.metadata,
  .h4.metadata {
    font-size: 14px;
    color: #323849;
  }

  h4.large,
  .h4.large {
    font-family: "gustan-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.2em;
    line-height: 1.13636364em;
    margin-bottom: .5em;
  }

  h5,
  .h5,
  h6,
  .h6 {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.7em;
    line-height: 1.17647059em;
    color: #323849;
    margin-top: .55555556em;
    margin-bottom: .55555556em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .content .link__download .link__title,
  .content .link__external .link__title,
  .content .link__internal .link__title {
    line-height: 25px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .content li,
  .process li,
  .content dd,
  .process dd,
  .content dt,
  .process dt {
    font-size: 16px;
    line-height: 1.5625em;
    padding-top: .2em;
    padding-bottom: .2em;
  }

  .content li li,
  .process li li,
  .content dd li,
  .process dd li,
  .content dt li,
  .process dt li {
    font-size: 1em;
  }

  .content .text-small ul,
  .process .text-small ul,
  .content .text-small ol,
  .process .text-small ol,
  .content .text-small dd,
  .process .text-small dd {
    margin-bottom: 10px;
  }

  .content .text-small li,
  .process .text-small li,
  .content .text-small dd,
  .process .text-small dd,
  .content .text-small dt,
  .process .text-small dt {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
  }

  .content dt,
  .process dt {
    font-family: "fira-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    clear: both;
  }

  .content p+ul,
  .process p+ul,
  .content p+ol,
  .process p+ol {
    margin-top: -2em;
  }

  .content td p+ul,
  .process td p+ul,
  .content td p+ol,
  .process td p+ol {
    margin-top: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  p {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    color: #323849;
    margin-bottom: 1.875em;
  }

  p .larger,
  p.larger {
    font-size: 3em;
    margin-bottom: 10px;
  }

  .text-small p {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.28571429em;
  }

  p.MsoNormal,
  p.MsoBodyText {
    margin: 0;
  }

  p.intro {
    font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.66666667em;
    position: relative;
    padding-left: 50px;
    margin-top: 1.11111111em;
    margin-bottom: 2.77777778em;
    margin-top: 1.4em;
  }

  p.intro:before {
    content: '  ';
    position: absolute;
    top: 7px;
    bottom: 8px;
    left: 0;
    background-color: #00b1e3;
    width: 11px;
  }

  .metadata+p.intro {
    margin-top: 30px;
  }

  .text-center p.intro,
  p.intro.text-center {
    max-width: 100%;
  }

  .metadata__article {
    padding-top: 20px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .layout_header {
    padding-top: 9rem;
    padding-bottom: 1rem;
  }

  .layout_header.linkgrid_header {
    padding-left: 100px;
    padding-right: 100px;
  }

  .layout_header__title {
    padding-bottom: 6rem;
    padding-top: 6rem;
  }

  .layout_header__intab {
    padding-bottom: 0;
    padding-top: 1rem;
    margin-bottom: 0;
  }

  .banner .row_equal>div.col-pglg-4 {
    flex: 0 8%;
    padding: 0 15px 0 35px;
  }

  .banner .row_equal>div.col-pgmd-8 {
    flex: 1 10.5%;
    padding: 0 15px 0 35px;
  }

  .banner .row_equal>div.col-pgmd-9 {
    flex: 3 0;
    padding: 0 15px 0 35px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .accordinate__title_blurb_cta {
    padding-right: 0;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__title {
    padding: 1.125em 0;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__blurb {
    padding: 1.40625em 0;
  }

  .accordinate__title_blurb_cta_wrapper .accordinate__cta {
    position: relative;
  }

  .criteria_accordion {
    margin-top: 50px;
  }

  .criteria_accordion__visafactsheet {
    margin-top: 0;
    padding: 0 0 50px;
  }

  .criteria_accordion .cta_show,
  .criteria_accordion .cta_dismiss {
    margin-top: 6px;
  }

  .criteria_accordion .accordinate__clickable .accordion_trigger.accordinate__cta:after {
    top: 32px;
  }

  .criteria_accordion .accordinate__clickable .accordion_trigger.accordinate__cta.active:after {
    top: 35px;
  }

  .accordinate__clickable .accordinate__lg_title {
    border-top: 2px solid #d5d6d9;
    padding-left: 15px;
    padding-right: 15px;
  }

  .accordinate__clickable .accordinate__lg_title .accordion_trigger_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 17.5px 15px 17.5px 0;
    font-size: 1.6em;
    line-height: 1.26666667em;
    margin: 0;
  }

  .accordinate__clickable .accordinate__lg_title+.accordinate__title {
    margin-top: -2px;
  }

  .accordinate__clickable .accordinate__lg_title:last-child {
    border-bottom: 2px solid #d5d6d9;
  }

  .accordinate__clickable .accordinate__lg_title .listing_item:last-child {
    border-bottom: 0;
  }

  .accordinate__clickable {
    position: relative;
  }

  .accordinate__clickable .accordion_trigger {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: right;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .alternatepathway_heading {
    font-size: 3em;
    line-height: 1.26666667em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .banner.open.banner__inline {
    padding-top: 2.8em;
    padding-bottom: 2em;
  }

  .banner_icon {
    font-size: 40px;
  }

  .banner_title {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3.5em;
    line-height: 1.08571429em;
    margin-bottom: 5px;
  }

  .banner_type {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    margin-bottom: 0;
    display: block;
  }

  .banner_timing {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.66666667em;
    display: block;
    line-height: 2em;
  }

  .banner_timing strong {
    font-family: "fira-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.66666667em;
  }

  .banner_text {
    padding-left: 0;
  }

  .banner_texttitle {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    margin-bottom: .3125em;
  }

  .banner_text p strong,
  .banner_textcopy strong {
    font-family: "fira-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.25em;
    font-size: 1em;
  }

  .banner_dismiss {
    font-size: 2em;
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 7px;
    right: 9.3%;
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f10a";
  }

  .banner_promo-link {
    padding: .56666667em 0;
    font-family: "gustan-light","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3em;
    line-height: 1.2em;
  }

  .banner_promo-link .glyph_right_arrow:before {
    font-size: .26666667em;
    margin-left: 1.875em;
    top: 47%;
  }

  .banner__inline .banner_text p,
  .banner__inline .banner_textcopy {
    font-size: 1.6em;
  }

  .banner__static {
    padding: 2em 2.5em 4em;
  }

  .ie9 .banner__wide {
    padding-left: 35px;
  }

  .banner__wide .banner_dismiss {
    right: 5%;
  }

  .ie9 .banner__wide .col-pglg-22 {
    margin: 0 auto;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .bgpattern.bgpattern_about:before,
  .bgpattern.bgpattern_about:after {
    width: 33%;
  }

  .ie .bgpattern.bgpattern_about:before,
  .ie .bgpattern.bgpattern_about:after {
    display: block;
  }

  .bgpattern.bgpattern_about:before {
    background-size: 150%;
    background-position: -275px 110%;
  }

  .ie .bgpattern.bgpattern_about:before {
    background-position: 100%;
  }

  .bgpattern.bgpattern_about:after {
    background-position: 132px -444px;
    background-size: 161%;
  }

  .ie .bgpattern.bgpattern_about:after {
    background-position: -100% 0;
  }

  .bgpattern.bgpattern_migrant:before {
    background-position: 0 0;
    width: 37%;
    background-size: 100%;
    bottom: auto;
  }

  .bgpattern.bgpattern_industry:before {
    width: 50%;
  }

  .bgpattern.bgpattern_industry:after {
    width: 25%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  a[target="slider"]:not(.listing_link):not(.link__internal),
  .btn {
    padding: 1em 1.4em;
  }

  .btn__lrg {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.66666667em;
    padding: .83333333em 1.38888889em;
    margin-right: .55555556em;
    line-height: 1.6em;
  }

  [data-action-select].margin-onshow:not(.disabled) {
    margin-top: 32px;
    margin-bottom: 21px;
  }

  a[target="slider"]:not(.listing_link):not(.link__internal),
  .btn__trigger {
    font-size: 12px;
    line-height: 1em;
    padding: 8px 22px 8px 12px;
  }

  a[target="slider"]:not(.listing_link):not(.link__internal):after,
  .btn__trigger:after {
    right: 12px;
    top: calc(50% - 6px);
  }

  .btn__withinput {
    line-height: 1.6em;
    min-height: 50px;
    padding: 1em 1.4em;
  }

  .btn__grey {
    padding: .8em 1em;
  }

  .btn_back_wrap .btn_back_button {
    font-size: 1.8em;
    line-height: 1.11111111em;
    padding-left: 2.77777778em;
  }

  .btn_back_wrap .btn_back_button:before {
    font-size: 35px;
    line-height: 40px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .comparison tbody th {
    font-size: 1.5em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .contentblock_heading {
    margin-top: 43px;
  }

  .contentblock_heading h1 {
    margin-bottom: 52px;
    padding-top: 50px;
  }

  .contentblock_heading .intro {
    margin-bottom: 60px;
  }

  .contentblock_centered_xlarge {
    max-width: 995px;
  }

  .content__maxwidth {
    max-width: 1110px;
  }

  div.calendar {
    top: 49px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .visa_back {
    padding-top: 0;
    padding-bottom: 40px;
    top: 91px;
    left: -26%;
  }

  .visa_back .visa_back_btn {
    font-size: 18px;
    line-height: 20px;
    padding-left: 50px;
  }

  .visa_back .visa_back_btn:before {
    font-size: 35px;
    line-height: 40px;
  }

  .factsheet_title {
    padding-left: 8%;
  }

  .factsheet_intro {
    margin-left: 9%;
  }

  .duration {
    padding-bottom: 10px;
  }

  .duration .duration_label {
    font-family: "gustan-medium","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    padding-bottom: 8px;
  }

  .duration .duration_value {
    font-size: 30px;
    line-height: 25px;
  }

  .stickybar_holder .contentblock_centered_large {
    max-width: 950px;
  }

  .btn_adjust.btn {
    margin-top: 50px;
  }

  .fees_step_wrap .process__vertical .process_heading {
    min-height: 1px;
  }

  .fees_step_wrap .process__vertical .process_content p {
    margin-bottom: 12px;
  }

  .fees_footer h5 {
    margin: 5px 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .field_legend {
    font-size: 20px;
  }

  .field__error {
    font-size: 13px;
    padding: 8px 20px 8px 50px;
    line-height: 14px;
  }

  .field__error:before {
    top: 34%;
    left: 18px;
    line-height: 1.2em;
    font-size: 16.9px;
    margin-right: 10px;
    margin-top: -3px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .form_field>label {
    font-size: 20px;
  }

  .form_fieldset {
    padding: 4em;
  }

  .panel_contact .form_fieldset {
    padding: 0;
  }

  .form__tool .form_fieldset {
    padding: 40px;
  }

  .form__tool__standalone {
    margin-bottom: 60px;
  }

  .form .input {
    font-size: 16px;
    height: 50px;
    padding-left: 30px;
    padding-right: 20px;
  }

  .form textarea {
    padding: 15px 20px;
    font-size: 16px;
  }

  .form__disperse .form_fieldset {
    padding-bottom: 40px;
  }

  .form__disperse .form_searchfield>.input {
    padding-right: 2.22222222em;
  }

  .form__disperse .input {
    height: 46px;
  }

  .form__disperse .select2_filters+.select2-container .select2-selection .select2-selection__rendered {
    font-size: 1.8em;
    line-height: 1.88888889em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dock .image__small {
    left: -100px;
    width: 36%;
    margin-right: -6%;
  }

  .image_caption_text,
  .image_caption_link {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.28571429em;
  }

  .dock .image_caption_inner {
    padding: 6em 5em 6em 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .input__check label,
  .input__radio label {
    font-size: 16px;
    padding-left: 50px;
  }

  .input__radio input {
    font-size: 16px;
    left: -3px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .leftnav {
    margin-top: 5px;
  }

  .leftnav .leftnav_title {
    font-size: 2em;
  }

  .leftnav .leftnav__l1 .leftnav_L1_link {
    font-family: "gustan-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    padding: 1.25em 0;
  }

  .leftnav .leftnav__l1 .leftnav_L1_link.current {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
  }

  .leftnav .leftnav__l1 .leftnav_L1_link.section {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
  }

  .leftnav .leftnav__l2 {
    margin-left: 1.5em;
    margin-top: -1em;
    padding-bottom: 1em;
  }

  .leftnav .leftnav__l2 .leftnav_L2_link {
    font-family: "fira-regular","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    line-height: 1.25em;
    padding: .9375em 0 1.0625em;
  }

  .leftnav .leftnav__l2 .leftnav_L2_link.current,
  .leftnav .leftnav__l2 .leftnav_L2_link.section {
    font-family: "fira-bold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.25em;
  }

  .leftnav .leftnav__l3 {
    margin-left: 1.5em;
  }

  .leftnav .leftnav__l3 .leftnav_L3_link {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.28571429em;
    padding-left: .35714286em;
  }

  .leftnav .leftnav__l3 .leftnav_L3_link.current,
  .leftnav .leftnav__l3 .leftnav_L3_link.section {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;
    line-height: 1.28571429em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .linkgrid {
    margin-bottom: 5em;
    margin-left: -14em;
    margin-right: -14em;
  }

  .linkgrid+.linkgrid {
    margin-top: -50px;
  }

  .linkgrid .linkgrid_abstract {
    font-size: 1.6em;
  }

  .linkgrid_item {
    box-sizing: border-box;
  }

  .linkgrid_item .linkgrid_wrap {
    height: 74.5%;
    margin: 11% 13% 11% 12%;
  }

  .linkgrid_item__promo .linkgrid_wrap__promo {
    padding: 1.5em;
  }

  .linkgrid_item__promo .linkgrid_wrap__promo:after {
    bottom: 20px;
    right: 15px;
  }

  .linkgrid_item__promo .linkgrid_title__promo {
    font-size: 2em;
    padding-bottom: .5em;
  }

  .linkgrid_item__promo .linkgrid_abstract__promo {
    font-size: 1.3em;
    margin-bottom: 1.15384615em;
  }

  .linkgrid_title {
    font-size: 3em;
    line-height: 1.1666666em;
    margin-bottom: .83333333em;
  }

  .linkgrid_abstract {
    font-size: 1.6em;
    margin-bottom: 1.5625em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .listing_subtitle {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
  }

  .listing_info {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    margin-top: -10px;
    display: block;
  }

  .listing_item div>:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .listing .listing-item>.listing_link:after {
    font-size: 1.5em;
  }

  .listing__explore_visas .listing_item {
    padding-left: 1.5rem;
    padding-right: 2.5rem;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .motivegrid_itemtitle {
    font-size: 2.2em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .natlang {
    margin-bottom: 30px;
  }

  .natlang .query {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 20px;
    padding-right: 5px;
  }

  .natlang .textmeasure,
  .natlang .field.text input {
    font-size: 35px;
    height: 43px;
    line-height: 35px;
    max-width: 350px;
    overflow: hidden;
  }

  .ie9 .natlang .textmeasure[maxlength="3"],
  .ie9 .natlang .field.text input[maxlength="3"],
  .ie10 .natlang .textmeasure[maxlength="3"],
  .ie10 .natlang .field.text input[maxlength="3"] {
    min-width: 110px;
  }

  .natlang .field {
    max-width: 82%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .nav__primary .nav_item:first-child .nav_trigger {
    margin-left: -1.15384615em;
  }

  .nav__primary .nav_trigger {
    padding: 0 1.15384615em;
  }

  .nav__secondary .nav_link {
    margin: 0 1.05263158em;
  }

  .policecert_main_heading {
    font-size: 36px;
  }

  .policecert_result .content_list_item .content_list_headwrap {
    padding: 23px 0;
  }

  .policecert_result .content_list_item .content_list_trigger {
    top: 12px;
  }

  .policecert_result .content_list_heading {
    font-size: 30px;
  }

  .policecert_result .policecert_subheading {
    font-size: 20px;
    line-height: 25px;
    margin: 0;
  }

  .policecert_result .listing_item_inner p strong {
    font-size: 16px;
  }

  .popout_set {
    padding: 4.5em 4.8em 4.8em 5em;
  }

  .popout_contact .popout_set {
    padding: 6em 4.8em 5.8em 5em;
  }

  .popout_title {
    font-size: 20px;
  }

  .popout .btn__login {
    width: 85%;
  }

  .popout h2 {
    line-height: 1em;
  }

  .popout a img {
    display: block;
  }

  .popout .number {
    font-size: 20px;
    padding-top: 0;
  }

  .popout_triggers {
    position: relative;
  }

  .popout_triggersdescription {
    margin-bottom: 98px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .process {
    margin-bottom: 7em;
  }

  .process .popout_set {
    padding-left: 10.6%;
    padding-bottom: 0;
  }

  .process a .process_content {
    margin-bottom: 40px;
  }

  .process a .process_readmore {
    font-size: 1.2em;
    bottom: 0;
  }

  .process_step {
    padding: 3.5em 3em 1em 3em;
  }

  .process_step:before {
    margin-left: -3em;
  }

  .steps_title {
    font-size: 30px;
    line-height: 38px;
  }

  .process_heading {
    padding-top: 3em;
    margin: 0 0 2.5em;
    white-space: nowrap;
  }

  .process_heading:before {
    width: 4em;
  }

  .process_number {
    display: inline-block;
    font-size: 5.5em;
    line-height: .72727273em;
    width: .72727273em;
  }

  .process_title,
  .popout_title {
    display: inline-block;
    margin-left: -2.5em;
    padding-left: 2.5em;
    max-width: 100%;
    white-space: normal;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 5px;
  }

  .process_content,
  .process .popout__login_content {
    margin-bottom: 2.5em;
  }

  .process_content p,
  .process .popout__login_content p,
  .process_content ul li,
  .process .popout__login_content ul li {
    font-size: 14px;
    line-height: 20px;
  }

  .process.process__collapsible .process_title {
    padding-right: 0;
  }

  .process.process__collapsible.open .process_content {
    margin-bottom: 2.5em;
  }

  .process_section_heading {
    font-size: 50px;
  }

  .process_section_heading__small {
    font-size: 36px;
  }

  .process__vertical {
    margin-bottom: 0;
  }

  .process__vertical .process_step:first-child:not(.process_step__stage) {
    margin-top: 38px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .process_accordian_content p,
  .process_accordian_content address {
    font-size: 14px;
    line-height: 20px;
  }

  .process_accordian_content ul {
    margin-top: -1em;
  }

  .process_accordian_content ul li {
    font-size: 14px;
    line-height: 20px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .question_container {
    padding: 50px 7%;
  }

  .question_input .checkbox_label {
    padding-right: 225px;
  }

  .has_left_nav .question_input .checkbox_label {
    padding-right: 78px;
  }

  .question_select.select2-dropdown .select2-results__options .select2-results__option {
    padding: 6px 5%;
  }

  .question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all {
    margin-top: 63px;
  }

  .has_left_nav .question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all.question_evidencetrigger__all {
    margin-top: 0;
  }

  .question_container.has_left_nav .question_title {
    float: left;
    max-width: 150px;
    width: auto;
    border-top: 0;
  }

  .question_container.has_left_nav .question_option {
    padding-left: 170px;
    clear: none;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .resourcefooter_searchfield:after {
    font-size: 22px;
  }

  .resourcefooter_searchfield>input {
    font-size: 16px;
  }

  .resourcefooter_input {
    line-height: 1.25em;
    margin-bottom: .625em;
    padding: .9375em;
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    background-color: #2687c9;
    border: none;
    color: white;
    display: block;
    width: 100%;
  }

  .resourcefooter_input::-webkit-input-placeholder {
    font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
  }

  .resourcefooter_input::-moz-placeholder {
    font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
  }

  .resourcefooter_input:-moz-placeholder {
    font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
  }

  .resourcefooter_input:-ms-input-placeholder {
    font-family: "gustan-light-italic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
  }

  .resourcefooter_list {
    margin-top: 1.83em;
    list-style: none;
  }

  .resourcefooter_list li {
    font-family: "fira-book","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5625em;
    display: block;
    padding: .625em 0;
    margin-left: 2.23em;
  }

  .resourcefooter_list li:last-child {
    padding-bottom: .5em;
  }

  .resourcefooter_resultlink {
    display: inline;
    position: relative;
  }

  .resourcefooter_resultlink:visited,
  .resourcefooter_resultlink:active {
    color: white;
  }

  .no-touch .resourcefooter_resultlink:hover {
    color: #000;
    border-bottom-color: #000;
  }

  .resourcefooter_resultlink:before {
    font-family: "glyphs";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f11d";
    font-size: .75em;
    left: -3em;
    position: absolute;
    top: 23%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .results__news .metadata {
    font-size: 14px;
  }

  .results_summary {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .results_summary__flush {
    padding-left: 0;
    padding-right: 0;
  }

  .results__news .results_summary p {
    float: left;
    width: auto;
  }

  .results__news .results_summary p span {
    display: inline;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .select2-container .select2-selection--single {
    padding: 8px 7% 8px 30px;
  }

  .select2-container .select2-selection--single.select2--large {
    padding: 13.5px 18px;
  }

  .select2-container .select2-selection--single.select2--large .select2-selection__arrow b:before {
    height: 12px;
    width: 12px;
  }

  .select2-container .select2-results__options .select2-results__option {
    padding: 10px 7% 10px 30px;
  }

  .select2-container .select-large .select2-results__options .select2-results__option {
    padding: 10px 18px;
  }

  .select_2-generic.select_2-generic+.select2 .select2-selection--single {
    height: 47px;
    padding: 11px 20px;
  }

  .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__rendered,
  .select_2-generic.select_2-generic+.select2 .select2-selection--single .select2-selection__placeholder {
    font-size: 16px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .select2-container--natlang.select2 {
    line-height: 13px;
    margin-top: -5px;
    max-width: 100%;
  }

  .select2-container--natlang.select2:not(.setwidth) {
    width: auto !important;
  }

  .select2-container--natlang.select2.setwidth:not(.setwidth-value) {
    min-width: 200px;
  }

  .select2-container--natlang .select2-dropdown {
    min-width: 250px !important;
  }

  .select2-container--natlang .select2-selection .select2-selection__rendered,
  .select2-container--natlang .select2-selection .select2-selection__placeholder {
    font-size: 35px;
    line-height: 35px;
    padding-right: 25px;
    overflow: visible;
  }

  .select2-container--natlang .select2-selection .select2-selection__arrow b {
    right: -6px;
  }

  .select2-container--natlang .select2-results__options li.select2-results__option {
    padding: 8px 5% 8px 5%;
  }

  .select2-container--natlang .select2-search--inline.select2-search .select2-search__field {
    font-size: 35px;
    height: 41px;
    line-height: 35px;
  }

  .select2-container--natlang .select2-search--inline.select2-search:after {
    top: 17px;
    font-size: 20px;
  }

  .field_legend {
    font-size: 20px;
  }

  .select2_filters+.select2-container .select2-selection {
    height: 5em;
  }

  .select2_filters+.select2-container .select2-selection .select2-selection__arrow {
    top: 40%;
  }

  .select2_filters+.select2-container .select2-selection .select2-selection__rendered {
    font-size: 16px;
    line-height: 34px;
  }

  .select2_filters+.select2-container .select2-selection .select2-selection__placeholder {
    font-size: 16px;
  }

  .select2_filters+.select2-container .select2-selection__arrow b {
    top: 0;
  }

  .select2_filters~.field__error {
    padding-left: 44px;
  }

  .select2_filters~.field__error:before {
    margin-top: -12px;
  }

  .select_2-generic .select2-results__options .select2-results__option {
    padding: 10px 20px;
    font-size: 16px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .selectgrid_wrapper>div>p {
    font-size: 18px;
    line-height: 30px;
  }

  .selectgrid_wrapper.selectgrid_wrapper {
    max-width: 748px;
  }

  .selectgrid_cell {
    height: 250px;
    width: 250px;
    padding-top: 65px;
  }

  .selectgrid_cellblurb p {
    font-size: 14px;
  }

  .selectgrid_cellcheck {
    font-size: 13px;
    padding: 25px 20px 20px 10px;
  }

  .selectgrid_celltitle {
    font-size: 20px;
    line-height: 25px;
    margin: 0 0 25px;
  }

  .selectgrid_cellinfo {
    height: 165px;
  }

  .explore_grid_intro {
    font-size: 1.6em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .sidenote .ref-mark {
    font-size: 12px;
  }

  .dock .sidenote,
  .dock .footnotes,
  .dock .sidenote p,
  .dock .footnotes p,
  .dock .sidenote li,
  .dock .footnotes li {
    font-family: "fira-lightitalic","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #444;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .splitcols {
    display: table;
  }

  .splitcols_title {
    font-size: 3.6em;
    margin: 0 0 .69444444em;
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .splitcols_litemtitle {
    font-size: 2em;
    line-height: 1.1em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .table-collapsed th .metadata {
    font-size: 14px;
    color: #323849;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .termination_accordion .accordion_trigger {
    top: 35px;
  }

  .termination form.form .form_field.parsley-error .field__error {
    right: -45px;
  }

  .termination .banner {
    padding-top: 3.3em;
    padding-bottom: 3.3em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .tooltip {
    background-color: #323849;
  }

  .tooltip p {
    font-size: 14px;
  }

  .tooltip ul li {
    font-size: 13px;
  }

  .header_real_tip {
    left: -200px;
    width: 400px;
  }

  .header_real_tip:before {
    left: 50%;
  }

  .tooltip-arrow {
    opacity: 1;
  }

  .no-touch a.tooltip_content.tooltip_content.header__realme_info:hover,
  .no-touch a.tooltip_content.tooltip_content.header__realme_info:focus {
    text-decoration: none;
    border-color: #00b1e3;
  }

  a.tooltip_content.tooltip_content.header__realme_info:active {
    color: #00b1e3;
    border-color: #00b1e3 !important;
  }

  a.header__realme_info.tooltip_content.tooltip_content {
    border-bottom: 1px dashed #fff;
    color: #fff;
  }

  .no-touch a.header__realme_info.tooltip_content.tooltip_content:hover,
  .no-touch a.header__realme_info.tooltip_content.tooltip_content:focus {
    color: #00b1e3;
  }

  a.header__realme_info.tooltip_content.tooltip_content:active {
    border-bottom-color: #fff;
    color: #323849;
  }

  .header_real_tip {
    background: #e6e6e6;
    color: #323849;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 270px;
    padding: 20px;
  }

  .header_real_tip:before {
    content: '  ';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    top: -14px;
    left: 0;
    margin-left: 15px;
    border-width: 0 15px 15px;
    border-bottom-color: #e6e6e6;
  }

  .header_real_tip p,
  .header_real_tip ul,
  .header_real_tip ol {
    color: #323849;
    font-size: 1em;
    margin-bottom: 0;
  }

  .xl-m0 {
    margin: 0;
  }

  .xl-mt0 {
    margin-top: 0;
  }

  .xl-mr0 {
    margin-right: 0;
  }

  .xl-mb0 {
    margin-bottom: 0;
  }

  .xl-ml0 {
    margin-left: 0;
  }

  .xl-m1 {
    margin: .5rem;
  }

  .xl-mt1 {
    margin-top: .5rem;
  }

  .xl-mr1 {
    margin-right: .5rem;
  }

  .xl-mb1 {
    margin-bottom: .5rem;
  }

  .xl-ml1 {
    margin-left: .5rem;
  }

  .xl-m2 {
    margin: 1rem;
  }

  .xl-mt2 {
    margin-top: 1rem;
  }

  .xl-mr2 {
    margin-right: 1rem;
  }

  .xl-mb2 {
    margin-bottom: 1rem;
  }

  .xl-ml2 {
    margin-left: 1rem;
  }

  .xl-m3 {
    margin: 2rem;
  }

  .xl-mt3 {
    margin-top: 2rem;
  }

  .xl-mr3 {
    margin-right: 2rem;
  }

  .xl-mb3 {
    margin-bottom: 2rem;
  }

  .xl-ml3 {
    margin-left: 2rem;
  }

  .xl-m4 {
    margin: 2.6rem;
  }

  .xl-mt4 {
    margin-top: 2.6rem;
  }

  .xl-mr4 {
    margin-right: 2.6rem;
  }

  .xl-mb4 {
    margin-bottom: 2.6rem;
  }

  .xl-ml4 {
    margin-left: 2.6rem;
  }

  .xl-m5 {
    margin: 3rem;
  }

  .xl-mt5 {
    margin-top: 3rem;
  }

  .xl-mr5 {
    margin-right: 3rem;
  }

  .xl-mb5 {
    margin-bottom: 3rem;
  }

  .xl-ml5 {
    margin-left: 3rem;
  }

  .xl-m5-b {
    margin: 4.5rem;
  }

  .xl-mt5-b {
    margin-top: 4.5rem;
  }

  .xl-mr5-b {
    margin-right: 4.5rem;
  }

  .xl-mb5-b {
    margin-bottom: 4.5rem;
  }

  .xl-ml5-b {
    margin-left: 4.5rem;
  }

  .xl-m6 {
    margin: 5.45rem;
  }

  .xl-mt6 {
    margin-top: 5.45rem;
  }

  .xl-mr6 {
    margin-right: 5.45rem;
  }

  .xl-mb6 {
    margin-bottom: 5.45rem;
  }

  .xl-ml6 {
    margin-left: 5.45rem;
  }

  .xl-m7 {
    margin: 6.25rem;
  }

  .xl-mt7 {
    margin-top: 6.25rem;
  }

  .xl-mr7 {
    margin-right: 6.25rem;
  }

  .xl-mb7 {
    margin-bottom: 6.25rem;
  }

  .xl-ml7 {
    margin-left: 6.25rem;
  }

  .xl-m8 {
    margin: 11.111111rem;
  }

  .xl-mt8 {
    margin-top: 11.111111rem;
  }

  .xl-mr8 {
    margin-right: 11.111111rem;
  }

  .xl-mb8 {
    margin-bottom: 11.111111rem;
  }

  .xl-ml8 {
    margin-left: 11.111111rem;
  }

  .xl-mx1 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .xl-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl-mx4 {
    margin-left: 2.6rem;
    margin-right: 2.6rem;
  }

  .xl-mx5 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl-mx6 {
    margin-left: 5.45rem;
    margin-right: 5.45rem;
  }

  .xl-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .xl-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl-mxn4 {
    margin-left: -2.6rem;
    margin-right: -2.6rem;
  }

  .xl-mxn5 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl-mxn6 {
    margin-left: -5.45rem;
    margin-right: -5.45rem;
  }

  .xl-mxn7 {
    margin-left: -6.25rem;
    margin-right: -6.25rem;
  }

  .xl-mxn8 {
    margin-left: -11.111111rem;
    margin-right: -11.111111rem;
  }

  .xl-myn1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .xl-myn2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl-myn3 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl-myn4 {
    margin-top: -2.6rem;
    margin-bottom: -2.6rem;
  }

  .xl-myn5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl-myn6 {
    margin-top: -5.45rem;
    margin-bottom: -5.45rem;
  }

  .xl-myn7 {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }

  .xl-myn8 {
    margin-top: -11.111111rem;
    margin-bottom: -11.111111rem;
  }

  .xl-mtn1 {
    margin-top: -0.5rem;
  }

  .xl-mtn2 {
    margin-top: -1rem;
  }

  .xl-mtn3 {
    margin-top: -2rem;
  }

  .xl-mtn4 {
    margin-top: -2.6rem;
  }

  .xl-mtn5 {
    margin-top: -3rem;
  }

  .xl-mtn6 {
    margin-top: -5.45rem;
  }

  .xl-mtn7 {
    margin-top: -6.25rem;
  }

  .xl-mtn8 {
    margin-top: -11.111111rem;
  }

  .xl-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl-p0 {
    padding: 0;
  }

  .xl-pt0 {
    padding-top: 0;
  }

  .xl-pr0 {
    padding-right: 0;
  }

  .xl-pb0 {
    padding-bottom: 0;
  }

  .xl-pl0 {
    padding-left: 0;
  }

  .xl-px0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl-p1 {
    padding: .5rem;
  }

  .xl-py1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl-px1 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .xl-p2 {
    padding: 1rem;
  }

  .xl-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl-p3 {
    padding: 2rem;
  }

  .xl-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl-p4 {
    padding: 2.6rem;
  }

  .xl-py4 {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }

  .xl-px4 {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }

  .xl-p5 {
    padding: 3rem;
  }

  .xl-py5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl-px5 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl-p6 {
    padding: 5.45rem;
  }

  .xl-py6 {
    padding-top: 5.45rem;
    padding-bottom: 5.45rem;
  }

  .xl-px6 {
    padding-left: 5.45rem;
    padding-right: 5.45rem;
  }

  .xl-p7 {
    padding: 6.25rem;
  }

  .xl-py7 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .xl-px7 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .xl-p8 {
    padding: 11.111111rem;
  }

  .xl-py8 {
    padding-top: 11.111111rem;
    padding-bottom: 11.111111rem;
  }

  .xl-px8 {
    padding-left: 11.111111rem;
    padding-right: 11.111111rem;
  }

  .xl-pt1 {
    padding-top: .5rem;
  }

  .xl-pb1 {
    padding-bottom: .5rem;
  }

  .xl-pt2 {
    padding-top: 1rem;
  }

  .xl-pb2 {
    padding-bottom: 1rem;
  }

  .xl-pt3 {
    padding-top: 2rem;
  }

  .xl-pb3 {
    padding-bottom: 2rem;
  }

  .xl-pt4 {
    padding-top: 2.6rem;
  }

  .xl-pb4 {
    padding-bottom: 2.6rem;
  }

  .xl-pt5 {
    padding-top: 3rem;
  }

  .xl-pb5 {
    padding-bottom: 3rem;
  }

  .xl-pt6 {
    padding-top: 5.45rem;
  }

  .xl-pb6 {
    padding-bottom: 5.45rem;
  }

  .xl-pt7 {
    padding-top: 6.25rem;
  }

  .xl-pb7 {
    padding-bottom: 6.25rem;
  }

  .xl-pt8 {
    padding-top: 11.111111rem;
  }

  .xl-pb8 {
    padding-bottom: 11.111111rem;
  }

  .xl-pr1 {
    padding-right: .5rem;
  }

  .xl-pl1 {
    padding-left: .5rem;
  }

  .xl-pr2 {
    padding-right: 1rem;
  }

  .xl-pl2 {
    padding-left: 1rem;
  }

  .xl-pr3 {
    padding-right: 2rem;
  }

  .xl-pl3 {
    padding-left: 2rem;
  }

  .xl-pr4 {
    padding-right: 2.6rem;
  }

  .xl-pl4 {
    padding-left: 2.6rem;
  }

  .xl-pr5 {
    padding-right: 3rem;
  }

  .xl-pl5 {
    padding-left: 3rem;
  }

  .xl-pr6 {
    padding-right: 5.45rem;
  }

  .xl-pl6 {
    padding-left: 5.45rem;
  }

  .xl-pr7 {
    padding-right: 6.25rem;
  }

  .xl-pl7 {
    padding-left: 6.25rem;
  }

  .xl-pr8 {
    padding-right: 11.111111rem;
  }

  .xl-pl8 {
    padding-left: 11.111111rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2)       and (min-width: 62em),
only screen and (   min--moz-device-pixel-ratio: 2)       and (min-width: 62em),
only screen and (     -o-min-device-pixel-ratio: 2/1)     and (min-width: 62em),
only screen and (        min-device-pixel-ratio: 2)       and (min-width: 62em),
only screen and (                min-resolution: 192dpi)  and (min-width: 62em),
only screen and (                min-resolution: 2dppx)   and (min-width: 62em) {

}

@media only screen and (min-width: 72.5em) {
  /* 1160 needs to be here so that header content fits */

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .accordinate__lg_title .accordion_trigger_title {
    font-size: 3em;
    padding: 1.33333333em 0;
  }

  .criteria_accordion {
    padding-left: 0;
    padding-right: 0;
  }

  #reapply_message .select2_message__disabledonerror .btn__primary,
  #reapply_message .select2_message__error {
    margin-bottom: 50px !important;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .contentblock_wrap {
    margin: 0 -15rem;
  }

  .contentblock_centered {
    max-width: 933px;
  }

  .contentblock_centered_large {
    max-width: 950px;
  }

  .contentblock_centered_xlarge {
    max-width: 1100px;
  }

  .contentblock_centered__medium {
    max-width: 800px;
  }

  .contentblock_heading .intro.intro_small,
  .layout_header .intro.intro_small {
    max-width: 620px;
    display: inline-block;
  }

  .contentblock_heading.has_secondary_title .intro,
  .layout_header.has_secondary_title .intro {
    margin-top: .9em;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dock.closed .dock_dialog {
    right: -80%;
  }

  .dock_dialog {
    width: 75%;
  }

  .dock_body {
    padding: 50px 80px;
  }

  .dock_bodywrap {
    max-width: 900px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dropdown__footer .select2-search__field {
    max-width: 60%;
  }

  .process_filter .select2_filters.select2_filters+.select2-container .select2-selection .select2-selection__placeholder {
    font-size: 16px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .visa_back {
    top: 91px;
    left: -13%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .field__error {
    font-size: 14px;
  }

  .field__error:before {
    font-size: 19.6px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .header .header_login_text {
    font-size: 1.4em;
  }

  .header .header_login__init {
    padding-bottom: 34px;
  }

  .header .header_login__realme {
    padding-bottom: 21px;
  }

  .header .header_login__site .header_login_intro {
    padding-top: 1.5em;
  }

  .header .header_login__site .header_login_content {
    padding-top: 1.5em;
  }

  .header .header_login__site .header_login_content form {
    padding-top: 0;
  }

  .header .header_login__site h5 {
    position: absolute;
  }

  .header .header_login__site form {
    padding-top: 0;
    padding-left: 10em;
  }

  .header .header_login__site .field {
    width: 37.5%;
  }

  .header .header_login__site .action {
    width: 25%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .linkgrid {
    margin-left: auto;
    margin-right: auto;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .motivegrid_item__event .motivegrid_itemtitle,
  .motivegrid_itemtitle {
    font-size: 2.5em;
    line-height: 1.2em;
    padding: 1.4em;
  }

  .motivegrid_item__event .motivegrid_itemtitle {
    padding: 0 1.4em;
  }

  .motivegrid_itemblurb {
    font-size: 1.5em;
    line-height: 1.46666667em;
  }

  .motivegrid_promotext {
    padding: 2em 4em;
  }

  .motivegrid_item__news .motivegrid_article {
    width: 66%;
  }

  .motivegrid_item__event {
    padding: 3.5em 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .nav__primary .nav_item:first-child .nav_trigger {
    margin-left: -1.53846154em;
  }

  .nav__primary .nav_trigger {
    padding: 0 1.53846154em;
  }

  .nav__secondary .nav_link {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.2em;
    line-height: 1.13636364em;
    padding-bottom: .90909091em;
    margin: 0 .81818182em;
    padding-top: .31818182em;
  }

  .popout .btn__login {
    width: 87%;
  }

  .popout p {
    max-width: 90%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .process {
    margin-bottom: 7em;
  }

  .process .popout_set {
    padding-left: 10.5%;
  }

  .process .popout .btn__login {
    width: 86%;
  }

  .process_step {
    padding: 3.5em 3em 1em 4.5em;
  }

  .process_step:before {
    margin-left: -4.5em;
  }

  .process_heading {
    padding-top: 3em;
    margin: 0 0 2.5em;
    white-space: nowrap;
  }

  .process_heading:before {
    width: 4em;
  }

  .process_number {
    display: inline-block;
    font-size: 5.5em;
    line-height: .72727273em;
    width: .72727273em;
  }

  .process_title,
  .popout_title {
    display: inline-block;
    margin-left: -2.5em;
    padding-left: 2.5em;
    max-width: 100%;
    white-space: normal;
    font-size: 16px;
    line-height: 22px;
  }

  .process_content {
    margin-bottom: 2.5em;
  }

  .process_content p {
    font-size: 14px;
    line-height: 20px;
  }

  .process.process__collapsible .process_title {
    padding-right: 0;
  }

  .process.process__collapsible.open .process_content {
    margin-bottom: 2.5em;
  }

  .process__vertical {
    margin-bottom: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .question_container {
    max-width: 100em;
    padding: 70px 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .resourcefooter {
    max-width: 65em;
    margin: 0 auto;
  }

  .accordinate .reapply_section .select2_message__disabledonerror .btn__primary,
  .accordinate .reapply_section .select2_message__error {
    margin-bottom: 50px !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2)       and (min-width: 70em),
only screen and (   min--moz-device-pixel-ratio: 2)       and (min-width: 70em),
only screen and (     -o-min-device-pixel-ratio: 2/1)     and (min-width: 70em),
only screen and (        min-device-pixel-ratio: 2)       and (min-width: 70em),
only screen and (                min-resolution: 192dpi)  and (min-width: 70em),
only screen and (                min-resolution: 2dppx)   and (min-width: 70em) {

}

@media only screen and (min-width: 80em) {
  /* 1280 */

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .criteria_accordion {
    padding-left: 0;
    padding-right: 0;
    margin-top: 70px;
  }

  .criteria_accordion__visafactsheet {
    margin-top: 0;
    padding: 0 0 50px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .blocknav_block {
    margin-left: -68px;
    margin-right: -68px;
  }

  .blocknav_subtitle {
    margin-top: 60px;
  }

  .blocknav_title {
    margin-top: 14px;
    margin-bottom: 40px;
  }

  .blocknav_align {
    padding: 20px;
  }

  .blocknav_link,
  .blocknav_label {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.7em;
    line-height: 1.17647059em;
  }

  .blocknav_link {
    height: 130px;
    line-height: 1.2em;
  }

  .blocknav_align {
    height: 130px;
  }

  .blocknav_label {
    line-height: 58px;
  }

  .blocknav_searchfield.select2-dropdown {
    margin-top: -2px;
  }

  .blocknav_search,
  .blocknav_searchfield {
    line-height: 58px;
    text-align: left;
  }

  .blocknav_search .dropdown-toggle.btn,
  .blocknav_searchfield .dropdown-toggle.btn {
    height: 58px;
    display: block;
  }

  .blocknav_search .bs-searchbox,
  .blocknav_searchfield .bs-searchbox {
    height: 58px;
  }

  .blocknav_search .bs-searchbox input,
  .blocknav_searchfield .bs-searchbox input {
    display: block;
    height: 58px;
  }

  .blocknav_search .select2-search--inline:after,
  .blocknav_searchfield .select2-search--inline:after {
    top: 19px;
  }

  .blocknav_search .select2-search--inline .select2-search__field,
  .blocknav_searchfield .select2-search--inline .select2-search__field {
    padding: 19px 20px;
    min-width: 100%;
  }

  .blocknav_centered-sm {
    max-width: 900px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .contentblock_centered {
    max-width: 903px;
  }

  .contentblock_centered_small {
    max-width: 795px;
  }

  .contentblock_centered_large {
    max-width: 1008px;
  }

  .contentblock_centered_xlarge {
    max-width: 1200px;
  }

  .contentblock_centered__medium {
    max-width: 800px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dock.closed .dock_dialog {
    right: -80%;
  }

  .dock_dialog {
    width: 75%;
  }

  .dock_body {
    padding: 90px 100px;
  }

  .dock_bodywrap {
    max-width: 900px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .visa_back {
    padding: 0;
    position: absolute;
    top: 93px;
    left: -26%;
  }

  .factsheet_title {
    padding-left: 0;
  }

  .factsheet_intro {
    margin-left: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .header .header_login {
    padding-top: 3em;
  }

  .header .header_login_content {
    padding-top: 1.5em;
  }

  .header .header_login_intro {
    float: none;
    padding-right: 0;
    width: auto;
  }

  .header .header_login_title {
    padding-top: .5em;
    padding-right: 1em;
  }

  .header .header_login_text {
    padding-right: 2.85714286em;
  }

  .header .header_login__site .header_login_intro {
    padding-top: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .nav__secondary .nav_link {
    font-family: "gustan-extrabold","arial","冬青黑体","Hiragino Sans GB","黑体","SimHei",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.4em;
    line-height: 1.45833333em;
    padding-bottom: .83333333em;
    margin: 0 .83333333em;
    padding-top: 0;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .process .popout_set {
    padding-left: 10.9%;
  }

  .process .popout_set .col-pgmd-14 {
    width: 75%;
  }

  .process a .process_content {
    margin-bottom: 50px;
  }

  .process a .process_readmore {
    bottom: 5px;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .splitcols_col.col-sm-6:not(:only-child) .wrapper.splitcols_wrapper,
  .splitcols_col.col-md-6:not(:only-child) .wrapper.splitcols_wrapper,
  .splitcols_col.col-pgmd-10:not(:only-child) .wrapper.splitcols_wrapper,
  .splitcols_col.col-lg-6:not(:only-child) .wrapper.splitcols_wrapper,
  .splitcols_col.col-pglg-13:not(:only-child) .wrapper.splitcols_wrapper {
    padding: 6.8rem 10rem 6.5rem 0;
  }

  .splitcols_col.col-sm-6:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper,
  .splitcols_col.col-md-6:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper,
  .splitcols_col.col-pgmd-10:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper,
  .splitcols_col.col-lg-6:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper,
  .splitcols_col.col-pglg-13:not(:only-child)+.splitcols_col .wrapper.splitcols_wrapper {
    padding: 6.8rem 0 9rem 11.1rem;
  }

  .splitcols_col:only-child .wrapper.splitcols_wrapper {
    padding: 7.1em 12.9em;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2)       and (min-width: 80em),
only screen and (   min--moz-device-pixel-ratio: 2)       and (min-width: 80em),
only screen and (     -o-min-device-pixel-ratio: 2/1)     and (min-width: 80em),
only screen and (        min-device-pixel-ratio: 2)       and (min-width: 80em),
only screen and (                min-resolution: 192dpi)  and (min-width: 80em),
only screen and (                min-resolution: 2dppx)   and (min-width: 80em) {

}

@media only screen and (min-width: 90em) {
  /* wrapper max of 130em + 5% on either side = 1440 */

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .dock.closed .dock_dialog {
    right: -1115px;
  }

  .dock_dialog {
    width: 1110px;
  }

  .dock_body {
    padding: 70px 100px;
  }

  .dock_bodywrap {
    max-width: 900px;
  }

  .dock .col-lg-3 {
    width: 25%;
  }

  .dock .accordion_content_container .col-lg-offset-3 {
    margin-left: 25%;
  }

  .dock .accordion_content_container .col-lg-9 {
    width: 75%;
  }

@font-face {
    font-family: "glyphs";
    src: url("../../fonts/glyphs/glyphs.eot?3df43d2fbf0681e67f7ede0048bd057f");
    src: url("../../fonts/glyphs/glyphs.eot?#iefix") format("embedded-opentype"),url("../../fonts/glyphs/glyphs.woff?3df43d2fbf0681e67f7ede0048bd057f") format("woff"),url("../../fonts/glyphs/glyphs.ttf?3df43d2fbf0681e67f7ede0048bd057f") format("truetype");
    font-weight: normal;
    font-style: normal;
}

  .show-animate {
    transition: all .3s;
    max-height: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .hide-animate {
    transition: all .3s;
    max-height: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: block;
  }

  .nav__secondary .nav_link {
    margin: 0 1.5em;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2)       and (min-width: 100em),
only screen and (   min--moz-device-pixel-ratio: 2)       and (min-width: 100em),
only screen and (     -o-min-device-pixel-ratio: 2/1)     and (min-width: 100em),
only screen and (        min-device-pixel-ratio: 2)       and (min-width: 100em),
only screen and (                min-resolution: 192dpi)  and (min-width: 100em),
only screen and (                min-resolution: 2dppx)   and (min-width: 100em) {

}